<template>
  <div class="push-course-edit">
    <!--v-loading="pageLoading"-->
    <div class="push-course-body">
      <div class="content-title">
        <span>{{ contentTitle }}</span>
        <!-- <el-tooltip class="item" effect="light" popper-class="customPopper" content="预览" placement="top"> -->
        <!-- <img :src="detailViewIcon" alt="" @click="courseView" /> -->
        <div class="view-button"
             @click="courseView">预览推送效果</div>
        <!-- </el-tooltip> -->
      </div>
      <div class="panel-data"
           v-if="courseContentList.length > 0">
        <div class="first-panel pushEdit"
             v-for="(item, index) in courseContentList"
             :key="item.id">
          <div class="panel-header">
            <div class="title">
              <div class="title-icon"></div>{{ item.contentName }}
            </div>
            <div class="button">
              <el-tooltip class="item"
                          effect="light"
                          popper-class="customPopper"
                          content="附件上传"
                          placement="bottom"
                          v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0'">
                <img :src="uploadFileIcon"
                     alt=""
                     @click="handleContentItem('addFile', null, null, item, index)"
                     v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0'"
                     style="margin-right: 0.41667vw" />
              </el-tooltip>
              <el-divider direction="vertical"
                          v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0'"></el-divider>
              <el-tooltip class="item"
                          effect="light"
                          popper-class="customPopper"
                          content="图文编辑"
                          placement="bottom"
                          v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 &&item.contentType == '0'">
                <img :src="editIcon"
                     alt=""
                     @click=" handleContentItem('editItem', null, null, item, index)"
                     v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0'"
                     style="margin: 0 0.41667vw" />
              </el-tooltip>
              <el-tooltip class="item"
                          effect="light"
                          popper-class="customPopper"
                          :content="item.contentType == '1' ? '视频添加' : '活动添加'"
                          placement="bottom"
                          v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && (item.contentType == '1' || item.contentType == '2')">
                <img :src="videoAddIcon"
                     alt=""
                     @click="handleContentItem('addVideoOrActivity', null, null, item, index)"
                     v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 &&(item.contentType == '1' || item.contentType == '2')"
                     style="margin-right: 0.41667vw" />
              </el-tooltip>
              <el-divider direction="vertical"
                          v-if="item.eduCourseThemeCatalogueContentTrees.length == 0"></el-divider>
              <img :src="firstPanelTurnIcon"
                   alt=""
                   @click="handleContentItem('turnItem', null, null, item, index)"
                   style="margin-left: 0.41667vw"
                   :class="{ 'turn-icon-class': !item.showFlag }" />
            </div>
          </div>
          <div class="panel-content"
               v-show="item.showFlag"
               v-if="item.eduCourseThemeCatalogueContentTrees.length > 0">
            <div class="second-panel"
                 v-for="(info, i) in item.eduCourseThemeCatalogueContentTrees"
                 :key="info.id">
              <div class="panel-header">
                <div class="title">{{ info.contentName }}</div>
                <div class="button">
                  <el-tooltip class="item"
                              effect="light"
                              popper-class="customPopper"
                              content="附件上传"
                              placement="bottom"
                              v-if="info.contentType == '0'">
                    <img :src="uploadFileIcon"
                         alt=""
                         @click="handleContentItem('addFile', info, i, item, index)"
                         v-if="info.contentType == '0'"
                         style="margin-right: 0.41667vw" />
                  </el-tooltip>
                  <el-divider direction="vertical"
                              v-if="info.contentType == '0'"></el-divider>
                  <el-tooltip class="item"
                              effect="light"
                              popper-class="customPopper"
                              content="图文编辑"
                              placement="bottom"
                              v-if="info.contentType == '0'">
                    <img :src="editIcon"
                         alt=""
                         @click="handleContentItem('editItem', info, i, item, index)"
                         v-if="info.contentType == '0'"
                         style="margin: 0 0.41667vw" />
                  </el-tooltip>
                  <el-tooltip class="item"
                              effect="light"
                              popper-class="customPopper"
                              :content="info.contentType == '1' ? '视频添加' : '活动添加'"
                              placement="bottom"
                              v-if="info.contentType == '1' || info.contentType == '2'">
                    <img :src="videoAddIcon"
                         alt=""
                         @click="handleContentItem('addVideoOrActivity', info, i, item, index)"
                         v-if="info.contentType == '1' || info.contentType == '2'"
                         style="margin-right: 0.41667vw" />
                  </el-tooltip>
                  <el-divider direction="vertical"></el-divider>
                  <img :src="secondPanelTurnIcon"
                       alt=""
                       @click="handleContentItem('turnItem', info, i, item, index)"
                       style="margin-left: 0.41667vw"
                       :class="{ 'turn-icon-class': !info.showFlag }" />
                </div>
              </div>
              <div class="panel-content"
                   v-show="info.showFlag"
                   v-if="info.contentType == '0'">
                <div class="content-info"
                     v-if="info.eduCourseThemeContent.length > 0">
                  <!-- <div class="ql-container ql-snow">
                    <div class="ql-editor" v-html="info.eduCourseThemeContent[0].content"></div>
                  </div> -->
                  <div class="w-e-text-container"
                       @click="handleImgClick">
                    <div class="w-e-text"
                         v-html="info.eduCourseThemeContent[0].content"></div>
                  </div>
                </div>
                <div class="file-info"
                     v-if="info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0">
                  <div class="file-item"
                       v-for="(fileInfo,fileIndex) in info.eduCourseThemeCatalogueContentFile"
                       :key="fileInfo.id"
                       :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.52083vw', width: '19.79167vw' }">
                    <div class="content">
                      <img class="icon"
                           :src="fileIconPDF"
                           alt=""
                           v-if="fileInfo.iconType == 'pdf'" />
                      <img class="icon"
                           :src="fileIconWord"
                           alt=""
                           v-if="fileInfo.iconType == 'doc'" />
                      <img class="icon"
                           :src="fileIconExcel"
                           alt=""
                           v-if="fileInfo.iconType == 'xls'" />
                      <img class="icon"
                           :src="fileIconPPT"
                           alt=""
                           v-if="fileInfo.iconType == 'ppt'" />
                      <div class="message">
                        <div class="title">{{ fileInfo.originalName }}</div>
                      </div>
                    </div>
                    <div class="bottom">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件下载"
                                  placement="bottom"
                                  v-if="fileInfo.isDownload == '1'">
                        <img :src="fileDownloadIcon"
                             alt=""
                             @click="fileView(fileInfo,'download')"
                             style="margin-right: 0.41667vw"
                             v-if="fileInfo.isDownload == '1'" />
                      </el-tooltip>
                      <el-divider direction="vertical"
                                  v-if="fileInfo.isDownload == '1'"></el-divider>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件预览"
                                  placement="bottom">
                        <img :src="fileViewIcon"
                             alt=""
                             @click="fileView(fileInfo,'view')"
                             style="margin: 0 0.26042vw 0 0.41667vw" />
                      </el-tooltip>
                      <el-divider direction="vertical"></el-divider>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件删除"
                                  placement="bottom">
                        <img :src="delIcon"
                             alt=""
                             @click="delFile(fileInfo)"
                             style="margin: 0 0.26042vw 0 0.41667vw" />
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-content"
                   v-show="info.showFlag"
                   v-if="info.contentType == '1'">
                <el-row style="padding: padding: 1.25vw 0.9375vw;">
                  <!--type="flex"-->
                  <!-- <el-col :span="videoSpan" v-for="(video, k) in info.eduCourseThemeCatalogueContentVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '3.5vw' : '0', 'margin-bottom': '1.04167vw', }"> -->
                  <!--'margin-right': (index + 1) % 4 != 0 ? '5.4%' : '0',-->
                  <el-card shadow="hover"
                           class="video-card"
                           v-for="(video, k) in info.eduCourseThemeCatalogueContentVideo"
                           :key="video.id"
                           :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '0.67708vw' : '0', 'margin-bottom': '0.67708vw', 'width': '22.08333vw', display: 'inline-block' }"
                           :body-style="{ padding: 'undefined' }"
                           @click.native="videoView(video)">
                    <div class="video-body"
                         :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                      <img :src="videoPlayIcon"
                           alt="" />
                    </div>
                    <div class="info-body video-item">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="video.videoName"
                                  placement="top">
                        <div class="title">{{ video.videoName }}</div>
                      </el-tooltip>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="video.intro"
                                  placement="top"
                                  v-if="video.intro">
                        <div class="ltitle"
                             v-if="video.intro">{{ video.intro }}</div>
                      </el-tooltip>
                      <div class="bottom">
                        <div class="video-time">{{ video.createTime }}</div>
                        <div class="video-button">
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      content="视频编辑"
                                      placement="bottom">
                            <img :src="editIcon"
                                 alt=""
                                 @click.stop="editVideo(video, k, info, i, item, index)"
                                 style="margin-right: 15%" />
                          </el-tooltip>
                          <el-divider direction="vertical"></el-divider>
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      content="视频删除"
                                      placement="bottom">
                            <img :src="delIcon"
                                 alt=""
                                 @click.stop="delVideo(video)"
                                 style="margin-left: 15%" />
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </el-card>
                  <!-- </el-col> -->
                </el-row>
              </div>
              <div class="panel-content"
                   v-show="info.showFlag"
                   v-if="info.contentType == '2'">
                <!-- <el-radio-group v-model="info.activityTypeRadio" @change="(val) => handleRadioChange(val, info, i, item, index)">
                  <el-radio-button :label="radioInfo.value" v-for="radioInfo in activityTypeOptions" :key="radioInfo.id">{{ radioInfo.name }}</el-radio-button>
                </el-radio-group> -->
                <div class="activity-type-check"
                     v-show="info.activityListShow">
                  <div class="activity-type-item"
                       :class="{ active: radioInfo.value == info.activityTypeRadio }"
                       v-for="radioInfo in info.activityTypeList"
                       :key="radioInfo.id"
                       @click="handleRadioChange(radioInfo.value, info, i, item, index)">{{ radioInfo.name }}</div>
                </div>
                <div class="third-panel"
                     v-for="activityInfo in info.activityList"
                     :key="activityInfo.id">
                  <div class="panel-header">
                    <div class="title">
                      <img :src="activityTitleIcon"
                           alt="" /><span>{{ activityInfo.activityName }}</span>
                    </div>
                    <div class="button">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="活动编辑"
                                  placement="bottom">
                        <img :src="detailEditIcon"
                             alt=""
                             @click="handleActivityItem('edit', activityInfo, info, i, item, index)"
                             style="margin-right: 0.625vw" />
                      </el-tooltip>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="活动删除"
                                  placement="bottom">
                        <img :src="detailDelIcon"
                             alt=""
                             @click="handleActivityItem('del', activityInfo, info, i, item, index)" />
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="panel-content">
                    <div class="content-info"
                         v-if="activityInfo.activityContent">
                      <!-- <div class="ql-container ql-snow">
                        <div class="ql-editor" v-html="activityInfo.activityContent"></div>
                      </div> -->
                      <div class="w-e-text-container"
                           @click="handleImgClick">
                        <div class="w-e-text"
                             v-html="activityInfo.activityContent"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-content"
               v-show="item.showFlag"
               v-if="item.eduCourseThemeCatalogueContentTrees.length == 0">
            <div class="content-panel"
                 v-if="item.contentType == '0'">
              <div class="content-info"
                   v-if="item.eduCourseThemeContent.length > 0">
                <!-- <div class="ql-container ql-snow">
                  <div class="ql-editor" v-html="item.eduCourseThemeContent[0].content"></div>
                </div> -->
                <div class="w-e-text-container"
                     @click="handleImgClick">
                  <div class="w-e-text"
                       v-html="item.eduCourseThemeContent[0].content"></div>
                </div>
              </div>
              <div class="file-info"
                   v-if="item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0">
                <div class="file-item"
                     v-for="(fileInfo,fileIndex) in item.eduCourseThemeCatalogueContentFile"
                     :key="fileInfo.id"
                     :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.52083vw', width: '19.79167vw' }">
                  <div class="content">
                    <img class="icon"
                         :src="fileIconPDF"
                         alt=""
                         v-if="fileInfo.iconType == 'pdf'" />
                    <img class="icon"
                         :src="fileIconWord"
                         alt=""
                         v-if="fileInfo.iconType == 'doc'" />
                    <img class="icon"
                         :src="fileIconExcel"
                         alt=""
                         v-if="fileInfo.iconType == 'xls'" />
                    <img class="icon"
                         :src="fileIconPPT"
                         alt=""
                         v-if="fileInfo.iconType == 'ppt'" />
                    <div class="message">
                      <div class="title">{{ fileInfo.originalName }}</div>
                    </div>
                  </div>
                  <div class="bottom">
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件下载"
                                placement="bottom">
                      <img :src="fileDownloadIcon"
                           alt=""
                           @click="fileView(fileInfo,'download')"
                           style="margin-right: 0.41667vw" />
                    </el-tooltip>
                    <el-divider direction="vertical"></el-divider>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件预览"
                                placement="bottom">
                      <img :src="fileViewIcon"
                           alt=""
                           @click="fileView(fileInfo,'view')"
                           style="margin: 0 0.26042vw 0 0.41667vw" />
                    </el-tooltip>
                    <el-divider direction="vertical"></el-divider>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件删除"
                                placement="bottom">
                      <img :src="delIcon"
                           alt=""
                           @click="delFile(fileInfo)"
                           style="margin: 0 0.26042vw 0 0.41667vw" />
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-panel"
                 v-if="item.contentType == '1'">
              <el-row style="padding: 1.25vw 0.9375vw;">
                <!--type="flex"-->
                <!-- <el-col :span="videoSpan" v-for="(video, k) in item.eduCourseThemeCatalogueContentVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '3.5vw' : '0', 'margin-bottom': '1.04167vw', }">'margin-right': (index + 1) % 4 != 0 ? '5.4%' : '0', -->
                <el-card shadow="hover"
                         class="video-card"
                         v-for="(video, k) in item.eduCourseThemeCatalogueContentVideo"
                         :key="video.id"
                         :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '0.67708vw' : '0', 'margin-bottom': '0.67708vw', 'width': '22.08333vw', display: 'inline-block' }"
                         :body-style="{ padding: 'undefined' }"
                         @click.native="videoView(video)">
                  <div class="video-body"
                       :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + '', 'background-size': '100% 100%', }">
                    <img :src="videoPlayIcon"
                         alt="" />
                  </div>
                  <div class="info-body video-item">
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                :content="video.videoName"
                                placement="top">
                      <div class="title">{{ video.videoName }}</div>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                :content="video.intro"
                                placement="top"
                                v-if="video.intro">
                      <div class="ltitle"
                           v-if="video.intro">{{ video.intro }}</div>
                    </el-tooltip>
                    <div class="bottom">
                      <div class="video-time">{{ video.createTime }}</div>
                      <div class="video-button">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="视频编辑"
                                    placement="bottom">
                          <img :src="editIcon"
                               alt=""
                               @click.stop="editVideo(video, k, null, null, item, index)"
                               style="margin-right: 15%" />
                        </el-tooltip>
                        <el-divider direction="vertical"></el-divider>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="视频删除"
                                    placement="bottom">
                          <img :src="delIcon"
                               alt=""
                               @click.stop="delVideo(video)"
                               style="margin-left: 15%" />
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </el-card>
                <!-- </el-col> -->
              </el-row>
            </div>
            <div class="content-panel"
                 v-if="item.contentType == '2'">
              <!-- <el-radio-group v-model="item.activityTypeRadio" @change="(val) => handleRadioChange(val, null, null, item, index)">
                <el-radio-button :label="radioInfo.value" v-for="radioInfo in activityTypeOptions" :key="radioInfo.id">{{ radioInfo.name }}</el-radio-button>
              </el-radio-group> -->
              <div class="activity-type-check"
                   v-show="item.activityListShow">
                <div class="activity-type-item"
                     :class="{ active: radioInfo.value == item.activityTypeRadio }"
                     v-for="radioInfo in item.activityTypeList"
                     :key="radioInfo.id"
                     @click="handleRadioChange(radioInfo.value, null, null, item, index)">{{ radioInfo.name }}</div>
              </div>
              <div class="third-panel"
                   v-for="activityInfo in item.activityList"
                   :key="activityInfo.id">
                <div class="panel-header">
                  <div class="title">
                    <img :src="activityTitleIcon"
                         alt="" /><span>{{ activityInfo.activityName }}</span>
                  </div>
                  <div class="button">
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="活动编辑"
                                placement="bottom">
                      <img :src="detailEditIcon"
                           alt=""
                           @click="handleActivityItem('edit', activityInfo, null, null, item, index)"
                           style="margin-right: 0.625vw" />
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="活动删除"
                                placement="bottom">
                      <img :src="detailDelIcon"
                           alt=""
                           @click="handleActivityItem('del',activityInfo, null, null, item, index)" />
                    </el-tooltip>
                  </div>
                </div>
                <div class="panel-content">
                  <div class="content-info"
                       v-if="activityInfo.activityContent">
                    <!-- <div class="ql-container ql-snow">
                      <div class="ql-editor" v-html="activityInfo.activityContent"></div>
                    </div> -->
                    <div class="w-e-text-container"
                         @click="handleImgClick">
                      <div class="w-e-text"
                           v-html="activityInfo.activityContent"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-content-panel"
           v-if="courseContentList.length == 0">
        <img class="empty-img"
             :src="emptyImg"
             alt="" />
        <div class="empty-title">暂无内容</div>
        <!-- <div class="empty-ltitle">需要先进行内容设置后才能进行内容操作</div> -->
        <!-- <el-button class="empty-button" type="primary" plain round @click="handleCourseContentCatalog">内容设置</el-button> -->
      </div>
      <div class="footer-buttons">
        <el-button @click="handlePushCancel"
                   :loading="pageButtonLoading">取消</el-button>
        <el-button class="push-course-button"
                   plain
                   type="primary"
                   @click="handlePushSave"
                   :loading="pageButtonLoading">保存草稿</el-button>
        <el-button type="primary"
                   @click="handlePushSubmit"
                   :loading="pageButtonLoading">推送</el-button>
      </div>
    </div>
    <el-image style="width: 0; height: 0;"
              ref="viewImg"
              :src="viewUrl"
              :preview-src-list="viewUrlList"></el-image>
    <el-dialog :title="courseActivityTitle"
               :visible.sync="courseActivityVisible"
               :append-to-body="true"
               :destroy-on-close="true"
               @before-close="handleCourseActivityClose"
               @close="handleCourseActivityClose"
               width="70%">
      <el-form :model="courseActivityForm"
               ref="courseActivityForm"
               :rules="rules"
               label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="活动分类："
                          prop="activityClassify">
              <el-select class="form-input"
                         v-model="courseActivityForm.activityClassify"
                         clearable
                         placeholder="请选择活动分类">
                <el-option v-for="item in activityTypeOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="活动名称："
                          prop="activityName">
              <el-input class="form-input"
                        v-model="courseActivityForm.activityName"
                        placeholder="请输入活动名称，支持汉字、数字、字母组合"
                        :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="内容：">
              <editor-component style="width: 100%"
                                ref="activityContentComponent"
                                :id="'activityContent'"
                                :height="'300'"
                                :defaultContent="editorContent"
                                @getContent="getContent"></editor-component>
              <!-- <quill-editor style="width: 100%" class="textEditor" v-model="courseActivityForm.activityContent" :options="editorOption" ref="QuillEditor"></quill-editor> -->
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24" style="width: 0">
            <el-upload style="opacity: 0" :action="uploadUrl" :headers="uploadHeader" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <el-button id="uploadButton" size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button :loading="buttonloading"
                   @click="handleCourseActivityClose">取 消</el-button>
        <el-button :loading="buttonloading"
                   type="primary"
                   @click="handleCourseActivitySubmit">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="coursePushTitle"
               :visible.sync="coursePushVisible"
               :append-to-body="true"
               :destroy-on-close="true"
               @before-close="handleCoursePushClose"
               @close="handleCoursePushClose"
               width="70%">
      <el-form :model="coursePushForm"
               ref="coursePushForm"
               :rules="coursePushRules"
               label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="推送标题：">
              <el-input class="form-input"
                        v-model="coursePushForm.publishTitle"
                        placeholder="请输入推送标题"
                        :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="选择推送班级："
                          prop="classesIds">
              <el-checkbox-group v-model="coursePushForm.classesIds"
                                 @change="handlePushClassChange">
                <el-checkbox :label="item.classLabel"
                             v-for="item in classOptions"
                             :key="item.classLabel">{{ item.className }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item">
              <el-select class="form-input"
                         v-model="studentList"
                         multiple
                         clearable
                         placeholder="请选择学生"
                         @change="handlePushStudentChange">
                <el-option v-for="item in studentOptions"
                           :key="item.studentId"
                           :label="item.studentName"
                           :value="item.studentId"
                           :disabled="item.disabled"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="选择提交方式："
                          prop="isSubmit">
              <el-radio-group v-model="coursePushForm.isSubmit"
                              @change="handleIsSubmitChange">
                <el-radio :label="'1'">提交方式</el-radio>
                <el-radio :label="'0'">无需提交</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="coursePushForm.isSubmit === '1'">
            <el-form-item class="form-choose-item"
                          prop="submitType">
              <el-checkbox :indeterminate="isIndeterminate"
                           v-model="checkAll"
                           @change="handleCheckAllChange">全选</el-checkbox>
              <el-checkbox-group v-model="coursePushForm.submitType"
                                 @change="handleCheckedSubmitTypeChange"
                                 style="display: inline-block;margin-left: 1.14583vw;">
                <el-checkbox :label="item.value"
                             v-for="item in submitTypeOptions"
                             :key="item.value">{{ item.name }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="coursePushForm.isSubmit === '1'">
            <el-form-item class="form-choose-item"
                          label="评论截止时间："
                          prop="submitEndTime"
                          :rules="!submitTimeDisabled ? [ { required: true, message: '请选择截止时间', trigger: 'change' }, ] : []">
              <el-date-picker style="width: calc(100% - 4.42708vw)"
                              :disabled="submitTimeDisabled"
                              v-model="coursePushForm.submitEndTime"
                              type="datetime"
                              placeholder="选择时间"
                              format="yyyy-MM-dd HH:mm:ss"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              @change="handleEndTimeChange"></el-date-picker>
              <el-checkbox v-model="noTimeCheck"
                           @change="handleEndTimeTypeChange"
                           style="display: inline-block;margin-left: 1.14583vw;">永久</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="备注：">
              <el-input class="form-input"
                        type="textarea"
                        :rows="5"
                        placeholder="请输入备注"
                        v-model="coursePushForm.description"
                        resize="none"
                        :maxlength="200"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button :loading="buttonloading"
                   @click="handleCoursePushClose">取 消</el-button>
        <el-button :loading="buttonloading"
                   type="primary"
                   @click="handleCoursePushSubmit">保 存</el-button>
      </div>
    </el-dialog>
    <catalog-setting :catalogSettingDialogVisible.sync="courseSettingDialogVisible"
                     :catalogSettingDialogHandleType="handleType"
                     :catalogSettingDialogType="courseSettingType"
                     :formData="courseSettingData"
                     :courseId="courseContentCatalogId"
                     @refreshPage="refreshPage"></catalog-setting>
    <target-setting :targetSettingDialogVisible.sync="targetSettingDialogVisible"
                    :targetSettingDialogHandleType="targetSettingDialogHandleType"
                    :formData="targetSettingData"
                    :courseContentCatalogId="courseContentCatalogId"
                    @refreshPage="getTargetInfo"></target-setting>
    <content-component ref="contentComponent"
                       :contentDialogVisible.sync="contentDialogVisible"
                       :contentDialogHandleType="contentDialogHandleType"
                       :contentDialogType="contentDialogType"
                       :formData="contentData"
                       @getFileData="getContentInfo"></content-component>
    <upload-video ref="uploadVideo"
                  :uploadVideoDialogVisible.sync="uploadVideoDialogVisible"
                  :uploadVideoHandleType="uploadVideoHandleType"
                  :formData="videoData"
                  @getFileData="getVideoInfo"></upload-video>
    <upload-file ref="uploadFile"
                 :uploadFileDialogVisible.sync="uploadFileDialogVisible"
                 :uploadFileHandleType="uploadFileHandleType"
                 :formData="fileData"
                 @getFileData="getFileInfo"></upload-file>
    <video-view-dialog ref="videoView"
                       :videoViewDialogVisible.sync="videoViewDialogVisible"
                       :type="videoViewType"
                       :videoUrl="videoViewUrl"
                       @closeDialog="videoViewClose"></video-view-dialog>
    <publish-view :courseViewDialogVisible.sync="courseViewDialogVisible"
                  :contentId="recordId != null ? recordId : courseContentCatalogId"></publish-view>
  </div>
</template>
<script>
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['image', 'video'],
  ['clean'], // remove formatting button
];
import { mapGetters, mapState } from 'vuex';
import EditorComponent from '@/components/editorComponent/index.vue';
import CatalogSetting from '@/components/catalogSetting/index.vue';
import TargetSetting from '@/components/targetSetting/index.vue';
import contentComponent from '@/components/contentComponent/index.vue';
import uploadVideo from '@/components/uploadVideo/index.vue';
import uploadFile from '@/components/uploadFile/index.vue';
import VideoViewDialog from '@/components/videoView/index.vue';
import PublishView from '@/components/publishView/index.vue';
import detailTeacherIcon from '@/assets/img/detail-teacher-icon.png';
import detailTimeIcon from '@/assets/img/detail-time-icon.png';
import detailPushIcon from '@/assets/img/detail-push-icon.png';
import detailEditIcon from '@/assets/img/detail-edit-icon.png';
import detailDelIcon from '@/assets/img/detail-del-icon.png';
import detailViewIcon from '@/assets/img/detail-view-icon-text.png';
import emptyImg from '@/assets/img/empty-img.png';
import firstPanelTurnIcon from '@/assets/img/first-panel-turn-icon.png';
import editIcon from '@/assets/img/edit-icon.png';
import uploadFileIcon from '@/assets/img/upload-file-icon.png';
import panelDelIcon from '@/assets/img/panel-del-icon.png';
import fileViewIcon from '@/assets/img/file-view-icon.png';
import fileDownloadIcon from '@/assets/img/file-download-icon.png';
import delIcon from '@/assets/img/del-icon.png';
import videoAddIcon from '@/assets/img/video-add-icon.png';
import secondPanelTurnIcon from '@/assets/img/second-panel-turn-icon.png';
import catalogSettingIcon from '@/assets/img/catalog-setting-icon.png';
import activityTitleIcon from '@/assets/img/activity-title-icon.png';
import fileIconPDF from '@/assets/img/file-icon-PDF.png';
import fileIconWord from '@/assets/img/file-icon-word.png';
import fileIconExcel from '@/assets/img/file-icon-excel.png';
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
import videoPlayIcon from '@/assets/img/play-icon.png';
import defaultImg from '@/assets/img/default-img.png';
import { getToken } from '@/utils/auth';
import { noSymbol } from '@/utils/validate.js';
import { getFileViewUrl, exportFile } from '@/utils/fileUtils.js';
export default {
  name: 'courseContentSetting',
  components: {
    EditorComponent,
    CatalogSetting,
    TargetSetting,
    contentComponent,
    uploadVideo,
    uploadFile,
    VideoViewDialog,
    PublishView
  },
  props: {
    sendCourseData: {
      type: Object,
      default: null
    }
  },
  inject: ['tagClose'],
  data () {
    let checkName = (rule, value, callback) => {
      if (value === '') {
        return callback();
      }
      if (value !== '' && !noSymbol(value)) {
        callback(new Error('只支持汉字、数字、字母组合'));
      } else {
        callback();
      }
    };
    return {
      tableLoading: false, // 目标清单tableloading
      activeTab: 'courseIntroduce', // 介绍和内容切换
      courseContentInfo: {
        coverImg: '',
        courseThemeName: '',
        description: '',
        status: '',
        userName: '',
        createTime: '',
      }, // 课程基本信息
      detailTeacherIcon, // 教师信息icon
      detailTimeIcon, // 创建时间icon
      detailPushIcon, // 发布icon
      detailEditIcon, // 详情编辑icon
      detailDelIcon, // 详情删除icon
      detailViewIcon, // 详情预览icon
      emptyImg, // 空数据图片
      firstPanelTurnIcon, // 一级折叠icon
      editIcon, // 编辑icon
      uploadFileIcon, // 附件上传icon
      panelDelIcon, // 层删除icon
      fileViewIcon, // 文件预览icon
      fileDownloadIcon, // 文件下载icon
      delIcon, // 删除icon
      videoAddIcon, // 视频添加icon
      secondPanelTurnIcon, // 二级折叠icon
      catalogSettingIcon, // 课程内容的目录icon
      activityTitleIcon, // 活动头部icon
      fileIconPDF, // pdfIcon
      fileIconWord, // wordIcon
      fileIconExcel, // excelIcon
      fileIconPPT,//PPTIcon
      videoPlayIcon, // 视频icon
      defaultImg, // 默认图片

      courseActivityTitle: '', // 课程内容活动信息弹窗标题
      courseActivityVisible: false, // 课程活动信息弹窗显隐标识
      courseActivityHandleType: '', // 课程活动信息弹窗操作类型
      courseActivityForm: {
        activityClassify: '',
        activityName: '',
        // activityContent: "",
      }, // 查询条件
      rules: {
        activityName: [
          // {
          //   required: true,
          //   message: "请输入活动名称，支持汉字、数字、字母组合",
          //   trigger: "blur",
          // },
          { min: 2, message: '活动名称长度不能小于2', trigger: 'blur' },
          // { validator: checkName, trigger: "blur" },
        ],
        activityClassify: [
          { required: true, message: '请选择活动分类', trigger: 'change' },
        ],
      },
      activityContent: '', // 返回的文本值

      coursePushTitle: '', // 课程内容推送信息弹窗标题
      coursePushVisible: false, // 课程推送信息弹窗显隐标识
      coursePushHandleType: '', // 课程推送信息弹窗操作类型
      coursePushForm: {
        publishTitle: '',
        classesIds: [],
        techStudent: [],
        isSubmit: '1',
        submitType: [],
        submitEndTime: '',
        description: '',
      }, // 查询条件
      coursePushRules: {
        classesIds: [{ type: 'array', required: true, message: '请选择班级', trigger: 'change' },],
        isSubmit: [{ required: true, message: '请选择是否提交', trigger: 'change' },],
        submitType: [{ type: 'array', required: true, message: '请选择推送提交方式', trigger: 'change' },],
        // submitEndTime: [ { required: true, message: "请选择截止时间", trigger: "change" }, ],
      },
      checkAll: false, // 全选
      isIndeterminate: false, // 半选标识
      classOptions: [], // 班级选项
      studentOptions: [], // 学生下拉
      studentList: [], // 选择的学生

      courseContentId: null, // 编辑课程内容id
      courseContentList: [], // 课程内容详情
      handleType: '', // 操作类型
      contentTitle: '', // 选中目录的标题

      courseContentCatalogId: null, // 课程内容的目录id
      secondInfo: {}, // 二级对象
      secondIndex: null, // 二级对象下标
      firstInfo: {}, // 一级对象
      firstIndex: null, // 一级对象下标

      courseSettingDialogVisible: false, // 内容设置弹窗
      courseSettingData: {}, // 内容设置传值
      courseSettingType: '', // 内容设置类型

      targetSettingDialogVisible: false, // 指标设置弹窗显隐标识
      targetSettingDialogHandleType: '', // 指标设置弹窗类型
      targetSettingData: {}, // 指标设置弹窗数据

      buttonloading: false, // 弹框按钮loading
      pageLoading: null, // 页面loading
      pageButtonLoading: false, // 页面按钮loading
      uploadUrl: (process.env.NODE_ENV == 'development' ? process.env.VUE_APP_LOCAL_URL + '/webForm' : process.env.VUE_APP_LOCAL_URL) + '/api/upload/uploadImgToOSS', // 上传地址
      uploadHeader: { token: this.getToken() },
      editorOption: {
        // 富文本配置
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  document.getElementById('uploadButton').click();
                } else {
                  this.quill.format('image', false);
                }
              },
            },
          },
        },
      },

      contentDialogVisible: false, // 内容编辑弹窗标识
      contentDialogHandleType: '', // 内容编辑弹窗操作类型
      contentDialogType: '', // 内容弹窗类型
      contentData: {}, // 内容弹窗数据

      uploadVideoDialogVisible: false, // 视频上传弹窗标识
      uploadVideoHandleType: '', // 视频上传处理类型
      videoData: {}, // 视频上传数据

      uploadFileDialogVisible: false, // 文件上传弹窗标识
      uploadFileHandleType: '', // 文件上传处理类型
      fileData: {}, // 文件上传数据
      fileTypeList: ['png', 'jpg', 'jpeg', 'gif'], // 文件类型list

      catalogData: [], // 目录结构
      defaultProps: {
        children: 'eduCourseThemeCatalogueTree',
        label: 'catalogueName',
      },

      bodyLeftHeight: '100%',
      bodyRightHeight: '100%',

      videoViewDialogVisible: false, // 视频预览标识
      videoViewType: '', // 视频类型
      videoViewUrl: '', // 视频预览url

      videoSpan: 6, // video宽度
      videoNum: 4, // video个数
      fileNum: 4, // 附件数

      editorContent: '', // 编辑器初始化内容

      recordId: null, // 推送记录id
      courseId: null, // 课程id

      courseViewDialogVisible: false, // 预览
      getType: '', // 获取从何处跳转的类型

      viewUrl: '', // 附件图片预览
      viewUrlList: [], // 附件图片预览list

      noTimeCheck: true, // 截止时间永久
      submitTimeDisabled: true, // 截止时间填写禁用状态
    };
  },
  watch: {
    sendCourseData: {
      immediate: true,
      handler (val) {
        if (val) {
          this.courseId = val.courseId ? Number(val.courseId) : null;
          this.courseContentCatalogId = val.courseCatalogId ? Number(val.courseCatalogId) : null;
          this.contentTitle = val.catalogueName ? val.catalogueName : '';
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.getRecordCourseContentCatalogContentInfo();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      activityTypeOptions: (state) => state.common.activityTypeList,
      submitTypeOptions: (state) => state.common.submitTypeList
    }),
  },
  created () {
    this.courseId = this.$route.query.courseId ? Number(this.$route.query.courseId) : null;
    this.courseContentCatalogId = this.$route.query.courseId ? Number(this.$route.query.courseCatalogId) : null;
    this.contentTitle = this.$route.query.catalogueName ? this.$route.query.catalogueName : '';
    this.recordId = this.$route.query.id ? Number(this.$route.query.id) : null;
    // let that = this;
    // window.onresize = function () {
    //   that.resizeVideoInfo();
    //   that.resizeHeight();
    // };
    // that.resizeVideoInfo();
    this.getClassOptions();
  },
  mounted () {
    if (this.$route.query.courseId) {
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getRecordCourseContentCatalogContentInfo();
    }
  },
  methods: {
    getToken,
    getFileViewUrl,
    exportFile,
    // 调整video个数和宽度
    resizeVideoInfo () {
      console.log(window.outerWidth);
      if (window.outerWidth <= 1800 && window.outerWidth > 1300) {
        this.videoSpan = 7;
        this.videoNum = 3;
      } else if (window.outerWidth <= 1300) {
        this.videoSpan = 10;
        this.videoNum = 2;
      } else if (window.outerWidth > 1800) {
        this.videoSpan = 5;
        this.videoNum = 4;
      }

      if (window.outerWidth <= 1800 && window.outerWidth > 1400) {
        this.fileNum = 3;
      } else if (window.outerWidth <= 1400) {
        this.fileNum = 2;
      } else if (window.outerWidth > 1800) {
        this.fileNum = 4;
      }
    },
    // 调整height
    resizeHeight () {
      console.log(this.$refs.bodyLeft.offsetHeight);
      console.log(this.$refs.bodyRight.offsetHeight);
      this.bodyLeftHeight = 'auto';
      this.bodyRightHeight = 'auto';
      this.$nextTick(() => {
        this.bodyLeftHeight = this.$refs.bodyRight.offsetHeight + 'px';
        this.bodyRightHeight = this.$refs.bodyRight.offsetHeight + 'px';
        if (
          this.$refs.bodyLeft.offsetHeight > this.$refs.bodyRight.offsetHeight
        ) {
          this.bodyLeftHeight = this.$refs.bodyLeft.offsetHeight + 'px';
          this.bodyRightHeight = this.$refs.bodyLeft.offsetHeight + 'px';
        }
      });
    },
    // 获取课程内容详情
    getData (callback) {
      this.$api.getCourseContentInfo(this.courseContentId).then((res) => {
        if (res.data.code === 0) {
          this.courseContentInfo = { ...res.data.data };
        } else {
          this.$message.error(res.data.msg);
        }
        callback && callback();
      });
    },
    // 获取推送记录里的课程内容list
    getRecordCourseContentCatalogContentInfo () {
      let params = {};
      if (this.recordId === null) {
        params.courseThemeCatalogueId = this.courseContentCatalogId;
      } else {
        params.pushInfoId = this.recordId;
      }
      this.$api.getEditPublishInfo(params).then((res) => {
        this.pageLoading.close();
        if (res.data.code === 0) {
          setTimeout(() => {
            if (res.data.data.length > 0) {
              res.data.data.map((item, index) => {
                item.showFlag = true;
                if (item.contentType == '2') {
                  console.log(this.activityTypeOptions);
                  // item.activityTypeRadio = this.activityTypeOptions[0].value;
                  let activityList = [];
                  this.activityTypeOptions.map((activityInfo) => {
                    if (item.eduActivity && item.eduActivity.length > 0) {
                      let index = item.eduActivity.findIndex((info) => {
                        return info.activityClassify == activityInfo.value;
                      });
                      let listIndex = activityList.findIndex((info) => {
                        return info.value == activityInfo.value;
                      });
                      if (index != -1 && listIndex == -1) {
                        activityList.push(activityInfo);
                      }
                    }
                  });
                  if (item.eduActivity && item.eduActivity.length > 0) {
                    item.activityListShow = true;
                  } else {
                    item.activityListShow = false;
                  }
                  if (activityList.length > 0) {
                    item.activityTypeRadio = activityList[0].value;
                    item.activityTypeList = activityList;
                    this.getCourseContentCatalogActivityList(item.id, activityList[0].value, null, null, item, index);
                  }
                }
                if (item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0) {
                  item.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                    let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.'), fileInfo.fileUrl.length);
                    if (type.toLowerCase().indexOf('pdf') != -1) {
                      fileInfo.iconType = 'pdf';
                    }
                    if (type.toLowerCase().indexOf('doc') != -1) {
                      fileInfo.iconType = 'doc';
                    }
                    if (type.toLowerCase().indexOf('xls') != -1) {
                      fileInfo.iconType = 'xls';
                    }
                    if (type.toLowerCase().indexOf('ppt') != -1) {
                      fileInfo.iconType = 'ppt';
                    }
                  });
                }
                if (item.eduCourseThemeCatalogueContentTrees) {
                  item.eduCourseThemeCatalogueContentTrees.map((info, i) => {
                    info.showFlag = true;
                    if (info.contentType == '2') {
                      // info.activityTypeRadio = this.activityTypeOptions[0].value;
                      let activityList = [];
                      this.activityTypeOptions.map((activityInfo) => {
                        if (info.eduActivity && info.eduActivity.length > 0) {
                          let index = info.eduActivity.findIndex((result) => {
                            return result.activityClassify == activityInfo.value;
                          });
                          let listIndex = activityList.findIndex((result) => {
                            return result.value == activityInfo.value;
                          });
                          if (index != -1 && listIndex == -1) {
                            activityList.push(activityInfo);
                          }
                        }
                      });
                      if (info.eduActivity && info.eduActivity.length > 0) {
                        info.activityListShow = true;
                      } else {
                        info.activityListShow = false;
                      }
                      if (activityList.length > 0) {
                        info.activityTypeRadio = activityList[0].value;
                        info.activityTypeList = activityList;
                        this.getCourseContentCatalogActivityList(info.id, activityList[0].value, info, i, item, index);
                      }
                    }
                    if (info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0) {
                      info.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.'), fileInfo.fileUrl.length);
                        if (type.toLowerCase().indexOf('pdf') != -1) {
                          fileInfo.iconType = 'pdf';
                        }
                        if (type.toLowerCase().indexOf('doc') != -1) {
                          fileInfo.iconType = 'doc';
                        }
                        if (type.toLowerCase().indexOf('xls') != -1) {
                          fileInfo.iconType = 'xls';
                        }
                        if (type.toLowerCase().indexOf('ppt') != -1) {
                          fileInfo.iconType = 'ppt';
                        }
                      });
                    }
                  });
                }
              });
              this.courseContentList = res.data.data;
            } else {
              this.courseContentList = [];
            }
          }, 200);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    // 获取课程内容目录的内容list
    getCourseContentCatalogContentInfo () {
      this.$api.getCoursePushInfo({ courseThemeCatalogueId: this.courseContentCatalogId }).then((res) => {
        this.pageLoading.close();
        if (res.data.code === 0) {
          setTimeout(() => {
            if (res.data.data.length > 0) {
              res.data.data.map((item, index) => {
                item.showFlag = true;
                if (item.contentType == '2') {
                  console.log(this.activityTypeOptions);
                  item.activityTypeRadio = this.activityTypeOptions[0].value;
                  if (item.eduActivity && item.eduActivity.length > 0) {
                    item.activityListShow = true;
                  } else {
                    item.activityListShow = false;
                  }
                  this.getCourseContentCatalogActivityList(item.id, this.activityTypeOptions[0].value, null, null, item, index);
                }
                if (item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0) {
                  item.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                    let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.'), fileInfo.fileUrl.length);
                    if (type.toLowerCase().indexOf('pdf') != -1) {
                      fileInfo.iconType = 'pdf';
                    }
                    if (type.toLowerCase().indexOf('doc') != -1) {
                      fileInfo.iconType = 'doc';
                    }
                    if (type.toLowerCase().indexOf('xls') != -1) {
                      fileInfo.iconType = 'xls';
                    }
                    if (type.toLowerCase().indexOf('ppt') != -1) {
                      fileInfo.iconType = 'ppt';
                    }
                  });
                }
                if (item.eduCourseThemeCatalogueContentTrees) {
                  item.eduCourseThemeCatalogueContentTrees.map((info, i) => {
                    info.showFlag = true;
                    if (info.contentType == '2') {
                      info.activityTypeRadio = this.activityTypeOptions[0].value;
                      if (info.eduActivity && info.eduActivity.length > 0) {
                        info.activityListShow = true;
                      } else {
                        info.activityListShow = false;
                      }
                      this.getCourseContentCatalogActivityList(info.id, this.activityTypeOptions[0].value, info, i, item, index);
                    }
                    if (info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0) {
                      info.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.'), fileInfo.fileUrl.length);
                        if (type.toLowerCase().indexOf('pdf') != -1) {
                          fileInfo.iconType = 'pdf';
                        }
                        if (type.toLowerCase().indexOf('doc') != -1) {
                          fileInfo.iconType = 'doc';
                        }
                        if (type.toLowerCase().indexOf('xls') != -1) {
                          fileInfo.iconType = 'xls';
                        }
                        if (type.toLowerCase().indexOf('ppt') != -1) {
                          fileInfo.iconType = 'ppt';
                        }
                      });
                    }
                  });
                }
              });
              this.courseContentList = res.data.data;
            } else {
              this.courseContentList = [];
            }
            this.$nextTick(() => {
              this.resizeVideoInfo();
            });
          }, 200);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取课程内容目录的活动内容list
    getCourseContentCatalogActivityList (id, type, secondItem, secondIndex, firstItem, firstIndex, callback) {
      this.$api.contentCatalogActivityHandle('/api/techactivity/list', { courseThemeCatalogueContentId: id, activityClassify: type, }, 'list').then((res) => {
        if (res.data.code === 0) {
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.activityList = res.data.data;
            this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.activityList = res.data.data;
            this.$set(this.courseContentList, firstIndex, info);
          }
          setTimeout(() => {
            callback && callback();
          }, 300);
        } else {
          this.$message.error(res.data.msg);
          callback && callback();
        }
      });
    },
    // 目录内容设置
    handleCourseContentCatalog () {
      this.handleType = 'add';
      this.courseSettingDialogVisible = true;
      this.$nextTick(() => {
        this.courseSettingType = 'content';
      });
    },
    // 刷新列表
    refreshPage (data) {
      if (this.handleType == 'add' && data) {
        this.targetSettingDialogVisible = true;
        this.$nextTick(() => {
          this.targetSettingDialogHandleType = 'add';
        });
      } else if (this.handleType == 'add' && !data) {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getRecordCourseContentCatalogContentInfo();
      }
      this.courseSettingDialogVisible = false;
      this.$nextTick(() => {
        this.courseSettingType = '';
        this.courseSettingData = {};
        if (this.handleType == 'add' && !data) {
          this.handleType == '';
        }
      });
    },
    // 获取指标设置操作
    getTargetInfo (data) {
      this.targetSettingDialogVisible = false;
      this.$nextTick(() => {
        this.targetSettingDialogHandleType = '';
        this.targetSettingData = {};
        this.handleType == '';
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getRecordCourseContentCatalogContentInfo();
      });
    },
    // 内容操作
    handleContentItem (type, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      switch (type) {
        case 'addFile':
          this.uploadFileDialogVisible = true;
          this.$nextTick(() => {
            this.uploadFileHandleType = 'add';
          });
          break;
        case 'editItem':
          this.contentDialogVisible = true;
          this.$nextTick(() => {
            let contentName = secondItem ? secondItem.contentName : firstItem.contentName;
            let eduCourseThemeContentInfo = secondItem ? secondItem.eduCourseThemeContent : firstItem.eduCourseThemeContent;
            let content = '';
            if (eduCourseThemeContentInfo.length > 0) {
              content = eduCourseThemeContentInfo[0].content;
              this.contentData = {
                id: eduCourseThemeContentInfo[0].id,
                name: contentName,
                content,
              };
              this.contentDialogHandleType = 'edit';
            } else {
              content = '';
              this.contentData = {
                id: '',
                name: contentName,
                content,
              };
              this.contentDialogHandleType = 'add';
            }
            console.log(this.contentData);
            this.contentDialogType = 'normal';
          });
          break;
        case 'addVideoOrActivity':
          let type = secondItem ? secondItem.contentType : firstItem.contentType;
          if (type == '1') {
            this.uploadVideoDialogVisible = true;
            this.$nextTick(() => {
              this.uploadVideoHandleType = 'add';
            });
          } else if (type == '2') {
            this.courseActivityVisible = true;
            this.$nextTick(() => {
              this.courseActivityTitle = '添加活动';
              this.courseActivityHandleType = 'add';
            });
          }
          break;
        case 'turnItem':
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseContentList, firstIndex, info);
          }
          break;
      }
    },
    // 预览富文本图片
    handleImgClick (e) {
      console.log(e);
      if (e.target.nodeName == 'IMG') {
        this.viewUrl = e.target.currentSrc;
        this.viewUrlList = [e.target.currentSrc];
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true;
        });
      }
    },
    // 预览文件
    fileView (fileInfo, type) {
      // console.log(fileInfo, ',,,,');
      if (type == 'view') {
        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
        if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
          this.viewUrl = fileInfo.fileUrl;
          this.viewUrlList = [fileInfo.fileUrl];
          this.$nextTick(() => {
            this.$refs.viewImg.showViewer = true;
          });
        } else {
          const { originalName, fileVersionId } = fileInfo
          this.getFileViewUrl('', { fileVersionId, name: originalName }, (data) => {
            if (data) {
              sessionStorage.setItem('viewUrl', data);
              let routeUrl = this.$router.resolve({ path: '/fileView.html' });
              window.open(routeUrl.href, '_blank');
            }
          });
        }
      } else {
        if (fileInfo.isDownload === '1') {
          let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
          if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
            this.$message.warning('该附件为图片，只可预览查看');
          } else {
            // window.location.href = fileInfo.fileUrl;
            this.exportFile(fileInfo.fileUrl, {}, fileInfo.originalName)
          }
        } else {
          this.$message.warning('该附件不支持下载！');
        }
      }
    },
    // 删除文件
    delFile (fileInfo) {
      // "确定删除该附件, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '确定要删除该附件？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px', }, '是否确定删除该附件，删除后，将无法恢复。'),
        ]), '提示', { confirmButtonText: '确定', cancelButtonText: '取消', iconClass: 'el-icon-question', type: 'warning', customClass: 'warningMessage', }
      ).then(() => {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '操作中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$api.contentCatalogContentFileHandle('/api/techcoursethemecataloguecontentfile/del', { id: fileInfo.id, delFlag: '1' }, 'del').then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success', message: '删除成功!', duration: 2000, onClose: () => {
                this.pageLoading.close();
                this.getRecordCourseContentCatalogContentInfo();
              },
            });
          } else {
            this.pageLoading.close();
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.pageLoading.close();
        });
      }).catch(() => { });
    },
    // 视频预览
    videoView (videoInfo) {
      this.videoViewDialogVisible = true;
      this.$nextTick(() => {
        let type = videoInfo.videoUrl.substr(videoInfo.videoUrl.lastIndexOf('.') + 1, videoInfo.videoUrl.length);
        console.log(type);
        this.videoViewType = type == 'mp4' || type == 'avi' ? 'video' : type == 'mp3' ? 'audio' : '';
        this.videoViewUrl = videoInfo.videoUrl;
      });
    },
    // 视频预览关闭
    videoViewClose () {
      this.videoViewDialogVisible = false;
      this.$nextTick(() => {
        this.videoViewType = '';
        this.videoViewUrl = '';
      });
    },
    // 编辑视频
    editVideo (videoInfo, videoIndex, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      this.uploadVideoDialogVisible = true;
      this.$nextTick(() => {
        this.uploadVideoHandleType = 'edit';
        this.videoData = {
          id: videoInfo.id,
          videoName: videoInfo.videoName || '',
          imageUrl: videoInfo.fontImg || '',
          videoUrl: videoInfo.videoUrl,
          remark: videoInfo.intro,
        };
        console.log(this.videoData);
      });
    },
    // 删除视频
    delVideo (videoInfo) {
      // "确定删除该视频, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '确定要删除该视频？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px', }, '是否确定删除该视频，删除后，将无法恢复。'),
        ]), '提示', { confirmButtonText: '确定', cancelButtonText: '取消', iconClass: 'el-icon-question', type: 'warning', customClass: 'warningMessage', }
      ).then(() => {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$api.contentCatalogVideoHandle('/api/techcoursethemecataloguecontentvideo/del', { id: videoInfo.id, delFlag: '1' }, 'del').then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success', message: '删除成功!', duration: 2000, onClose: () => {
                this.pageLoading.close();
                this.getRecordCourseContentCatalogContentInfo();
              },
            });
          } else {
            this.pageLoading.close();
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.pageLoading.close();
        });
      }).catch(() => { });
    },
    // 活动切换操作
    handleRadioChange (val, secondItem, secondIndex, firstItem, firstIndex) {
      let info = null;
      if (secondItem) {
        info = { ...secondItem };
        info.activityTypeRadio = val;
        this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
        this.getCourseContentCatalogActivityList(secondItem.id, val, info, secondIndex, firstItem, firstIndex);
      } else {
        info = { ...firstItem };
        info.activityTypeRadio = val;
        this.$set(this.courseContentList, firstIndex, info);
        this.getCourseContentCatalogActivityList(firstItem.id, val, secondItem, secondIndex, info, firstIndex);
      }
    },
    // 活动内容操作
    handleActivityItem (type, activityInfo, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      if (type == 'edit') {
        this.courseActivityVisible = true;
        this.$nextTick(() => {
          this.courseActivityTitle = '编辑活动';
          this.courseActivityHandleType = 'edit';
          this.courseActivityForm.id = activityInfo.id;
          this.courseActivityForm.activityClassify = activityInfo.activityClassify;
          this.courseActivityForm.activityName = activityInfo.activityName;
          this.activityContent = activityInfo.activityContent;
          this.editorContent = activityInfo.activityContent;
        });
      } else if (type == 'del') {
        // "确定删除该活动, 是否继续?"
        const h = this.$createElement;
        this.$confirm(
          h('p', null, [
            h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '确定要删除该活动？'),
            h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px', }, '是否确定删除该活动，删除后，将无法恢复。'),
          ]), '提示', { confirmButtonText: '确定', cancelButtonText: '取消', iconClass: 'el-icon-question', type: 'warning', customClass: 'warningMessage', }
        ).then(() => {
          this.$api.contentCatalogActivityHandle('/api/techactivity/del', { id: activityInfo.id, delFlag: '1' }, 'del').then((res) => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success', message: '删除成功!', duration: 2000, onClose: () => {
                  this.pageLoading = this.$loading({
                    lock: true,
                    fullscreen: true,
                    text: '加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                  });
                  this.getRecordCourseContentCatalogContentInfo();
                },
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }).catch(() => { });
      }
    },
    // 富文本上传图片成功方法
    handleSuccess (res) {
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, 'image', res);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        Message.error('图片插入失败');
      }
    },
    // 上传封面成功事件
    handleAvatarSuccess (res, file) {
      console.log(res);
      this.handleSuccess(res);
    },
    // 上传封面之前事件
    beforeAvatarUpload (file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传图片只能是 png, jpg, jpeg, gif 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M; // && isLt2M
    },
    // 弹框关闭
    handleCourseActivityClose () {
      this.courseActivityVisible = false;
      this.$nextTick(() => {
        this.courseActivityForm = {
          activityClassify: '',
          activityName: '',
          // activityContent: "",
        };
        this.editorContent = '';
        this.activityContent = '';
        this.courseActivityTitle = '';
        this.courseActivityHandleType = '';
        this.firstInfo = null;
        this.firstIndex = null;
        this.secondInfo = null;
        this.secondIndex = null;
        this.$refs.courseActivityForm.clearValidate();
      });
    },
    // 弹窗保存
    handleCourseActivitySubmit () {
      this.$refs.courseActivityForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = { ...this.courseActivityForm };
          params.activityContent = this.activityContent;
          let url = '';
          if (this.courseActivityHandleType == 'add') {
            params.courseThemeCatalogueContentId = this.secondInfo ? this.secondInfo.id : this.firstInfo.id;
            url = '/api/techactivity';
          } else {
            url = '/api/techactivity';
          }
          this.$api.contentCatalogActivityHandle(url, params, this.courseActivityHandleType).then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: '保存成功', type: 'success', duration: 2000, onClose: () => {
                  this.buttonloading = false;
                  this.pageLoading = this.$loading({
                    lock: true,
                    fullscreen: true,
                    text: '加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                  });
                  this.getRecordCourseContentCatalogContentInfo();
                  this.handleCourseActivityClose();
                },
              });
            } else {
              this.buttonloading = false;
              this.$message.error(res.data.msg);
            }
          }).catch(() => {
            this.buttonloading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 获取图文值
    getContent (data) {
      this.activityContent = data.content;
    },
    // 课程预览
    courseView () {
      this.courseViewDialogVisible = true;
    },
    // 获取内容编辑操作
    getContentInfo (data) {
      console.log(data);
      let params = {
        courseThemeCatalogueContentId: this.secondInfo ? this.secondInfo.id : this.firstInfo.id,
        contentName: data.formData.name,
        content: data.formData.content,
        id: data.formData.id,
      };
      let url = '';
      if (this.contentDialogHandleType == 'add') {
        url = '/api/techcoursethemecontent';
      } else if (this.contentDialogHandleType == 'edit') {
        url = '/api/techcoursethemecontent';
      }
      this.$api.contentCatalogContentHandle(url, params, 'add').then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success', message: this.contentDialogType == 'add' ? '内容添加成功' : '内容修改成功', duration: 2000, onClose: () => {
              this.contentDialogVisible = false;
              this.$nextTick(() => {
                this.contentData = {};
                this.contentDialogType = '';
                this.contentDialogHandleType = '';
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.secondIndex = null;
                this.getRecordCourseContentCatalogContentInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.contentComponent.buttonloading = false;
        }
      });
    },
    // 获取视频编辑操作
    getVideoInfo (data) {
      let params = {
        videoName: data.formData.videoName,
        fontImg: data.formData.imageUrl,
        videoUrl: data.formData.videoUrl,
        intro: data.formData.remark,
      };
      let url = '';
      if (this.uploadVideoHandleType == 'add') {
        params.courseThemeCatalogueContentId = this.secondInfo ? this.secondInfo.id : this.firstInfo.id;
        url = '/api/techcoursethemecataloguecontentvideo';
      } else if (this.uploadVideoHandleType == 'edit') {
        url = '/api/techcoursethemecataloguecontentvideo';
        params.id = data.formData.id;
      }
      this.$api.contentCatalogVideoHandle(url, params, this.uploadVideoHandleType).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success', message: this.uploadVideoHandleType == 'add' ? '视频添加成功' : '视频修改成功', duration: 2000, onClose: () => {
              this.uploadVideoDialogVisible = false;
              this.$nextTick(() => {
                this.videoData = {};
                this.uploadVideoHandleType = '';
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.secondIndex = null;
                this.getRecordCourseContentCatalogContentInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.uploadVideo.buttonloading = false;
        }
      }).catch(() => {
        this.$refs.uploadVideo.buttonloading = false;
      });
    },
    // 获取文件编辑操作
    getFileInfo (data) {
      const { url, fileId, fileVersionId, name } = data.fileList[0]
      let params = {
        courseThemeCatalogueContentId: this.secondInfo ? this.secondInfo.id : this.firstInfo.id,
        originalName: name,
        fileUrl: url,
        fileVersionId,
        fileId,
        remark: data.formData.remark,
        isDownload: data.formData.isDownload,
      };
      console.log(params);
      let urlStr = '';
      if (this.uploadFileHandleType == 'add') {
        urlStr = '/api/techcoursethemecataloguecontentfile';
      } else if (this.uploadFileHandleType == 'edit') {
        urlStr = '/api/techcoursethemecataloguecontentfile';
        // params.id = ""
      }
      this.$api.contentCatalogContentFileHandle(urlStr, params, this.uploadFileHandleType).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success', message: this.uploadFileHandleType == 'add' ? '附件添加成功' : '附件修改成功', duration: 2000, onClose: () => {
              this.uploadFileDialogVisible = false;
              this.$nextTick(() => {
                this.uploadFileHandleType = '';
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.secondIndex = null;
                this.getRecordCourseContentCatalogContentInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.uploadFile.buttonloading = false;
        }
      }).catch(() => {
        this.$refs.uploadFile.buttonloading = false;
      });
    },
    // 处理取消操作
    handlePushCancel () {
      if (this.recordId) {
        this.tagClose(this.$route.fullPath);
        this.$nextTick(() => {
          this.$router.push('/sendRecordManage');
        });
        return;
      }
      this.$api.cancelPushCourse({ courseThemeCatalogueId: this.courseContentCatalogId }).then((res) => {
        if (res.data.code === 0) {
          if (this.sendCourseData) {
            this.$emit('refreshContent', false)
          } else {
            this.tagClose(this.$route.fullPath);
            this.$nextTick(() => {
              this.$router.push('/courseResource');
            });
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 处理推送操作
    handlePushSubmit () {
      this.pageButtonLoading = true;
      if (this.recordId) {
        this.$api.getPushCourseRecordInfo({ id: this.recordId }).then(res => {
          if (res.data.code === 0) {
            let recordInfo = res.data.data;
            this.coursePushVisible = true;
            this.$nextTick(() => {
              this.coursePushTitle = '推送';
              this.coursePushHandleType = 'edit';
              this.coursePushForm.publishTitle = recordInfo.publishTitle || this.contentTitle;
              this.coursePushForm.classesIds = recordInfo.classLabels ? recordInfo.classLabels.split(',') : [];
              let studentList = [];
              if (recordInfo.classLabels) {
                let classIds = recordInfo.classLabels.split(',');
                let studentList = [];
                this.classOptions.map((item) => {
                  let index = classIds.findIndex((info) => {
                    return info == item.classLabel;
                  });
                  if (index != -1) {
                    if (item.students) {
                      let studentInfoList = JSON.parse(JSON.stringify(item.students));
                      studentInfoList.map((info) => {
                        info.classLabel = item.classLabel;
                        info.disabled = false
                      });
                      studentList = studentList.concat(studentInfoList);
                    }
                  }
                });
                studentList = [{ studentName: '全部学生', studentId: '' }].concat(studentList)
                this.studentOptions = JSON.parse(JSON.stringify(studentList));
              }
              if (recordInfo.submitType) {
                this.coursePushForm.isSubmit = recordInfo.submitType != '' ? '1' : '0';
                this.coursePushForm.submitType = recordInfo.submitType ? recordInfo.submitType.split(',') : [];
                if (this.coursePushForm.submitType.length > 0 && this.coursePushForm.submitType.length < this.submitTypeOptions.length) {
                  this.isIndeterminate = true;
                } else {
                  this.isIndeterminate = false;
                }
                if (this.coursePushForm.submitType.length == this.submitTypeOptions.length) {
                  this.checkAll = true;
                } else {
                  this.checkAll = false;
                }
                if (this.coursePushForm.submitType.length > 0) {
                  if (recordInfo.submitEndTime) {
                    this.noTimeCheck = false;
                    this.submitTimeDisabled = false;
                  } else {
                    this.noTimeCheck = true;
                    this.submitTimeDisabled = true;
                  }
                }
              } else if (recordInfo.submitType === null) {
                this.noTimeCheck = true;
                this.submitTimeDisabled = true;
                this.coursePushForm.isSubmit = '1';
                this.checkAll = true;
                this.isIndeterminate = false;
                let submitTypeList = [];
                this.submitTypeOptions.map((item) => {
                  submitTypeList.push(item.value);
                });
                this.coursePushForm.submitType = submitTypeList;
                this.coursePushForm.submitEndTime = '';
              } else {
                this.coursePushForm.isSubmit = '0';
                this.checkAll = false;
                this.isIndeterminate = false;
                this.coursePushForm.submitType = [];
                this.coursePushForm.submitEndTime = recordInfo.submitEndTime;
                if (recordInfo.submitEndTime) {
                  this.noTimeCheck = false;
                  this.submitTimeDisabled = false;
                } else {
                  this.noTimeCheck = true;
                  this.submitTimeDisabled = true;
                }
              }
              this.coursePushForm.description = recordInfo.description || '';
              this.pageButtonLoading = false;
              this.$nextTick(() => {
                this.$refs.coursePushForm.clearValidate();
                if (recordInfo.studentTestList && recordInfo.studentTestList.length > 0) {
                  this.studentList = []
                  recordInfo.studentTestList.map((item) => {
                    this.studentList.push(item.studentLabel);
                    this.coursePushForm.techStudent.push(item);
                  });
                } else {
                  if (this.studentOptions.length > 0) {
                    this.studentList = [''];
                    this.studentOptions.map((item) => {
                      item.disabled = true;
                    });
                  }
                }
              });
            });
          } else {
            this.pageButtonLoading = false;
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.pageButtonLoading = false;
        });
      } else {
        this.coursePushVisible = true;
        this.$nextTick(() => {
          this.coursePushTitle = '推送';
          this.coursePushHandleType = 'add';
          this.coursePushForm.publishTitle = this.contentTitle;
          this.noTimeCheck = true;
          this.submitTimeDisabled = true;
          this.checkAll = true;
          this.isIndeterminate = false;
          let submitTypeList = [];
          this.submitTypeOptions.map((item) => {
            submitTypeList.push(item.value);
          });
          this.coursePushForm.submitType = submitTypeList;
          this.pageButtonLoading = false;
        });
      }
    },
    // 获取推送的班级选项
    getClassOptions () {
      this.$api.getCourseClassList({}).then(res => {
        if (res.data.code === 0) {
          this.classOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 处理推送的班级选项变化
    handlePushClassChange (val) {
      console.log(val);
      this.coursePushForm.classesIds = val;
      if (val.length == 0) {
        this.studentOptions = [];
        this.studentList = [];
        return;
      }
      let studentList = [];
      this.classOptions.map((item) => {
        let index = val.findIndex((result) => {
          return result == item.classLabel;
        });
        if (index != -1) {
          if (item.students) {
            let studentInfoList = JSON.parse(JSON.stringify(item.students));
            studentInfoList.map((info) => {
              info.classLabel = item.classLabel;
              info.disabled = true;
            });
            studentList = studentList.concat(studentInfoList);
          }
        }
      });
      studentList = [{ studentName: '全部学生', studentId: '' }].concat(studentList);
      this.$nextTick(() => {
        this.studentOptions = JSON.parse(JSON.stringify(studentList));
        this.studentList = [''];
      });
    },
    // 处理推送的学生选项变化
    handlePushStudentChange (val) {
      console.log(val);
      if (val.length == 0) {
        this.coursePushForm.techStudent = [];
        this.studentList = val;
        this.studentOptions.map((item, i) => {
          item.disabled = false;
        });
        return;
      }
      let allIndex = val.findIndex((item) => {
        return item === '';
      });
      if (allIndex != -1) {
        this.coursePushForm.techStudent = [];
        this.studentList = [''];
        this.studentOptions.map((item, i) => {
          if (i !== 0) {
            item.disabled = true;
          }
        });
      } else {
        let studentList = [];
        this.studentOptions.map((item) => {
          let index = val.findIndex((result) => {
            return result == item.studentId;
          });
          if (index != -1) {
            let studentInfo = { studentLabel: item.studentId, classesLabel: item.classLabel };
            studentList.push(studentInfo);
          }
        });
        this.coursePushForm.techStudent = JSON.parse(JSON.stringify(studentList));
      }
    },
    // 处理推送的学生选项变化
    handleEndTimeChange (val) {
      console.log(val);
      this.coursePushForm.submitEndTime = val ? val : '';
      this.$nextTick(() => {
        this.$refs.coursePushForm.validateField('submitEndTime');
      });
    },
    // 处理是否提交选项变化
    handleIsSubmitChange () {
      if (this.coursePushForm.isSubmit === '0') {
        this.coursePushForm.submitType = [];
        this.coursePushForm.submitEndTime = '';
        this.noTimeCheck = true;
        this.submitTimeDisabled = true;
        this.isIndeterminate = false;
        this.checkAll = false;
      } else if (this.coursePushForm.isSubmit === '1') {
        this.checkAll = true;
        this.isIndeterminate = false;
        let submitTypeList = [];
        this.submitTypeOptions.map((item) => {
          submitTypeList.push(item.value);
        });
        this.coursePushForm.submitType = submitTypeList;
      }
    },
    // 是否全选提交方式
    handleCheckAllChange (val) {
      let submitTypeChooseList = [];
      if (val) {
        this.submitTypeOptions.map((item) => {
          submitTypeChooseList.push(item.value);
        });
      }
      this.coursePushForm.submitType = val ? submitTypeChooseList : [];
      this.isIndeterminate = false;
    },
    // 截止时间是否永久
    handleEndTimeTypeChange (val) {
      if (val) {
        this.submitTimeDisabled = true;
        this.coursePushForm.submitEndTime = '';
        this.$refs.coursePushForm.clearValidate('submitEndTime');
      } else {
        this.submitTimeDisabled = false;
      }
    },
    // 处理提交选项变化
    handleCheckedSubmitTypeChange (value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.submitTypeOptions.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.submitTypeOptions.length;
    },
    // 推送弹窗关闭
    handleCoursePushClose () {
      this.coursePushVisible = false;
      this.$nextTick(() => {
        this.coursePushForm = {
          publishTitle: '',
          classesIds: [],
          techStudent: [],
          isSubmit: '1',
          submitType: [],
          submitEndTime: '',
          description: '',
        };
        this.studentList = [];
        this.coursePushTitle = '';
        this.coursePushHandleType = '';
        this.$refs.coursePushForm.clearValidate();
      });
    },
    // 推送弹窗提交
    handleCoursePushSubmit () {
      this.$refs.coursePushForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            publishTitle: this.coursePushForm.publishTitle,
            classesIds: this.coursePushForm.classesIds.join(','),
            submitType: this.coursePushForm.submitType.join(','),
            submitEndTime: this.coursePushForm.submitEndTime,
            description: this.coursePushForm.description,
            techStudent: this.coursePushForm.techStudent,
            status: 1
          };
          params.courseThemeCatalogueContentId = this.courseContentCatalogId;
          if (this.coursePushHandleType == 'add') {
            this.$api.pushCourse(params).then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  message: '推送成功', type: 'success', duration: 2000, onClose: () => {
                    this.buttonloading = false;
                    this.handleCoursePushClose();
                    this.$nextTick(() => {
                      if (this.sendCourseData) {
                        this.$emit('refreshContent', true)
                      } else {
                        this.tagClose(this.$route.fullPath);
                        this.$nextTick(() => {
                          this.$router.push('/courseResource');
                        });
                      }
                    });
                  },
                });
              } else {
                this.buttonloading = false;
                this.$message.error(res.data.msg);
              }
            }).catch(() => {
              this.buttonloading = false;
            });
          } else if (this.coursePushHandleType == 'edit') {
            params.publishId = this.recordId;
            this.$api.pushNoSendCourse(params).then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  message: '推送成功', type: 'success', duration: 2000, onClose: () => {
                    this.buttonloading = false;
                    this.handleCoursePushClose();
                    this.$nextTick(() => {
                      this.tagClose(this.$route.fullPath);
                      this.$nextTick(() => {
                        this.$router.push('/sendRecordManage');
                      });
                    });
                  },
                });
              } else {
                this.buttonloading = false;
                this.$message.error(res.data.msg);
              }
            }).catch(() => {
              this.buttonloading = false;
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 处理保存操作
    handlePushSave () {
      this.pageButtonLoading = true;
      this.$api.pushCourse({ courseThemeCatalogueContentId: this.courseContentCatalogId, status: '0' }).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: '保存成功', type: 'success', duration: 2000, onClose: () => {
              this.pageButtonLoading = false;
              // this.pageLoading = true
              // this.getCourseContentCatalogContentInfo();
            },
          });
        } else {
          this.buttonloading = false;
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.buttonloading = false;
      });
    },
  },
  beforeDestroy () {
    window.onresize = null;
  },
};
</script>
<style lang="scss" scoped>
.push-course-edit {
  width: 100%;
  height: 100%;
}
.push-course-body {
  width: calc(100% - 56px);
  padding: 0 28px;
  background-color: #ffffff;
  .content-title {
    width: 100%;
    padding: 35px 0 17px 0;
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 65px;
      height: 31px;
    }
    .view-button {
      padding: 0 10px;
      height: 27px;
      border: 2px solid #dcdfe6;
      color: rgba(0, 0, 0, 0.75);
      background-color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      line-height: 26px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
  .footer-buttons {
    width: 100%;
    padding: 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .push-course-button {
      &.el-button--primary {
        background-color: #ffffff;
        &:hover {
          background-color: #597ef7;
          color: #ffffff;
        }
        &:focus {
          background-color: #597ef7;
          color: #ffffff;
        }
      }
    }
  }
}
.info-body.video-item {
  .bottom {
    .video-button {
      font-size: 16px;
    }
  }
}
//tab样式
/deep/.el-tabs__nav-wrap {
  padding: 0 20px;
  height: 60px;
}

/deep/.el-tabs__nav {
  font-size: 20px;
  height: 60px;
  .el-tabs__item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}

/deep/.el-textarea {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  &:hover {
    border-color: #c0c4cc;
  }
}
/deep/.el-textarea__inner {
  margin-bottom: 18px;
  border: none;
}
/deep/.el-input__count {
  bottom: 10px;
  line-height: normal;
  &::before {
    content: '已输入 ';
  }
}
</style>
