<template>
  <div class="course-resource-outside"
       :class="$store.state.isSelect?'':'no_select'">
    <el-backtop target=".course-resource"
                class="top-back"
                :visibility-height="5"
                @click="handleBackTop"></el-backtop>
    <div class="course-resource"
         ref="courseResoureMain"
         :style="{ 'overflow-y': mainFlowFlag }"
         @scroll="mainScroll">
      <!--v-loading="pageLoading"-->
      <div class="course-resource-title">
        <div class="text">
          <span>课程资源</span>
          <div class="line"></div>
        </div>
      </div>
      <el-card class="box-card course"
               shadow="never"
               v-if="courseList.length > 0">
        <div class="course-list">
          <div class="list-left"
               @click="moveCourse('prev')">
            <img :src="coursePanelNewLeftIcon"
                 alt="" />
          </div>
          <div class="list-info">
            <div class="list-inside-info"
                 :style="{ width: courseListWidth, transform: 'translateX(' + moveWidth + ')', transition: 'transform 1s ease 0s', }">
              <div class="info-item"
                   :class="{ active: item.isChoose === true }"
                   v-for="(item,i) in courseList"
                   :key="item.id"
                   :style="{ 'background-image': 'url(' + (item.isChoose === true ? courseItemNewActiveBgImg : courseItemNewBgImg) + ')', 'background-size': '100% 100%', }"
                   @click="courseClick(item,i)">
                <div class="item-content">
                  <el-tooltip class="item"
                              effect="light"
                              popper-class="customPopper"
                              :content="item.courseName"
                              placement="top"
                              v-if="item.courseName">
                    <div class="item-title">{{ item.courseName }}</div>
                  </el-tooltip>
                  <el-tooltip class="item"
                              effect="light"
                              popper-class="customPopper"
                              :content="item.courseSuitable ? item.courseSuitableName : item.courseLevelName"
                              placement="top"
                              v-if="item.courseSuitableName || item.courseLevelName">
                    <div class="item-ltitle">{{ item.courseSuitable ? item.courseSuitableName : item.courseLevelName }}</div>
                  </el-tooltip>
                  <!-- <div class="item-button" v-if="item.isChoose === true" @click.stop="getCourseIntro(item)">课程介绍</div> -->
                </div>
                <!-- <div class="item-del" v-show="item.isChoose === true" @click.stop="handleCourseItemDel(item, i)">
                  <img :src="courseItemDelIcon" alt="" />
                </div> -->
              </div>
              <!-- <div class="info-item add" :style="{ 'background-image': 'url(' + courseItemAddBgImg + ')', 'background-size': '100% 100%', }" @click="showAdd" v-show="isAdd">
                <div class="item-content">
                  <div class="add-button">
                    <i class="el-icon-plus"></i>
                    <span>添加课程</span>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="list-right"
               @click="moveCourse('next')">
            <img :src="coursePanelNewRightIcon"
                 alt="" />
          </div>
        </div>
        <div class="add-course"
             @click="showAdd"
             v-show="isAdd">
          <img class="search-select-icon"
               :class="{ turn: !isAddShow }"
               :src="courseItemSelectionMore" />
          <div class="add-course-text">{{ isAddShow ? '收起筛选' : '展开筛选' }}</div>
        </div>
      </el-card>
      <el-card class="box-card selection"
               :class="{ 'shadow': isHaveCourse && isAddShow }"
               shadow="never"
               v-show="isAddShow">
        <div class="course-choose-arraw"
             v-if="isHaveCourse && isAddShow"></div>
        <div class="course-choose-content">
          <div class="content-item">
            <div class="label">分类：</div>
            <div class="item-list"
                 ref="courseTypeList"
                 :style="{ height: courseTypeListHeight, 'overflow-y': isCourseTypeTurnShow && !courseTypeTurnFlag ? 'hidden' : 'auto' }">
              <div class="item"
                   :class="{ active: addCourseForm.courseType === '' }"
                   @click="handleChooseClick('courseType', '')">全部</div>
              <div class="item"
                   v-for="item in typeOptions"
                   :key="item.id"
                   :class="{ active: addCourseForm.courseType === item.value }"
                   @click="handleChooseClick('courseType', item.value)"
                   :style="{ 'margin-bottom': (courseTypeListHeight != 'auto' && !isCourseTypeTurnShow) || (courseTypeListHeight == 'auto' && isCourseTypeTurnShow) ? '0.625vw' : '0' }">{{ item.name }}</div>
            </div>
            <div class="turn-icon"
                 :style="{ visibility: isCourseTypeTurnShow ? 'visible' : 'hidden', cursor: isCourseTypeTurnShow ? 'pointer' : 'none' }"
                 @click="handleShowInfo('courseType')">
              {{ !courseTypeTurnFlag ? '展开' : '收起' }}<i :class="{ 'el-icon-arrow-down': !courseTypeTurnFlag, 'el-icon-arrow-up': courseTypeTurnFlag }"></i>
            </div>
          </div>
          <div class="content-item">
            <div class="label">领域：</div>
            <div class="item-list"
                 ref="courseFieldList"
                 :style="{ height: courseFieldListHeight, 'overflow-y': isCourseFieldTurnShow && !courseFieldTurnFlag ? 'hidden' : 'auto' }">
              <div class="item"
                   :class="{ active: addCourseForm.courseField === '' }"
                   @click="handleChooseClick('courseField', '')">全部</div>
              <div class="item"
                   v-for="item in areaOptions"
                   :key="item.id"
                   :class="{ active: addCourseForm.courseField === item.value }"
                   @click="handleChooseClick('courseField', item.value)"
                   :style="{ 'margin-bottom': (courseFieldListHeight != 'auto' && !isCourseFieldTurnShow) || (courseFieldListHeight == 'auto' && isCourseFieldTurnShow) ? '0.625vw' : '0' }">{{ item.name }}</div>
            </div>
            <div class="turn-icon"
                 :style="{ visibility: isCourseFieldTurnShow ? 'visible' : 'hidden', cursor: isCourseFieldTurnShow ? 'pointer' : 'none' }"
                 @click="handleShowInfo('courseField')">
              {{ !courseFieldTurnFlag ? '展开' : '收起' }}<i :class="{ 'el-icon-arrow-down': !courseFieldTurnFlag, 'el-icon-arrow-up': courseFieldTurnFlag }"></i>
            </div>
          </div>
          <div class="content-item">
            <div class="label">课程：</div>
            <div class="item-list"
                 ref="courseThemeList"
                 :style="{ height: courseThemeListHeight, 'overflow-y': isCourseThemeTurnShow && !courseThemeTurnFlag ? 'hidden' : 'auto' }">
              <div class="item"
                   v-for="item in searchThemeOptions"
                   :key="item.id"
                   :class="{ active: addCourseForm.courseTheme === item.value }"
                   @click="handleChooseClick('courseTheme', item.value)"
                   :style="{ 'margin-bottom': (courseThemeListHeight != 'auto' && !isCourseThemeTurnShow) || (courseThemeListHeight == 'auto' && isCourseThemeTurnShow) ? '0.625vw' : '0' }">{{ item.name }}</div>
            </div>
            <div class="turn-icon"
                 :style="{ visibility: isCourseThemeTurnShow ? 'visible' : 'hidden', cursor: isCourseThemeTurnShow ? 'pointer' : 'none' }"
                 @click="handleShowInfo('courseTheme')">
              {{ !courseThemeTurnFlag ? '展开' : '收起' }}<i :class="{ 'el-icon-arrow-down': !courseThemeTurnFlag, 'el-icon-arrow-up': courseThemeTurnFlag }"></i>
            </div>
          </div>
          <div class="content-item">
            <div class="label">版本：</div>
            <div class="item-list"
                 ref="courseVersionList"
                 :style="{ height: courseVersionListHeight, 'overflow-y': isCourseVersionTurnShow && !courseVersionTurnFlag ? 'hidden' : 'auto' }">
              <div class="item"
                   v-for="item in versionOptions"
                   :key="item.id"
                   :class="{ active: addCourseForm.courseVersion === item.value }"
                   @click="handleChooseClick('courseVersion', item.value)"
                   :style="{ 'margin-bottom': (courseVersionListHeight != 'auto' && !isCourseVersionTurnShow) || (courseVersionListHeight == 'auto' && isCourseVersionTurnShow) ? '0.625vw' : '0' }">{{ item.name }}</div>
            </div>
            <div class="turn-icon"
                 :style="{ visibility: isCourseVersionTurnShow ? 'visible' : 'hidden', cursor: isCourseVersionTurnShow ? 'pointer' : 'none' }"
                 @click="handleShowInfo('courseVersion')">
              {{ !courseVersionTurnFlag ? '展开' : '收起' }}<i :class="{ 'el-icon-arrow-down': !courseVersionTurnFlag, 'el-icon-arrow-up': courseVersionTurnFlag }"></i>
            </div>
          </div>
          <div class="content-item"
               style="border: none;">
            <div class="label">阶段：</div>
            <div class="item-list"
                 ref="scopeList"
                 :style="{ height: scopeListHeight, 'overflow-y': 'hidden' }">
              <div class="item"
                   v-for="item in scopeOptions"
                   :key="item.id"
                   :class="{ active: (addCourseForm.ageScope && addCourseForm.ageScope === item.value && item.type == 'age_scope') || (addCourseForm.courseLevel && addCourseForm.courseLevel === item.value && item.type == 'class_level') }"
                   @click="handleChooseClick('scope', item.value, item)"
                   :style="{ 'margin-bottom': (scopeListHeight != 'auto' && !isScopeTurnShow) || (scopeListHeight == 'auto' && isScopeTurnShow) ? '0.625vw' : '0' }">{{ item.name }}</div>
            </div>
            <div class="turn-icon"
                 :style="{ visibility: isScopeTurnShow ? 'visible' : 'hidden',cursor: isScopeTurnShow ? 'pointer' : 'none' }"
                 @click="handleShowInfo('scope')">
              {{ !scopeTurnFlag ? '展开' : '收起' }}<i :class="{ 'el-icon-arrow-down': !scopeTurnFlag, 'el-icon-arrow-up': scopeTurnFlag }"></i>
            </div>
          </div>
          <!-- <div class="content-item" style="border: none;">
            <div class="label">等级：</div>
            <div class="item-list" ref="courseLevelList" :style="{ height: courseLevelListHeight, 'overflow-y': isCourseLevelTurnShow && !courseLevelTurnFlag ? 'hidden' : 'auto' }">
              <div class="item" :class="{ active: addCourseForm.courseLevel === '' }" @click="handleChooseClick('courseLevel', '')">全部</div>
              <div class="item" v-for="item in levelOptions" :key="item.id" :class="{ active: addCourseForm.courseLevel === item.value }" @click="handleChooseClick('courseLevel', item.value)">{{ item.name }}</div>
            </div>
            <div class="turn-icon" :style="{ visibility: isCourseLevelTurnShow ? 'visible' : 'hidden', cursor: isCourseLevelTurnShow ? 'pointer' : 'none' }" @click="handleShowInfo('courseLevel')">
              {{ !courseLevelTurnFlag ? '展开' : '收起' }}<i :class="{ 'el-icon-arrow-down': !courseLevelTurnFlag, 'el-icon-arrow-up': courseLevelTurnFlag }"></i>
            </div>
          </div> -->
          <!-- <div class="content-item" style="border: none;">
            <div class="label">课程：</div>
            <div class="item-list" ref="searchCourseList" :style="{ height: searchCourseListHeight, 'overflow-y': isSearchCourseTurnShow ? 'hidden' : 'auto' }">
              <div class="item" v-for="item in searchCourseList" :key="item.id" :class="{ active: addCourseForm.courseId == item.id }" @click="handleChooseClick('courseId', item.id)">{{ item.courseName }}</div>
            </div>
            <div class="turn-icon" :style="{ visibility: isSearchCourseTurnShow ? 'visible' : 'hidden', cursor: isSearchCourseTurnShow ? 'pointer' : 'none'}" @click="handleShowInfo('searchCourse')">
              {{ !searchCourseTurnFlag ? '展开' : '收起' }}<i :class="{ 'el-icon-arrow-down': !searchCourseTurnFlag, 'el-icon-arrow-up': searchCourseTurnFlag }"></i>
            </div>
          </div> -->
        </div>
        <!-- <div class="footer-button">
          <el-button class="add-course-button" @click="hideAdd" plain round>取消</el-button>
          <el-button class="add-course-button" type="primary" @click="handleAddCourseContact" plain round><i class="el-icon-plus"></i>我的课程</el-button>
        </div> -->
      </el-card>
      <div class="course-show search"
           ref="searchCourseShow"
           v-if="isAddShow"
           :style="{ height: searchCourseList.length > 1 ? '8.125vw' : '6.875vw' }">
        <!--:class="{'fixedShow': fixedFlag }"   , width: fixedFlag ? showWidth+'px' : '100%'-->
        <div class="course-show-outside"
             :style="{ width: searchCourseWidth, transform: 'translateX(' + searchMoveWidth + 'px )', transition: 'transform 1s ease 0s' }"
             v-if="searchCourseList.length > 0">
          <div class="course-show-item"
               v-for="(item,i) in searchCourseList"
               :key="item.id"
               @mousedown="e => handleMoveDownCourse(e,item,i)"
               @mouseup="e => handleMoveUpCourse(e,item,i)">
            <img class="course-show-img"
                 :src="item.coverImg ? item.coverImg : defaultImg"
                 alt="" />
            <div class="course-show-info">
              <div class="info-title">{{ item.courseName }}</div>
              <div class="info-description">
                <span class="label">课程描述：</span>
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            :content="item.description"
                            placement="top"
                            v-if="item.description">
                  <span v-if="item.description">{{ item.description }}</span>
                </el-tooltip>
                <span v-if="!item.description">暂无</span>
              </div>
            </div>
            <div class="course-show-buttons">
              <div class="course-intro-button"
                   @click="getCourseIntro(item)">课程介绍</div>
              <div class="course-submit-button"
                   :class="{ 'cancel': item.isLike, 'like': !item.isLike }"
                   @click="item.isLike ? handleCourseItemDel(item,i) : handleAddCourseContact()"><img class="collect-icon"
                     :src="item.isLike ? courseCancelLikeIcon : courseLikeIcon"
                     alt="" /><span>{{ item.isLike ? '取消收藏' : '收藏' }}</span></div>
            </div>
          </div>
        </div>
        <div class="course-show-outside"
             :style="{ width: '100%'}"
             v-if="searchCourseList.length == 0">
          <div class="course-show-empty">
            <img :src="searchCourseEmptyImg"
                 alt="">
            <div class="course-show-empty-text">暂无课程</div>
          </div>
        </div>
        <div class="course-show-tips"
             v-show="searchCourseList.length > 1">
          <div class="course-show-tip"
               :class="{ 'active': item.isShow }"
               v-for="(item,i) in searchCourseTipList"
               :key="item.id"
               @click="handleTipChange(item,i)"></div>
        </div>
      </div>
      <div class="course-show"
           v-show="!isAddShow && isHaveCourse"
           ref="courseShow">
        <!-- :class="{'fixedShow': fixedFlag }" :style="{ width: fixedFlag ? 'calc('+showWidth+'px - 1.875vw)' : 'calc(100% - 1.875vw)' }" -->
        <div class="course-show-outside"
             style="width: 100%;">
          <div class="course-show-item">
            <img class="course-show-img"
                 :src="showCourseBaseInfo.coverImg ? showCourseBaseInfo.coverImg : defaultImg"
                 alt="" />
            <div class="course-show-info">
              <div class="info-title">{{ showCourseBaseInfo.courseName }}</div>
              <div class="info-description">
                <span class="label">课程描述：</span>
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            :content="showCourseBaseInfo.description"
                            placement="top"
                            v-if="showCourseBaseInfo.description">
                  <span v-if="showCourseBaseInfo.description">{{ showCourseBaseInfo.description }}</span>
                </el-tooltip>
                <span v-if="!showCourseBaseInfo.description">暂无</span>
              </div>
            </div>
            <div class="course-show-buttons">
              <div class="course-intro-button"
                   @click="getCourseIntro(showCourseBaseInfo)">课程介绍</div>
              <div class="course-submit-button cancel"
                   @click="handleCourseItemDel(showCourseBaseInfo)"><img class="collect-icon"
                     :src="courseCancelLikeIcon"
                     alt="" /><span>取消收藏</span></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="ZWDiv" v-if="fixedFlag"></div> -->
      <div class="content-body"
           v-show="isShow"
           ref="courseBody">
        <!--:style="{ 'overflow-y': showFlowFlag, height: showFlowFlag == 'auto' ? 'calc(100% - 12.5vw)' : 'auto' }"-->
        <!--v-show="isShow"-->
        <div class="body-left"
             ref="bodyLeft"
             :style="{ height: bodyLeftHeight }">
          <!--v-if="showMenuFlag"-->
          <el-menu :default-active="activeIndex"
                   class="el-menu-demo"
                   @open="handleOpenMenu"
                   :unique-opened="true"
                   v-if="courseThemeList.length > 0">
            <el-submenu :index="(item.id+'')"
                        v-for="(item,i) in courseThemeList"
                        :key="(item.id+'')">
              <template slot="title">
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            :content="item.courseThemeName"
                            placement="top-start">
                  <div class="submenu-title">{{ item.courseThemeName }}</div>
                </el-tooltip>
              </template>
              <el-menu-item :index="(item.id+'_1')">
                <div class="tree-list"
                     v-if="item.catalogData && item.catalogData.length > 0">
                  <div class="tree-item"
                       v-for="(treeInfo,j) in item.catalogData"
                       :key="treeInfo.id">
                    <div class="tree-title"
                         :class="{ parentShow: treeInfo.isOpen === '1' ,active: treeInfo.id == courseContentCatalogId }"
                         @click="handleTreeClick(treeInfo,i,treeInfo,j,null,null)">
                      <div :style="{'font-size': treeInfo.children && treeInfo.children.length > 0 ? '0.9375vw' : '0.88542vw','font-weight': treeInfo.children && treeInfo.children.length > 0 ? '500' : '400',color: treeInfo.children && treeInfo.children.length > 0 ? '#000000' : 'rgba(0, 0, 0, 0.75)'}">{{ treeInfo.catalogueName }}</div>
                      <div v-if="treeInfo.isOpen === '1'"
                           class="parent-show">家长</div>
                      <i :class="{ 'el-icon-caret-top': treeInfo.isShowTree, 'el-icon-caret-bottom': !treeInfo.isShowTree }"
                         v-if="treeInfo.children"
                         @click.stop="handleTreeTurn(item,i,treeInfo,j,null,null)"></i>
                    </div>
                    <div v-if="treeInfo.children && treeInfo.isShowTree"
                         class="sub-tree-list">
                      <div class="tree-item"
                           v-for="(subTreeInfo,k) in treeInfo.children"
                           :key="subTreeInfo.id">
                        <div class="tree-title"
                             :style="{ 'padding-left': '2.60417vw', 'width': subTreeInfo.id == courseContentCatalogId ? ( subTreeInfo.isOpen === '1' ? 'calc(100% - 6.04167vw)' : 'calc(100% - 3.75vw)' ) : ( subTreeInfo.isOpen === '1' ? 'calc(100% - 5.83333vw)' : 'calc(100% - 3.54167vw)' )}"
                             :class="{ parentShow: subTreeInfo.isOpen === '1' , active: subTreeInfo.id == courseContentCatalogId, }"
                             @click="handleTreeClick(subTreeInfo,i,treeInfo,j,subTreeInfo,k)">
                          <div :style="{'font-size': subTreeInfo.children && subTreeInfo.children.length > 0 ? '0.9375vw' : '0.88542vw','font-weight': subTreeInfo.children && subTreeInfo.children.length > 0 ? '500' : '400',color: subTreeInfo.children && subTreeInfo.children.length > 0 ? '#000000' : 'rgba(0, 0, 0, 0.75)'}">{{ subTreeInfo.catalogueName }}</div>
                          <div v-if="subTreeInfo.isOpen === '1'"
                               class="parent-show">家长</div>
                          <i :class="{ 'el-icon-caret-top': subTreeInfo.isShowTree, 'el-icon-caret-bottom': !subTreeInfo.isShowTree }"
                             style="left: 1.5625vw;"
                             v-if="subTreeInfo.children"
                             @click.stop="handleTreeTurn(item,i,treeInfo,j,subTreeInfo,k)"></i>
                        </div>
                        <div v-if="subTreeInfo.children && subTreeInfo.isShowTree"
                             class="sub-tree-list">
                          <div class="tree-item"
                               v-for="thirdTreeInfo in subTreeInfo.children"
                               :key="thirdTreeInfo.id">
                            <div class="tree-title"
                                 :style="{ 'padding-left': '3.125vw' ,width: thirdTreeInfo.id == courseContentCatalogId ? ( thirdTreeInfo.isOpen === '1' ? 'calc(100% - 6.5625vw)' : 'calc(100% - 4.27083vw)' ) : ( thirdTreeInfo.isOpen === '1' ? 'calc(100% - 6.35417vw)' : 'calc(100% - 4.0625vw)' ) }"
                                 :class="{ parentShow: thirdTreeInfo.isOpen === '1' , active: thirdTreeInfo.id == courseContentCatalogId, }"
                                 @click="handleTreeClick(thirdTreeInfo)">
                              <div :style="{'font-size': '0.88542vw','font-weight': '400',color: 'rgba(0, 0, 0, 0.75)'}">{{ thirdTreeInfo.catalogueName }}</div>
                              <div v-if="thirdTreeInfo.isOpen === '1'"
                                   class="parent-show">家长</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <div class="body-right"
             ref="bodyRight"
             :style="{ height: bodyRightHeight }">
          <div class="content-title">
            <span>{{ contentTitle }}</span>
            <!-- <el-tooltip class="item" effect="light" popper-class="customPopper" content="内容推送" placement="top" v-show="isParentShow && isPush"> -->
            <!-- <img :src="detailPushIcon" alt="" @click="coursePush" v-show="isParentShow && isPush"/> -->
            <!-- </el-tooltip> -->
            <div class="push-button"
                 @click="coursePush"
                 v-show="isParentShow && isPush && pushText">{{ pushText }}</div>
          </div>
          <div class="first-panel"
               v-if="courseContentTargetInfo.length > 0">
            <div class="panel-header">
              <div class="title">
                <div class="title-icon"></div>目标清单
              </div>
              <div class="button"></div>
            </div>
            <div class="panel-content">
              <el-table class="tableDialog"
                        :data="courseContentTargetInfo"
                        style="width: 100%;margin-top: 0.67708vw;"
                        ref="dialog-table"
                        v-loading="tableLoading">
                <el-table-column prop="descriptionCode"
                                 label="指标编号"
                                 width="200"
                                 align="center"></el-table-column>
                <el-table-column prop="descriptionCode"
                                 label="指标库"
                                 width="250"
                                 align="center"></el-table-column>
                <el-table-column prop="catalogueName"
                                 label="目标名称"
                                 align="center"
                                 width="300"></el-table-column>
                <el-table-column prop="descriptionContent"
                                 label="指标内容"
                                 align="center"
                                 width="300"></el-table-column>
                <el-table-column prop="targetSuggest"
                                 label="教学建议"
                                 width="120"
                                 align="center"
                                 fixed="right">
                  <template slot-scope="scope">
                    <el-button type="text"
                               v-if="scope.row.sortIndex != 0"
                               class="button"
                               @click="handleTaggetItem('suggestView', scope.row)">查看</el-button>
                  </template>
                </el-table-column>
                <el-table-column prop="targetPractice"
                                 label="教学实践"
                                 width="120"
                                 align="center"
                                 fixed="right">
                  <template slot-scope="scope">
                    <el-button type="text"
                               v-if="scope.row.sortIndex != 0"
                               class="button"
                               @click="handleTaggetItem('practiceView', scope.row)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="panel-data"
               v-if="courseContentList.length > 0">
            <div class="first-panel"
                 v-for="(item, index) in courseContentList"
                 :key="item.id">
              <div class="panel-header">
                <div class="title">
                  <div class="title-icon"></div>{{ item.contentName }}
                </div>
                <div class="button">
                  <img :src="firstPanelTurnIcon"
                       alt=""
                       @click="handleContentItem('turnItem', null, null, item, index)"
                       :class="{ 'turn-icon-class': !item.showFlag }" />
                </div>
              </div>
              <div class="panel-content"
                   v-show="item.showFlag"
                   v-if="item.eduCourseThemeCatalogueContentTrees.length > 0">
                <div class="second-panel"
                     v-for="(info, i) in item.eduCourseThemeCatalogueContentTrees"
                     :key="info.id">
                  <div class="panel-header">
                    <div class="title">{{ info.contentName }}</div>
                    <div class="button">
                      <img :src="secondPanelTurnIcon"
                           alt=""
                           @click="handleContentItem('turnItem', info, i, item, index)"
                           style="margin-left: 0.78125vw"
                           :class="{ 'turn-icon-class': !info.showFlag }" />
                    </div>
                  </div>
                  <div class="panel-content"
                       v-show="info.showFlag"
                       v-if="info.contentType == '0'">
                    <div class="content-info"
                         v-if="info.eduCourseThemeContent.length > 0"
                         :style="{ 'padding-bottom': info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0 ? '0' : '1.25vw' }">
                      <!-- <div class="ql-container ql-snow">
                        <div class="ql-editor" v-html="info.eduCourseThemeContent[0].content"></div>
                      </div> -->
                      <div class="w-e-text-container"
                           @click="handleImgClick">
                        <div class="w-e-text"
                             v-html="info.eduCourseThemeContent[0].content"></div>
                      </div>
                    </div>
                    <div class="file-info"
                         v-if="info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0">
                      <div class="file-item"
                           v-for="(fileInfo,fileIndex) in info.eduCourseThemeCatalogueContentFile"
                           :key="fileInfo.id"
                           :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.41667vw', 'width': '22.08333vw' }">
                        <div class="content">
                          <img class="icon"
                               :src="fileIconPDF"
                               alt=""
                               v-if="fileInfo.iconType == 'pdf'" />
                          <img class="icon"
                               :src="fileIconWord"
                               alt=""
                               v-if="fileInfo.iconType == 'doc'" />
                          <img class="icon"
                               :src="fileIconExcel"
                               alt=""
                               v-if="fileInfo.iconType == 'xls'" />
                          <img class="icon"
                               :src="fileIconPPT"
                               alt=""
                               v-if="fileInfo.iconType == 'ppt'" />
                          <div class="message">
                            <el-tooltip class="item"
                                        effect="light"
                                        popper-class="customPopper"
                                        :content="fileInfo.originalName"
                                        placement="top">
                              <div class="title">{{ fileInfo.originalName }}</div>
                            </el-tooltip>
                            <!-- <div class="ltitle">{{ fileInfo.remark }}</div> -->
                          </div>
                        </div>
                        <div class="bottom">
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      content="附件下载"
                                      placement="bottom"
                                      v-if="fileInfo.isDownload == '1'">
                            <img :src="fileDownloadIcon"
                                 alt=""
                                 @click="fileView(fileInfo,'download')"
                                 style="margin-right: 0.41667vw"
                                 v-if="fileInfo.isDownload == '1'" />
                          </el-tooltip>
                          <el-divider direction="vertical"
                                      v-if="fileInfo.isDownload == '1'"></el-divider>
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      content="附件预览"
                                      placement="bottom">
                            <img :src="fileViewIcon"
                                 alt=""
                                 @click="fileView(fileInfo,'view')"
                                 style="margin: 0 0.26042vw 0 0.41667vw" />
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="panel-content"
                       v-show="info.showFlag"
                       v-if="info.contentType == '1'">
                    <el-row style="padding: 1.25vw 0.9375vw;">
                      <!--type="flex"-->
                      <!-- <el-col :span="videoSpan" v-for="(video, k) in info.eduCourseThemeCatalogueContentVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '3.5vw' : '0', 'margin-bottom': '1.04167vw', }"> -->
                      <!--'margin-right': (index + 1) % 4 != 0 ? '5.4%' : '0',-->
                      <el-card shadow="hover"
                               class="video-card"
                               v-for="(video, k) in info.eduCourseThemeCatalogueContentVideo"
                               :key="video.id"
                               :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '0.67708vw' : '0', 'margin-bottom': '0.67708vw', 'width': '22.08333vw', display: 'inline-block' }"
                               :body-style="{ padding: 'undefined' }"
                               @click.native="videoView(video)">
                        <div class="video-body"
                             :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                          <img :src="videoPlayIcon"
                               alt="" />
                        </div>
                        <div class="info-body video-item">
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      :content="video.videoName"
                                      placement="top">
                            <div class="title">{{ video.videoName }}</div>
                          </el-tooltip>
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      :content="video.intro"
                                      placement="top"
                                      v-if="video.intro">
                            <div class="ltitle"
                                 v-if="video.intro">{{ video.intro }}</div>
                          </el-tooltip>
                          <div class="bottom">
                            <div class="video-time">{{ video.createTime }}</div>
                            <div class="video-button"></div>
                          </div>
                        </div>
                      </el-card>
                      <!-- </el-col> -->
                    </el-row>
                  </div>
                  <div class="panel-content"
                       v-show="info.showFlag"
                       v-if="info.contentType == '2'">
                    <!-- <el-radio-group v-model="info.activityTypeRadio" @change="(val) => handleRadioChange(val, info, i, item, index)">
                      <el-radio-button :label="radioInfo.value" v-for="radioInfo in activityTypeOptions" :key="radioInfo.id">{{ radioInfo.name }}</el-radio-button>
                    </el-radio-group> -->
                    <div class="activity-type-check"
                         v-show="info.activityListShow">
                      <div class="activity-type-item"
                           :class="{ active: radioInfo.value == info.activityTypeRadio }"
                           v-for="radioInfo in info.activityTypeList"
                           :key="radioInfo.id"
                           @click="handleRadioChange(radioInfo.value, info, i, item, index)">{{ radioInfo.name }}</div>
                    </div>
                    <div class="third-panel"
                         v-for="activityInfo in info.activityList"
                         :key="activityInfo.id">
                      <div class="panel-header">
                        <div class="title">
                          <img :src="activityTitleIcon"
                               alt="" /><span>{{ activityInfo.activityName }}</span>
                        </div>
                        <div class="button"></div>
                      </div>
                      <div class="panel-content">
                        <div class="content-info"
                             v-if="activityInfo.activityContent">
                          <!-- <div class="ql-container ql-snow">
                            <div class="ql-editor" v-html="activityInfo.activityContent"></div>
                          </div> -->
                          <div class="w-e-text-container"
                               @click="handleImgClick">
                            <div class="w-e-text"
                                 v-html="activityInfo.activityContent"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-content"
                   v-show="item.showFlag"
                   v-if="item.eduCourseThemeCatalogueContentTrees.length == 0">
                <div class="content-panel"
                     v-if="item.contentType == '0'">
                  <div class="content-info"
                       v-if="item.eduCourseThemeContent.length > 0"
                       :style="{ 'padding-bottom': item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0 ? '0' : '1.25vw' }">
                    <!-- <div class="ql-container ql-snow">
                      <div class="ql-editor" v-html="item.eduCourseThemeContent[0].content"></div>
                    </div> -->
                    <div class="w-e-text-container"
                         @click="handleImgClick">
                      <div class="w-e-text"
                           v-html="item.eduCourseThemeContent[0].content"></div>
                    </div>
                  </div>
                  <div class="file-info"
                       v-if="item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0">
                    <div class="file-item"
                         v-for="(fileInfo,fileIndex) in item.eduCourseThemeCatalogueContentFile"
                         :key="fileInfo.id"
                         :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.41667vw', 'width': '22.08333vw' }">
                      <div class="content">
                        <img class="icon"
                             :src="fileIconPDF"
                             alt=""
                             v-if="fileInfo.iconType == 'pdf'" />
                        <img class="icon"
                             :src="fileIconWord"
                             alt=""
                             v-if="fileInfo.iconType == 'doc'" />
                        <img class="icon"
                             :src="fileIconExcel"
                             alt=""
                             v-if="fileInfo.iconType == 'xls'" />
                        <img class="icon"
                             :src="fileIconPPT"
                             alt=""
                             v-if="fileInfo.iconType == 'ppt'" />
                        <div class="message">
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      :content="fileInfo.originalName"
                                      placement="top">
                            <div class="title">{{ fileInfo.originalName }}</div>
                          </el-tooltip>
                          <!-- <div class="ltitle">{{ fileInfo.remark }}</div> -->
                        </div>
                      </div>
                      <div class="bottom">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="附件下载"
                                    placement="bottom"
                                    v-if="fileInfo.isDownload == '1'">
                          <img :src="fileDownloadIcon"
                               alt=""
                               @click="fileView(fileInfo,'download')"
                               style="margin-right: 0.41667vw"
                               v-if="fileInfo.isDownload == '1'" />
                        </el-tooltip>
                        <el-divider direction="vertical"
                                    v-if="fileInfo.isDownload == '1'"></el-divider>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="附件预览"
                                    placement="bottom">
                          <img :src="fileViewIcon"
                               alt=""
                               @click="fileView(fileInfo,'view')"
                               style="margin: 0 0.26042vw 0 0.41667vw" />
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-panel"
                     v-if="item.contentType == '1'">
                  <el-row style="padding: 1.25vw 0.9375vw;">
                    <!--type="flex"-->
                    <!-- <el-col :span="videoSpan" v-for="(video, k) in item.eduCourseThemeCatalogueContentVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '3.5vw' : '0', 'margin-bottom': '1.04167vw', }">'margin-right': (index + 1) % 4 != 0 ? '5.4%' : '0', -->
                    <el-card shadow="hover"
                             class="video-card"
                             v-for="(video, k) in item.eduCourseThemeCatalogueContentVideo"
                             :key="video.id"
                             :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '0.67708vw' : '0', 'margin-bottom': '0.67708vw', 'width': '22.08333vw', display: 'inline-block' }"
                             :body-style="{ padding: 'undefined' }"
                             @click.native="videoView(video)">
                      <div class="video-body"
                           :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + '', 'background-size': '100% 100%', }">
                        <img :src="videoPlayIcon"
                             alt="" />
                      </div>
                      <div class="info-body video-item">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    :content="video.videoName"
                                    placement="top">
                          <div class="title">{{ video.videoName }}</div>
                        </el-tooltip>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    :content="video.intro"
                                    placement="top"
                                    v-if="video.intro">
                          <div class="ltitle"
                               v-if="video.intro">{{ video.intro }}</div>
                        </el-tooltip>
                        <div class="bottom">
                          <div class="video-time">{{ video.createTime }}</div>
                          <div class="video-button">
                          </div>
                        </div>
                      </div>
                    </el-card>
                    <!-- </el-col> -->
                  </el-row>
                </div>
                <div class="content-panel"
                     v-if="item.contentType == '2'">
                  <!-- <el-radio-group v-model="item.activityTypeRadio" @change="(val) => handleRadioChange(val, null, null, item, index)">
                    <el-radio-button :label="radioInfo.value" v-for="radioInfo in activityTypeOptions" :key="radioInfo.id">{{ radioInfo.name }}</el-radio-button>
                  </el-radio-group> -->
                  <div class="activity-type-check"
                       v-show="item.activityListShow">
                    <div class="activity-type-item"
                         :class="{ active: radioInfo.value == item.activityTypeRadio }"
                         v-for="radioInfo in item.activityTypeList"
                         :key="radioInfo.id"
                         @click="handleRadioChange(radioInfo.value, null, null, item, index)">{{ radioInfo.name }}</div>
                  </div>
                  <div class="third-panel"
                       v-for="activityInfo in item.activityList"
                       :key="activityInfo.id">
                    <div class="panel-header">
                      <div class="title">
                        <img :src="activityTitleIcon"
                             alt="" /><span>{{ activityInfo.activityName }}</span>
                      </div>
                      <div class="button"></div>
                    </div>
                    <div class="panel-content">
                      <div class="content-info"
                           v-if="activityInfo.activityContent">
                        <!-- <div class="ql-container ql-snow">
                          <div class="ql-editor" v-html="activityInfo.activityContent"></div>
                        </div> -->
                        <div class="w-e-text-container"
                             @click="handleImgClick">
                          <div class="w-e-text"
                               v-html="activityInfo.activityContent"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-content-panel"
               v-if="courseContentList.length == 0">
            <img class="empty-img"
                 :src="emptyImg"
                 alt="" />
            <div class="empty-title">暂无内容</div>
            <!-- <div class="empty-ltitle">需要先进行内容设置后才能进行内容操作</div> -->
          </div>
        </div>
      </div>
      <div class="content-body"
           :style="{'min-height': '0', height: isAddShow ? ( courseList.length > 0 ? (searchCourseList.length > 0 ? 'calc(100% - 25.78125vw)' : 'calc(100% - 24.375vw)') : (searchCourseList.length > 0 ? 'calc(100% - 20.15625vw)' : 'calc(100% - 18.75vw)')) : 'calc(100% - 12.55208vw)'}"
           v-if="!isShow">
        <div class="empty-content-panel"
             :style="{'height': '100%'}">
          <img class="empty-img"
               :src="emptyImg"
               alt=""
               :style="{'margin-bottom': isAddShow ? ( courseList.length > 0 ? (searchCourseList.length > 0 ? '0.3125vw' : '0') : (searchCourseList.length > 0 ? '1.45833vw' : '1.45833vw')) : '1.97917vw', 'width': isAddShow ? '7.8125vw' : '10.41667vw', 'height': isAddShow ? '6.97917vw' : '9.27083vw'}" />
          <div class="empty-title"
               style="margin-bottom: 0">暂无课程内容</div>
          <!-- <div class="empty-ltitle">需要先进行内容设置后才能进行内容操作</div> -->
        </div>
      </div>
      <el-image style="width: 0; height: 0;"
                ref="viewImg"
                :src="viewUrl"
                :preview-src-list="viewUrlList"></el-image>
      <suggests-view-dialog :suggestsViewDialogVisible.sync="suggestsViewDialogVisible"
                            :courseContentCatalogTargetId="courseContentCatalogTargetId"
                            @closeDialog="suggestsDialogClose"></suggests-view-dialog>
      <practices-view-dialog :practicesViewDialogVisible.sync="practicesViewDialogVisible"
                             :courseContentCatalogTargetId="courseContentCatalogTargetId"
                             :courseContentCatalogTargetInfoId="courseContentCatalogTargetInfoId"
                             :courseContentCatalogTargetFlag="courseContentCatalogTargetFlag"
                             :courseContentCatalogTargetTypeValue="courseContentCatalogTargetTypeValue"
                             @closeDialog="practicesDialogClose"></practices-view-dialog>
      <video-view-dialog ref="videoView"
                         :videoViewDialogVisible.sync="videoViewDialogVisible"
                         :type="videoViewType"
                         :videoUrl="videoViewUrl"
                         @closeDialog="videoViewClose"></video-view-dialog>
      <content-component ref="contentComponent"
                         :contentDialogVisible.sync="contentDialogVisible"
                         :formData="{}"></content-component>
      <el-dialog custom-class="course-intro-dialog"
                 :visible.sync="courseIntroVisible"
                 :show-close="false"
                 :append-to-body="true"
                 :destroy-on-close="true"
                 @before-close="handleCourseIntroClose"
                 @close="handleCourseIntroClose"
                 fullscreen>
        <div slot="title"
             class="course-intro-dialog-title">
          <span>课程介绍</span>
          <div class="course-intro-dialog-close-button"
               @click="handleCourseIntroClose">返回</div>
        </div>
        <div class="course-intro-dialog-body">
          <course-intro :courseIntroId="courseIntroId"
                        v-if="courseIntroVisible"></course-intro>
        </div>
      </el-dialog>
      <el-dialog custom-class="course-intro-dialog"
                 :visible.sync="sendCourseVisible"
                 :show-close="false"
                 :append-to-body="true"
                 :destroy-on-close="true"
                 @before-close="handleSendCourseClose"
                 @close="handleSendCourseClose"
                 fullscreen>
        <div slot="title"
             class="course-intro-dialog-title">
          <span>编辑推送信息</span>
          <div class="course-intro-dialog-close-button"
               @click="handleSendCourseClose">返回</div>
        </div>
        <div class="course-intro-dialog-body">
          <send-edit :sendCourseData="sendCourseData"
                     v-if="sendCourseVisible"
                     @refreshContent="refreshContent"></send-edit>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import SuggestsViewDialog from './components/suggestsDialog.vue';
import PracticesViewDialog from './components/practicesDialog.vue';
import VideoViewDialog from '@/components/videoView/index.vue';
import contentComponent from '@/components/contentComponent/index.vue';
import CourseIntro from './courseIntro.vue';
import SendEdit from './sendEdit.vue';
import coursePanelNewLeftIcon from '@/assets/img/course-panel-new-left.png';
import coursePanelNewRightIcon from '@/assets/img/course-panel-new-right.png';
import courseItemAddBgImg from '@/assets/img/add-course-item-bg.png';
import courseItemNewBgImg from '@/assets/img/course-item-new-bg.png';
import courseItemNewActiveBgImg from '@/assets/img/course-item-new-active-bg.png';
import courseItemDelIcon from '@/assets/img/course-item-del-icon.png';
import detailTeacherIcon from '@/assets/img/detail-teacher-icon.png';
import detailTimeIcon from '@/assets/img/detail-time-icon.png';
import detailPushIcon from '@/assets/img/detail-push-icon-text.png';
import detailEditIcon from '@/assets/img/detail-edit-icon.png';
import detailDelIcon from '@/assets/img/detail-del-icon.png';
import emptyImg from '@/assets/img/empty-img.png';
import firstPanelTurnIcon from '@/assets/img/first-panel-turn-icon.png';
import editIcon from '@/assets/img/edit-icon.png';
import uploadFileIcon from '@/assets/img/upload-file-icon.png';
import panelDelIcon from '@/assets/img/panel-del-icon.png';
import fileViewIcon from '@/assets/img/file-view-icon.png';
import fileDownloadIcon from '@/assets/img/file-download-icon.png';
import delIcon from '@/assets/img/del-icon.png';
import videoAddIcon from '@/assets/img/video-add-icon.png';
import secondPanelTurnIcon from '@/assets/img/second-panel-turn-icon.png';
import catalogSettingIcon from '@/assets/img/catalog-setting-icon.png';
import activityTitleIcon from '@/assets/img/activity-title-icon.png';
import fileIconPDF from '@/assets/img/file-icon-PDF.png';
import fileIconWord from '@/assets/img/file-icon-word.png';
import fileIconExcel from '@/assets/img/file-icon-excel.png';
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
import videoPlayIcon from '@/assets/img/play-icon.png';
import defaultImg from '@/assets/img/default-img.png';
import courseItemSelectionMore from '@/assets/img/course-item-selection-more.png'
import courseCancelLikeIcon from '@/assets/img/course-cancel-like-icon.png'
import courseLikeIcon from '@/assets/img/course-like-icon.png'
import searchCourseEmptyImg from '@/assets/img/search-course-empty-img.png'
import { getToken } from '@/utils/auth';
import { getPermissionButton } from '@/utils/getUrlPermissionButtons';
import { getFileViewUrl, exportFile } from '@/utils/fileUtils.js';
import { getAgeScopeName, getCourseLevelName } from "@/utils/getDicName";
export default {
  name: 'courseContentSetting',
  components: {
    SuggestsViewDialog,
    PracticesViewDialog,
    VideoViewDialog,
    contentComponent,
    CourseIntro,
    SendEdit
  },
  data () {
    return {
      courseList: [], // 课程列表
      courseContentTargetInfo: [], // 目标清单list
      tableLoading: false, // 目标清单tableloading

      coursePanelNewLeftIcon, // 课程板块的左icon
      coursePanelNewRightIcon, // 课程板块的右icon
      courseItemAddBgImg, // 课程添加背景图
      courseItemNewBgImg, // 课程背景图
      courseItemNewActiveBgImg, // 课程选中背景图
      courseItemDelIcon, // 课程删除icon
      detailTeacherIcon, // 教师信息icon
      detailTimeIcon, // 创建时间icon
      detailPushIcon, // 发布icon
      detailEditIcon, // 详情编辑icon
      detailDelIcon, // 详情删除icon
      emptyImg, // 空数据图片
      firstPanelTurnIcon, // 一级折叠icon
      editIcon, // 编辑icon
      uploadFileIcon, // 附件上传icon
      panelDelIcon, // 层删除icon
      fileViewIcon, // 文件预览icon
      fileDownloadIcon, //文件下载icon
      delIcon, // 删除icon
      videoAddIcon, // 视频添加icon
      secondPanelTurnIcon, // 二级折叠icon
      catalogSettingIcon, // 课程内容的目录icon
      activityTitleIcon, // 活动头部icon
      fileIconPDF, // pdfIcon
      fileIconWord, // wordIcon
      fileIconExcel, // excelIcon
      fileIconPPT,//PPTIcon
      videoPlayIcon, // 视频icon
      defaultImg, // 默认图片
      courseItemSelectionMore, //展开条件icon
      courseCancelLikeIcon, //取消收藏icon
      courseLikeIcon, //收藏icon
      searchCourseEmptyImg, //搜索课程空图片

      courseId: null, // 课程id
      courseContentId: null, // 编辑课程内容id
      courseContentList: [], // 课程内容详情
      handleType: '', // 操作类型
      contentTitle: '', // 选中目录的标题
      isParentShow: false, // 是否展示推送按钮
      isOpen: '0', // 是否家长展示

      courseContentCatalogId: null, // 课程内容的目录id
      secondInfo: {}, // 二级对象
      secondIndex: null, // 二级对象下标
      firstInfo: {}, // 一级对象
      firstIndex: null, // 一级对象下标

      buttonloading: false, // 弹框按钮loading
      pageLoading: null, // 页面loading

      bodyLeftHeight: '100%',
      bodyRightHeight: '100%',
      suggestsViewDialogVisible: false, // 目标建议查看显隐标识
      practicesViewDialogVisible: false, // 目标实践查看显隐标识
      courseContentCatalogTargetId: null, // 目标id
      courseContentCatalogTargetInfoId: null, //目标内容id
      courseContentCatalogTargetFlag: '', // 目标维度
      courseContentCatalogTargetTypeValue: '', // 目标维度字典值

      videoViewDialogVisible: false, // 视频预览标识
      videoViewType: '', // 视频类型
      videoViewUrl: '', // 视频预览url

      videoSpan: 6, // video宽度
      videoNum: 4, // video个数
      fileNum: 3, // 附件数

      isShow: false, // 是否显示内容
      isAddShow: false, // 是否显示添加内容
      isHaveCourse: false, //是否有收藏课程
      courseListWidth: '100%', // 课程宽度
      courseMoveNum: 0, // 课程移动次数
      currentMoveNum: 1, // 当前移动页数
      moveWidth: 0, // 移动长度

      courseTypeListHeight: 'auto', // 课程分类高度
      isCourseTypeTurnShow: false, // 课程分类是否显示展开收起
      courseTypeTurnFlag: false, // 课程分类展开收起标识

      courseFieldListHeight: 'auto', // 课程领域高度
      isCourseFieldTurnShow: false, // 课程领域是否显示展开收起
      courseFieldTurnFlag: false, // 课程领域展开收起标识

      courseThemeListHeight: 'auto', // 课程专题高度
      isCourseThemeTurnShow: false, // 课程专题是否显示展开收起
      courseThemeTurnFlag: false, // 课程专题展开收起标识

      courseVersionListHeight: 'auto', // 课程版本高度
      isCourseVersionTurnShow: false, // 课程版本是否显示展开收起
      courseVersionTurnFlag: false, // 课程版本展开收起标识

      scopeListHeight: 'auto', // 课程阶段高度
      isScopeTurnShow: false, // 课程阶段是否显示展开收起
      scopeTurnFlag: false, // 课程阶段展开收起标识

      ageScopeListHeight: 'auto', // 课程年龄阶段高度
      isAgeScopeTurnShow: false, // 课程年龄阶段是否显示展开收起
      ageScopeTurnFlag: false, // 课程年龄阶段展开收起标识

      courseLevelListHeight: 'auto', // 课程等级高度
      isCourseLevelTurnShow: false, // 课程等级是否显示展开收起
      courseLevelTurnFlag: false, // 课程等级展开收起标识

      searchCourseListHeight: 'auto', // 课程高度
      isSearchCourseTurnShow: false, // 课程是否显示展开收起
      searchCourseTurnFlag: false, // 课程展开收起标识

      scopeOptions: [], //阶段综合list
      versionOptions: [], //版本list
      searchThemeOptions: [], //搜索的系列list

      addCourseForm: {
        courseType: '',
        courseField: '',
        courseTheme: '',
        courseVersion: '',
        ageScope: '',
        courseLevel: '',
        courseId: '',
      }, // 添加课程form
      searchCourseList: [], // 课程搜索结果
      searchCourseTipList: [], //课程搜索显示图标list
      searchCourseWidth: '100%',

      showCourseBaseInfo: {
        coverImg: "",
        courseName: "",
        description: "",

      }, //展示的课程信息

      courseThemeList: [], // 课程主题列表

      activeIndex: '', // 目录下拉

      fileTypeList: ['png', 'jpg', 'jpeg'], // 文件类型list

      isPush: false, // 推送权限
      isAdd: false, // 添加权限
      permissionButtonList: [], // 权限列表

      contentDialogVisible: false,

      viewUrl: '', // 附件图片预览
      viewUrlList: [], // 附件图片预览list

      showMenuFlag: false,
      startDot: 0, //开始点
      searchMoveWidth: 0, //课程移动距离
      singleCourseMove: 0, //移动单个距离

      courseIntroVisible: false, //介绍弹窗标识
      courseIntroId: "", //介绍课程id

      chooseCourseIndex: null, //展开条件储存课程index
      mainFlowFlag: 'auto',//最外层滚动效果
      showFlowFlag: 'hidden',//内容部分的展示
      fixedFlag: false,
      showWidth: 0,

      sendCourseVisible: false, //编辑推送信息弹窗标识
      sendCourseData: null, //推送编辑页所需参数

      pushText: '',//是否推送text
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      activityTypeOptions: (state) => state.common.activityTypeList,
      themeOptions: (state) => state.common.courseThemeList,
      // versionOptions: (state) => state.common.courseVersionList,
      menuList: (state) => state.common.menuList,
    })

  },
  watch: {
    permissionButtonList: {
      handler (val) {
        if (val) {
          this.isPush = val.indexOf('course:add') != -1;
          this.isAdd = val.indexOf('course:push') != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler (val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created () {
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: "loadingStyle"
    });
    let that = this;
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    window.onresize = function () {
      that.resizeVideoInfo();
      that.resizeHeight();
      that.resizeChoose();
      if (that.isAddShow && that.searchCourseList.length > 0) {
        that.singleCourseMove = that.$refs.searchCourseShow.scrollWidth / that.searchCourseList.length
      }
    };
    that.resizeVideoInfo();
  },
  mounted () {
    setTimeout(() => {
      this.getCourseList();
    }, 300)
  },
  methods: {
    getToken,
    getPermissionButton,
    getFileViewUrl,
    getAgeScopeName,
    getCourseLevelName,
    exportFile,
    mainScroll (e) {
      if (this.mainFlowFlag == 'auto') {
        console.log(this.$refs.courseShow.offsetTop)
        if (this.$refs.courseShow && e.target.scrollTop >= this.$refs.courseShow.offsetTop && !this.isAddShow || (this.$refs.searchCourseShow && e.target.scrollTop >= this.$refs.searchCourseShow.offsetTop && this.isAddShow)) {
          this.fixedFlag = true
          this.showWidth = this.$refs.courseResoureMain.clientWidth
        } else {
          this.fixedFlag = false
        }
      }
    },
    //返回顶部
    handleBackTop () {
      this.mainFlowFlag = 'auto';
      this.showFlowFlag = 'hidden'
      this.$nextTick(() => {
        this.$refs.courseResoureMain.scrollTop = 0
      })
    },
    // 展示添加div
    showAdd () {
      if (this.isAddShow) {
        this.hideAdd()
      } else {
        let index = this.courseList.findIndex((info) => { return info.isChoose == true })
        // this.addCourseForm.courseType = this.typeOptions[0].value
        // this.addCourseForm.courseField = this.areaOptions[0].value
        // this.addCourseForm.courseTheme = this.themeOptions[0].value
        this.$nextTick(() => {
          this.getThemeList(() => {
            if (this.searchThemeOptions.length > 0) {
              this.addCourseForm.courseTheme = this.searchThemeOptions[0].value
            }
            this.getVersionList(() => {
              if (this.versionOptions.length > 0) {
                this.addCourseForm.courseVersion = this.versionOptions[0].value
              }
              this.getScopeList(() => {
                if (this.scopeOptions.length > 0) {
                  if (this.scopeOptions[0].type == 'class_level') {
                    this.addCourseForm.courseLevel = this.scopeOptions[0].value
                  } else if (this.scopeOptions[0].type == 'age_scope') {
                    this.addCourseForm.ageScope = this.scopeOptions[0].value
                  }
                }
                this.isAddShow = true;
                this.chooseCourseIndex = index
                this.courseId = null;
                this.courseList.map((item) => {
                  item.isChoose = false
                })
                this.isShow = false
                this.getCourseSearchList();
                this.$nextTick(() => {
                  this.resizeChoose();
                })
              })
            })
          })
        })
      }
    },
    // 隐藏添加div
    hideAdd () {
      this.isAddShow = false;
      this.$refs.courseResoureMain.scrollTop = 0
      this.fixedFlag = false
      this.$nextTick(() => {
        this.addCourseForm = {
          courseType: '',
          courseField: '',
          courseTheme: '',
          courseVersion: '',
          ageScope: '',
          courseLevel: '',
          courseId: '',
        };
        this.searchCourseList = [];
        if (this.chooseCourseIndex !== null) {
          let info = JSON.parse(JSON.stringify(this.courseList[this.chooseCourseIndex]))
          info.isChoose = true
          this.$set(this.courseList, this.chooseCourseIndex, info)
          this.$nextTick(() => {
            this.isShow = false
            this.showCourseInfo(info, 'choose')
          })
        }
        // this.searchCourseListHeight = 'auto'; // 课程高度
        // this.isSearchCourseTurnShow = false; // 课程是否显示展开收起
        // this.searchCourseTurnFlag = false; // 课程展开收起标识
      });
    },

    // 获取课程资源列表
    getCourseList () {
      this.$api.getCourseResourceList({}).then((res) => {
        if (res.data.code === 0) {
          let list = res.data.data
          list.map((item, i) => {
            if (this.isAddShow) {
              item.isChoose = false;
            } else {
              if (i === 0) {
                item.isChoose = true;
                this.courseId = item.id;
              } else {
                item.isChoose = false;
              }
            }

            let courseSuitableList = item.courseSuitable.split(",")
            let courseSuitableName = []
            courseSuitableList.map((info) => {
              let name = this.getAgeScopeName(info)
              if (name) {
                courseSuitableName.push(name)
              }
            })

            let courseLevelList = item.courseLevel.split(",")
            let courseLevelName = []
            courseLevelList.map((info) => {
              let name = this.getCourseLevelName(info)
              if (name) {
                courseLevelName.push(name)
              }
            })

            item.courseSuitableName = courseSuitableName.join(",")
            item.courseLevelName = courseLevelName.join(",")
          });
          this.courseListWidth = 15 * list.length + ((list.length - 1) * 0.625) + 'vw';
          if (list.length % 5 == 0) {
            this.courseMoveNum = parseInt(list.length / 5);
          } else {
            this.courseMoveNum = parseInt(list.length / 5) + 1;
          }
          this.courseList = list;
          if (list.length > 0) {
            this.isHaveCourse = true
            if (!this.isAddShow) {
              this.showCourseInfo(list[0]);
            }
          } else {
            this.pageLoading.close();
            this.isShow = false;
            this.mainFlowFlag = 'hidden'
            this.isHaveCourse = false
            // this.addCourseForm.courseType = this.typeOptions[0].value
            // this.addCourseForm.courseField = this.areaOptions[0].value
            this.addCourseForm.courseTheme = this.themeOptions[0].value
            this.$nextTick(() => {
              this.getThemeList(() => {
                if (this.searchThemeOptions.length > 0) {
                  this.addCourseForm.courseTheme = this.searchThemeOptions[0].value
                }
                this.getVersionList(() => {
                  if (this.versionOptions.length > 0) {
                    this.addCourseForm.courseVersion = this.versionOptions[0].value
                  }
                  this.getScopeList(() => {
                    if (this.scopeOptions.length > 0) {
                      if (this.scopeOptions[0].type == 'class_level') {
                        this.addCourseForm.courseLevel = this.scopeOptions[0].value
                      } else if (this.scopeOptions[0].type == 'age_scope') {
                        this.addCourseForm.ageScope = this.scopeOptions[0].value
                      }
                    } else {
                      this.addCourseForm.courseLevel = ''
                      this.addCourseForm.ageScope = ''
                    }
                    this.isAddShow = true
                    this.getCourseSearchList();
                    this.$nextTick(() => {
                      this.resizeChoose();
                    })
                  })
                })
              })

            })
          }
        } else {
          this.$message.error(res.data.msg);
          this.pageLoading.close()
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    // 删除课程资源
    handleCourseItemDel (row, index) {
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '是否将《' + row.courseName + '》从我的课程中移除？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px', }, '移除后，可在【课程资源】中重新添加'), /// 移除后，可点击【添加课程】中重新添加
        ]), '提示', { confirmButtonText: '确定', cancelButtonText: '取消', iconClass: 'el-icon-question', type: 'warning', customClass: 'warningMessage', }
      ).then(() => {
        this.$api.delCourseThemeContact({ courseId: row.id, delFlag: '1' }).then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: '取消收藏课程资源成功', type: 'success', duration: 1500, onClose: () => {
                this.pageLoading = this.$loading({
                  lock: true,
                  fullscreen: true,
                  text: '加载中',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)',
                  customClass: "loadingStyle"
                });
                this.courseListWidth = '100%', // 课程宽度
                  this.courseMoveNum = 0, // 课程移动次数
                  this.currentMoveNum = 1, // 当前移动页数
                  this.moveWidth = 0, // 移动长度
                  this.getCourseList();
                if (this.isAddShow) {
                  setTimeout(() => {
                    this.getCourseSearchList('noMove')
                  }, 300)
                  // this.getCourseSearchList('noMove')
                }
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => { });
    },
    // 添加课程资源
    handleAddCourseContact () {
      if (this.addCourseForm.courseId === '') {
        this.$message.warning('请选择要收藏的课程');
        return;
      }
      this.$api.addCourseThemeContact({ courseId: this.addCourseForm.courseId, isDefault: '0' }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '收藏课程资源成功', type: 'success', duration: 1500, onClose: () => {
              // this.hideAdd();
              this.pageLoading = this.$loading({
                lock: true,
                fullscreen: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: "loadingStyle"
              });
              this.courseListWidth = '100%', // 课程宽度
                this.courseMoveNum = 0, // 课程移动次数
                this.currentMoveNum = 1, // 当前移动页数
                this.moveWidth = 0, // 移动长度
                this.getCourseList();
              setTimeout(() => {
                this.getCourseSearchList('noMove')
              }, 300)
            }
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取课程详情(主题+主题目录+目录内容)
    showCourseInfo (row, type) {
      this.courseId = row.id;
      this.courseContentTargetInfo = []
      this.courseContentList = []
      this.courseThemeList = []
      if (!this.isAddShow) {
        this.showCourseBaseInfo = JSON.parse(JSON.stringify(row))
        this.courseList.map((info) => {
          if (info.id == row.id) {
            info.isChoose = true;
          } else {
            info.isChoose = false;
          }
        });
      }
      if (type == 'choose') {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass: "loadingStyle"
        });
      }
      this.getCourseThemeList(() => {
        if (this.courseThemeList.length === 0) {
          this.isShow = false;
          this.showMenuFlag = false;
          this.mainFlowFlag = 'hidden'
          this.pageLoading.close()
        } else {
          this.isShow = true;
          this.showMenuFlag = true;
          this.mainFlowFlag = 'auto'
          this.getCatalogInfo((list) => {
            console.log(list)
            let resultCatalogList = []
            resultCatalogList = list.map((item, index) => {
              if (item.catalogData && index == 0) {
                item.catalogData.map((dataInfo) => {
                  if (dataInfo.children && !dataInfo.isLeaf) {
                    dataInfo.isShowTree = true
                    dataInfo.children.map((secondInfo) => {
                      if (secondInfo.children && !secondInfo.isLeaf) {
                        secondInfo.isShowTree = true
                      }
                    })
                  }
                })
              }
              return item
            })
            this.courseThemeList = resultCatalogList
            this.activeIndex = this.courseThemeList[0].id + '_1';
            this.$nextTick(() => {
              this.getHaveContentCatalog(list[0].catalogData, (data) => {
                let isHaveContentCatalogData = data
                this.$nextTick(() => {
                  this.refreshCatalog(isHaveContentCatalogData)
                  this.courseContentCatalogId = isHaveContentCatalogData ? isHaveContentCatalogData.id : null;
                  this.contentTitle = isHaveContentCatalogData ? isHaveContentCatalogData.catalogueName : '';
                  this.isOpen = isHaveContentCatalogData ? isHaveContentCatalogData.isOpen : '0';
                  this.isParentShow = isHaveContentCatalogData ? isHaveContentCatalogData.isOpen === '1' : false;
                  if (isHaveContentCatalogData) {
                    this.pushText = ''
                    this.getIsPush()
                    this.getCourseContentCatalogContentInfo();
                    this.getCourseContentCatalogTargetList();
                  } else {
                    this.pageLoading.close();
                  }
                })
              })
            })
          })
        }
      });
    },
    //更新目录展开效果
    refreshCatalog (data) {
      if (data && data.parentIndexList.length > 0) {
        if (data.parentIndexList.length == 1) {
          let info = JSON.parse(JSON.stringify(this.courseThemeList[0].catalogData[data.parentIndexList[0]]))
          info.isShowTree = true
          this.$set(this.courseThemeList[0].catalogData, data.parentIndexList[0], info)
        }
        if (data.parentIndexList.length == 2) {
          let info = JSON.parse(JSON.stringify(this.courseThemeList[0].catalogData[data.parentIndexList[0]]))
          info.isShowTree = true
          info.children[data.parentIndexList[1]].isShowTree = true
          this.$set(this.courseThemeList[0].catalogData, data.parentIndexList[0], info)
        }
      }
    },
    //获取筛选的阶段list
    getScopeList (callback) {
      let params = {
        courseClassify: this.addCourseForm.courseType,
        courseField: this.addCourseForm.courseField,
        courseTheme: this.addCourseForm.courseTheme,
        courseVersion: this.addCourseForm.courseVersion,
      }
      this.$api.getSearchResourseDictionary(params).then((res) => {
        if (res.data.code === 0) {
          let scopeOptions = []
          res.data.data.map((item) => {
            let info = JSON.parse(JSON.stringify(item))
            info.optionType = 'level'
            scopeOptions.push(info)
          })
          this.scopeOptions = scopeOptions
          callback && callback()
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    //获取筛选的版本list
    getVersionList (callback) {
      let params = {
        courseClassify: this.addCourseForm.courseType,
        courseField: this.addCourseForm.courseField,
        courseTheme: this.addCourseForm.courseTheme,
      }
      this.$api.getSearchResourseVersions(params).then((res) => {
        if (res.data.code === 0) {
          this.versionOptions = res.data.data
          callback && callback()
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    //获取筛选的系列list
    getThemeList (callback) {
      let params = {
        courseClassify: this.addCourseForm.courseType,
        courseField: this.addCourseForm.courseField,
      }
      this.$api.getSearchResourseTheme(params).then((res) => {
        if (res.data.code === 0) {
          this.searchThemeOptions = res.data.data
          callback && callback()
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    //获取目录内容信息
    async getCatalogInfo (callback) {
      let list = JSON.parse(JSON.stringify(this.courseThemeList))
      let i = 0
      for (let item of list) {
        await this.getCourseThemeCatalogList(item.id, i, (data, index) => {
          item.catalogData = data;
          if (index == list.length - 1) {
            callback && callback(list)
          } else {
            i++
          }
        });
      }
    },
    // 获取课程内容list
    getCourseThemeList (callback) {
      this.$api.getCourseThemeList({ id: this.courseId }).then((res) => {
        if (res.data.code === 0) {
          this.courseThemeList = res.data.data;
          this.$nextTick(() => {
            callback && callback();
          })
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取课程内容目录list
    async getCourseThemeCatalogList (id, index, callback) {
      await this.$api.getCourseThemeCatalogTreeList({ courseThemeId: id ? id : this.activeIndex }).then(res => {
        if (res.data.code === 0) {
          res.data.data.map((item, i) => {
            item.isShowTree = false;
            item.parentIndexList = []
            if (item.children && item.children.length > 0) {
              item.isLeaf = false
              item.children.map((info, j) => {
                info.isShowTree = false;
                info.parentIndexList = [i];
                if (info.children && info.children.length > 0) {
                  info.isLeaf = false
                  info.children.map((result, k) => {
                    result.parentIndexList = [i, j];
                    result.isLeaf = true
                  });
                } else {
                  info.isLeaf = true
                }
              });
            } else {
              item.isLeaf = true
            }
          });
          callback && callback(res.data.data, index);
        } else {
          this.pageLoading.close()
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    //获取有内容的目录信息
    async getHaveContentCatalog (list, callback) {
      let insideResult = null
      let returnFlag = false

      let result = async (resultList) => {
        if (returnFlag) {
          return
        }
        if (resultList && resultList.length > 0) {
          for (let info of resultList) {
            console.log(info)
            if (returnFlag) {
              break
            }
            let contentFlag = await this.isHaveCourseContentCatalogContent(info.id)
            console.log(contentFlag)
            if (contentFlag) {
              insideResult = info
              returnFlag = true
              break
            } else {
              if (info.children && info.children.length > 0) {
                await result(info.children)
              } else if (info.isLeaf) {
                insideResult = info
                returnFlag = true
                break
              }
            }
          }
          if (returnFlag) {
            return
          }
        }
      }

      await result(list);
      callback && callback(insideResult)
    },
    // 课程主题内容展开
    handleOpenMenu (key, keyPath) {
      console.log(key, keyPath);
      let list = JSON.parse(JSON.stringify(this.courseThemeList))
      let resultList = []
      resultList = list.map((item) => {
        console.log(item.id)
        if (item.id == key) {
          item.catalogData.map((dataInfo) => {
            if (dataInfo.children && !dataInfo.isLeaf) {
              dataInfo.isShowTree = true
              dataInfo.children.map((secondInfo) => {
                if (secondInfo.children && !secondInfo.isLeaf) {
                  secondInfo.isShowTree = true
                }
              })
            }
          })
          return item
        } else {
          return item
        }
      })
      console.log(resultList);
      this.courseThemeList = resultList
    },
    // 课程主题内容关闭
    handleCloseMenu (key, keyPath) {
      console.log(key, keyPath);
      let list = JSON.parse(JSON.stringify(this.courseThemeList))
      list = list.map((item) => {
        if (item.courseThemeId == key) {
          item.catalogData.map((dataInfo) => {
            if (dataInfo.children && !dataInfo.isLeaf) {
              dataInfo.isShowTree = false
              dataInfo.children.map((secondInfo) => {
                if (secondInfo.children && !secondInfo.isLeaf) {
                  secondInfo.isShowTree = false
                }
              })
            }
          })
        }
        return item
      })
      this.courseThemeList = list
    },
    //课程选择事件
    courseClick (item, i) {
      if (this.isAddShow) {
        this.chooseCourseIndex = i
        this.$nextTick(() => {
          this.hideAdd()
        })
      } else {
        this.isShow = false
        this.showCourseInfo(item, 'choose')
      }
    },
    // 移动事件
    moveCourse (type) {
      if (type == 'next') {
        if (this.currentMoveNum < this.courseMoveNum) {
          // this.courseList.map((item) => {
          //   item.isChoose = false;
          // });
          this.$nextTick(() => {
            this.moveWidth = -(15.625 * 5 * this.currentMoveNum) + 'vw';
            this.currentMoveNum++;
            // this.$nextTick(() => {
            //   if ((this.currentMoveNum - 1) * 5 < this.courseList.length) {
            //     this.courseList[(this.currentMoveNum - 1) * 5].isChoose = true;
            //     this.courseId = this.courseList[(this.currentMoveNum - 1) * 5].id;
            //     this.showCourseBaseInfo = JSON.parse(JSON.stringify(this.courseList[(this.currentMoveNum - 1) * 5]))
            //     this.pageLoading = this.$loading({
            //       lock: true,
            //       fullscreen: true,
            //       text: '加载中',
            //       spinner: 'el-icon-loading',
            //       background: 'rgba(0, 0, 0, 0.7)',
            //       customClass: "loadingStyle"
            //     });
            //     this.getCourseThemeList(() => {
            //       if (this.courseThemeList.length === 0) {
            //         this.isShow = false;
            //         this.pageLoading.close();
            //       } else {
            //         this.isShow = true;
            //         this.getCatalogInfo((list) => {
            //           this.courseThemeList = list
            //           this.$nextTick(async () => {
            //             this.activeIndex = this.courseThemeList[0].id + '_1';
            //             let isHaveContentCatalogData = await this.getHaveContentCatalog(this.courseThemeList[0].catalogData)
            //             this.refreshCatalog(isHaveContentCatalogData)
            //             this.courseContentCatalogId = isHaveContentCatalogData ? isHaveContentCatalogData.id : null;
            //             this.contentTitle = isHaveContentCatalogData ? isHaveContentCatalogData.catalogueName : '';
            //             this.isOpen = isHaveContentCatalogData ? isHaveContentCatalogData.isOpen : '0';
            //             this.isParentShow = isHaveContentCatalogData ? isHaveContentCatalogData.isOpen === '1' : false;
            //             if(this.courseContentCatalogId){
            //               this.getCourseContentCatalogContentInfo();
            //               this.getCourseContentCatalogTargetList();
            //             } else {
            //               this.pageLoading.close();
            //             }
            //           })
            //         })
            //       }
            //     });
            //   }
            // });
          });
        }
      } else {
        if (this.currentMoveNum > 1) {
          // this.courseList.map((item) => {
          //   item.isChoose = false;
          // });
          this.$nextTick(() => {
            this.moveWidth = -(15.625 * 5 * (this.currentMoveNum - 2)) + 'vw';
            this.currentMoveNum--;
            // this.$nextTick(() => {
            //   this.courseList[(this.currentMoveNum - 1) * 5].isChoose = true;
            //   this.courseId = this.courseList[(this.currentMoveNum - 1) * 5].id;
            //   this.showCourseBaseInfo = JSON.parse(JSON.stringify(this.courseList[(this.currentMoveNum - 1) * 5]))
            //   this.pageLoading = this.$loading({
            //     lock: true,
            //     fullscreen: true,
            //     text: '加载中',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.7)',
            //     customClass: "loadingStyle"
            //   });
            //   this.getCourseThemeList(() => {
            //     if (this.courseThemeList.length === 0) {
            //       this.isShow = false;
            //       this.pageLoading.close();
            //     } else {
            //       this.isShow = true;
            //       this.getCatalogInfo((list) => {
            //         this.courseThemeList = list
            //         this.$nextTick(async () => {
            //           this.activeIndex = this.courseThemeList[0].id + '_1';
            //           let isHaveContentCatalogData = await this.getHaveContentCatalog(this.courseThemeList[0].catalogData)
            //           this.refreshCatalog(isHaveContentCatalogData)
            //           this.courseContentCatalogId = isHaveContentCatalogData ? isHaveContentCatalogData.id : null;
            //           this.contentTitle = isHaveContentCatalogData ? isHaveContentCatalogData.catalogueName : '';
            //           this.isOpen = isHaveContentCatalogData ? isHaveContentCatalogData.isOpen : '0';
            //           this.isParentShow = isHaveContentCatalogData ? isHaveContentCatalogData.isOpen === '1' : false;
            //           if(this.courseContentCatalogId){
            //             this.getCourseContentCatalogContentInfo();
            //             this.getCourseContentCatalogTargetList();
            //           } else {
            //             this.pageLoading.close();
            //           }
            //         })
            //       })
            //     }
            //   });
            // });
          });
        }
      }
    },
    // 添加课程的条件筛选事件
    handleChooseClick (type, value, info) {
      if (type == 'courseType' || type == 'courseField') {
        this.addCourseForm[type] = value;
        this.addCourseForm.courseLevel = '';
        this.addCourseForm.ageScope = '';
        this.addCourseForm.courseVersion = ''
        this.addCourseForm.courseTheme = ''
        this.$nextTick(() => {
          this.getThemeList(() => {
            if (this.searchThemeOptions.length > 0) {
              this.addCourseForm.courseTheme = this.searchThemeOptions[0].value
            }
            this.getVersionList(() => {
              if (this.versionOptions.length > 0) {
                this.addCourseForm.courseVersion = this.versionOptions[0].value
              }
              this.getScopeList(() => {
                if (this.scopeOptions.length > 0) {
                  if (this.scopeOptions[0].type == 'class_level') {
                    this.addCourseForm.courseLevel = this.scopeOptions[0].value
                  } else if (this.scopeOptions[0].type == 'age_scope') {
                    this.addCourseForm.ageScope = this.scopeOptions[0].value
                  }
                }
                this.searchMoveWidth = 0;
                this.searchCourseWidth = '100%';
                this.searchCourseList = [];
                this.isShow = false

                this.$nextTick(() => {
                  // if (type != 'courseId') {
                  //   this.addCourseForm.courseId = '';
                  this.getCourseSearchList();
                  // }
                });
              })
            })
          })
        })
      } else if (type == 'scope') {
        if (info.type == 'age_scope') {
          this.addCourseForm.courseLevel = '';
          this.addCourseForm.ageScope = value;
        } else if (info.type == 'class_level') {
          this.addCourseForm.ageScope = '';
          this.addCourseForm.courseLevel = value;
        }
        this.searchMoveWidth = 0;
        this.searchCourseWidth = '100%';
        this.searchCourseList = [];
        this.isShow = false
        this.$nextTick(() => {
          // if (type != 'courseId') {
          //   this.addCourseForm.courseId = '';
          this.getCourseSearchList();
          // }
        });
      } else if (type == 'courseVersion') {
        this.addCourseForm[type] = value;
        this.addCourseForm.courseLevel = '';
        this.addCourseForm.ageScope = '';
        this.$nextTick(() => {
          this.getScopeList(() => {
            if (this.scopeOptions.length > 0) {
              if (this.scopeOptions[0].type == 'class_level') {
                this.addCourseForm.courseLevel = this.scopeOptions[0].value
              } else if (this.scopeOptions[0].type == 'age_scope') {
                this.addCourseForm.ageScope = this.scopeOptions[0].value
              }
            }
            this.searchMoveWidth = 0;
            this.searchCourseWidth = '100%';
            this.searchCourseList = [];
            this.isShow = false
            this.$nextTick(() => {
              // if (type != 'courseId') {
              //   this.addCourseForm.courseId = '';
              this.getCourseSearchList();
              // }
            });
          })
        })
      } else if (type == 'courseTheme') {
        this.addCourseForm[type] = value;
        this.addCourseForm.courseLevel = '';
        this.addCourseForm.ageScope = '';
        this.addCourseForm.courseVersion = ''
        this.$nextTick(() => {
          this.getVersionList(() => {
            if (this.versionOptions.length > 0) {
              this.addCourseForm.courseVersion = this.versionOptions[0].value
            }
            this.getScopeList(() => {
              if (this.scopeOptions.length > 0) {
                if (this.scopeOptions[0].type == 'class_level') {
                  this.addCourseForm.courseLevel = this.scopeOptions[0].value
                } else if (this.scopeOptions[0].type == 'age_scope') {
                  this.addCourseForm.ageScope = this.scopeOptions[0].value
                }
              }
              this.searchMoveWidth = 0;
              this.searchCourseWidth = '100%';
              this.searchCourseList = [];
              this.isShow = false

              this.$nextTick(() => {
                // if (type != 'courseId') {
                //   this.addCourseForm.courseId = '';
                this.getCourseSearchList();
                // }
              });
            })
          })
        })
      }
    },
    //搜索课程切换点变化
    handleTipChange (item, i) {
      let showIndex = this.searchCourseTipList.findIndex((tipInfo) => { return tipInfo.isShow == true })
      if (!item.isShow) {
        if (showIndex < i) {
          this.searchMoveWidth = this.searchMoveWidth - ((i - showIndex) * this.singleCourseMove)
        } else if (showIndex > i) {
          this.searchMoveWidth = this.searchMoveWidth + ((showIndex - i) * this.singleCourseMove)
        }
        if (!this.searchCourseList[i].isLike) {
          this.addCourseForm.courseId = this.searchCourseList[i].id
        }
        this.searchCourseTipList.map((info, j) => {
          if (j == i) {
            info.isShow = true
          } else {
            info.isShow = false
          }
        })
        this.$nextTick(() => {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
            customClass: "loadingStyle"
          });
          setTimeout(() => {
            this.showCourseInfo(this.searchCourseList[i])
          }, 500)
        })
      }
    },
    //搜索课程鼠标按下
    handleMoveDownCourse (e, item, i) {
      this.startDot = e.offsetX
    },
    //搜索课程鼠标抬起
    handleMoveUpCourse (e, item, index) {
      let endDot = e.offsetX
      if (endDot - this.startDot < -20) {
        if (index != this.searchCourseList.length - 1) {
          this.searchMoveWidth = this.searchMoveWidth - this.singleCourseMove
          if (!this.searchCourseList[index + 1].isLike) {
            this.addCourseForm.courseId = this.searchCourseList[index + 1].id
          }
          this.searchCourseTipList.map((info, i) => {
            if (i == index + 1) {
              info.isShow = true
            } else {
              info.isShow = false
            }
          })
          this.$nextTick(() => {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '加载中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
              customClass: "loadingStyle"
            });
            setTimeout(() => {
              this.showCourseInfo(this.searchCourseList[index + 1])
            }, 500)
          })
        }
      } else if (endDot - this.startDot > 20) {
        if (index != 0) {
          this.searchMoveWidth = this.searchMoveWidth + this.singleCourseMove
          if (!this.searchCourseList[index - 1].isLike) {
            this.addCourseForm.courseId = this.searchCourseList[index - 1].id
          }
          this.searchCourseTipList.map((info, i) => {
            if (i == index - 1) {
              info.isShow = true
            } else {
              info.isShow = false
            }
          })
          this.$nextTick(() => {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '加载中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
              customClass: "loadingStyle"
            });
            setTimeout(() => {
              this.showCourseInfo(this.searchCourseList[index - 1])
            }, 500)
          })
        }
      }
    },
    // 获取添加课程的课程列表
    getCourseSearchList (type) {
      let params = {
        courseClassify: this.addCourseForm.courseType,
        courseField: this.addCourseForm.courseField,
        courseTheme: this.addCourseForm.courseTheme,
        courseVersion: this.addCourseForm.courseVersion,
        courseSuitable: this.addCourseForm.ageScope,
        courseLevel: this.addCourseForm.courseLevel,
      };
      this.$api.getCourseSearchList(params).then((res) => {
        if (res.data.code === 0) {
          // this.searchCourseList = res.data.data;
          let list = []
          let searchList = []
          res.data.data.map((item, i) => {
            let info = JSON.parse(JSON.stringify(item))
            let listInfo = JSON.parse(JSON.stringify(item))
            let index = this.courseList.findIndex((courseInfo) => { return courseInfo.id == listInfo.id })
            if (i == 0) {
              info.isShow = true
            } else {
              info.isShow = false
            }
            if (index != -1) {
              listInfo.isLike = true
            } else {
              if (type !== 'noMove') {
                if (i == 0) {
                  this.addCourseForm.courseId = listInfo.id
                }
              } else {
                this.addCourseForm.courseId = null
              }
              listInfo.isLike = false
            }
            list.push(info)
            searchList.push(listInfo)
          })
          if (type !== 'noMove') {
            this.searchCourseWidth = (res.data.data.length * 100) + '%'
            this.searchCourseTipList = list
          }
          this.searchCourseList = searchList
          if (!this.isHaveCourse && this.courseThemeList.length == 0) {
            if (list.length > 0) {
              this.showCourseInfo(list[0]);
            }
          } else if (this.isAddShow) {
            if (list.length > 0) {
              this.showCourseInfo(list[0]);
            } else {
              this.isShow = false
              this.mainFlowFlag = 'hidden'
            }
          }
          this.$nextTick(() => {
            // this.searchCourseListHeight = 'auto';
            // this.isSearchCourseTurnShow = false;
            // this.searchCourseTurnFlag = false;
            if (type !== 'noMove') {
              if (this.searchCourseList.length > 0) {
                this.singleCourseMove = this.$refs.searchCourseShow.scrollWidth / this.searchCourseList.length
              }
            }
            console.log(this.singleCourseMove)
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 调整选择条件展开收起
    resizeChoose () {
      if (this.$refs.courseTypeList.scrollHeight > 30) {
        this.courseTypeListHeight = '1.5625vw';
        this.isCourseTypeTurnShow = true;
      } else {
        this.isCourseTypeTurnShow = false;
      }

      if (this.$refs.courseFieldList.scrollHeight > 30) {
        this.courseFieldListHeight = '1.5625vw';
        this.isCourseFieldTurnShow = true;
      } else {
        this.isCourseFieldTurnShow = false;
      }

      if (this.$refs.courseThemeList.scrollHeight > 30) {
        this.courseThemeListHeight = '1.5625vw';
        this.isCourseThemeTurnShow = true;
      } else {
        this.isCourseThemeTurnShow = false;
      }
      if (this.$refs.courseVersionList.scrollHeight > 30) {
        this.courseVersionListHeight = '1.5625vw';
        this.isCourseVersionTurnShow = true;
      } else {
        this.isCourseVersionTurnShow = false;
      }

      if (this.$refs.scopeList.scrollHeight > 30) {
        this.scopeListHeight = '1.5625vw';
        this.isScopeTurnShow = true;
      } else {
        this.isScopeTurnShow = false;
      }
      // if (this.$refs.courseLevelList.scrollHeight > 30) {
      //   this.courseLevelListHeight = '30px';
      //   this.isCourseLevelTurnShow = true;
      // } else {
      //   this.isCourseLevelTurnShow = false;
      // }
      // if (this.$refs.searchCourseList.scrollHeight > 30) {
      //   this.searchCourseListHeight = '30px';
      //   this.isSearchCourseTurnShow = true;
      // } else {
      //   this.isSearchCourseTurnShow = false;
      // }
    },
    // 处理某个筛选项展开收起
    handleShowInfo (type) {
      switch (type) {
        case 'courseType':
          if (this.isCourseTypeTurnShow) {
            this.courseTypeTurnFlag = !this.courseTypeTurnFlag;
            this.$nextTick(() => {
              this.courseTypeListHeight = this.courseTypeTurnFlag ? 'auto' : '1.5625vw';
            });
          }
          break;
        case 'courseField':
          if (this.isCourseFieldTurnShow) {
            this.courseFieldTurnFlag = !this.courseFieldTurnFlag;
            this.$nextTick(() => {
              this.courseFieldListHeight = this.courseFieldTurnFlag ? 'auto' : '1.5625vw';
            });
          }
          break;
        case 'courseTheme':
          if (this.isCourseThemeTurnShow) {
            this.courseThemeTurnFlag = !this.courseThemeTurnFlag;
            this.$nextTick(() => {
              this.courseThemeListHeight = this.courseThemeTurnFlag ? 'auto' : '1.5625vw';
            });
          }
          break;
        case 'courseVersion':
          if (this.isCourseVersionTurnShow) {
            this.courseVersionTurnFlag = !this.courseVersionTurnFlag;
            this.$nextTick(() => {
              this.courseVersionListHeight = this.courseVersionTurnFlag ? 'auto' : '1.5625vw';
            });
          }
          break;
        case 'scope':
          if (this.isScopeTurnShow) {
            this.scopeTurnFlag = !this.scopeTurnFlag;
            this.$nextTick(() => {
              this.scopeListHeight = this.scopeTurnFlag ? 'auto' : '1.5625vw';
            });
          }
          break;
        // case 'courseLevel':
        //   if (this.isCourseLevelTurnShow) {
        //     this.courseLevelTurnFlag = !this.courseLevelTurnFlag;
        //     this.$nextTick(() => {
        //       this.courseLevelListHeight = this.courseLevelTurnFlag ? 'auto' : '1.5625vw';
        //     });
        //   }
        //   break;
        // case 'searchCourse':
        //   if (this.isSearchCourseTurnShow) {
        //     this.searchCourseTurnFlag = !this.searchCourseTurnFlag;
        //     this.$nextTick(() => {
        //       this.searchCourseListHeight = this.searchCourseTurnFlag ? 'auto' : '1.5625vw';
        //     });
        //   }
        //   break;
      }
    },
    // 获取课程介绍
    getCourseIntro (row) {
      this.courseIntroVisible = true
      this.$nextTick(() => {
        this.courseIntroId = row.id + ""
      })
      // this.$router.push({ path: '/courseResource/courseIntro', query: { id: row.id, courseId: row.id } });
    },
    //关闭课程介绍弹窗
    handleCourseIntroClose () {
      this.courseIntroVisible = false
      this.$nextTick(() => {
        this.courseIntroId = ""
      })
    },
    //是否推送过
    getIsPush () {
      this.$api.getCoursePushInfo({ courseThemeCatalogueId: this.courseContentCatalogId }).then((res) => {
        if (res.data.code === 0) {
          this.pushText = '推送'
        } else {
          // this.$message.error(res.data.msg);
          this.pushText = '已推送'
        }
      });
    },
    // 课程内容推送处理
    coursePush () {
      if (this.pushText == '推送') {
        this.sendCourseVisible = true
        this.sendCourseData = null
        this.$nextTick(() => {
          this.sendCourseData = { courseId: this.courseId, courseCatalogId: this.courseContentCatalogId, catalogueName: this.contentTitle, getType: 'course' }
        })
      }
    },
    //关闭推送编辑弹窗
    handleSendCourseClose () {
      this.sendCourseVisible = false
      this.$nextTick(() => {
        this.sendCourseData = null
      })
    },
    //推送过后刷新操作
    refreshContent (flag) {
      if (flag) {
        this.pushText == ''
        this.handleSendCourseClose()
        this.$nextTick(() => {
          this.getIsPush()
        })
      } else {
        this.handleSendCourseClose()
      }
    },
    // 调整video个数和宽度
    resizeVideoInfo () {
      console.log(window.outerWidth);
      if (window.outerWidth <= 1800 && window.outerWidth > 1400) {
        this.videoSpan = 7;
        this.videoNum = 3;
        this.fileNum = 3;
      } else if (window.outerWidth <= 1400) {
        this.videoSpan = 10;
        this.videoNum = 2;
        this.fileNum = 3;
      } else if (window.outerWidth > 1800) {
        this.videoSpan = 5;
        this.videoNum = 3;
        this.fileNum = 3;
      }
    },
    // 调整height
    resizeHeight () {
      console.log(this.$refs.bodyLeft.offsetHeight);
      console.log(this.$refs.bodyRight.offsetHeight);
      this.bodyLeftHeight = 'auto';
      this.bodyRightHeight = 'auto';
      this.$nextTick(() => {
        this.bodyLeftHeight = this.$refs.bodyRight.offsetHeight + 'px';
        this.bodyRightHeight = this.$refs.bodyRight.offsetHeight + 'px';
        if (
          this.$refs.bodyLeft.offsetHeight > this.$refs.bodyRight.offsetHeight
        ) {
          this.bodyLeftHeight = this.$refs.bodyLeft.offsetHeight + 'px';
          this.bodyRightHeight = this.$refs.bodyLeft.offsetHeight + 'px';
        }
      });
    },
    // 判断课程内容目录是否有内容
    async isHaveCourseContentCatalogContent (id, callback) {
      console.log(id)
      let result = false
      await this.$api.getCourseThemeDetailInfo({ courseThemeCatalogueId: id }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length > 0) {
            result = true
          }
        } else {
          result = false
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        result = false
        // callback && callback(false)
      });

      return result
    },
    // 获取课程内容目录的内容list
    getCourseContentCatalogContentInfo () {
      this.$api.getCourseThemeDetailInfo({ courseThemeCatalogueId: this.courseContentCatalogId }).then((res) => {
        this.pageLoading.close();
        if (res.data.code === 0) {
          setTimeout(() => {
            if (res.data.data.length > 0) {
              res.data.data.map((item, index) => {
                item.showFlag = true;
                if (item.contentType == '2') {
                  console.log(this.activityTypeOptions);
                  // item.activityTypeRadio = this.activityTypeOptions[0].value;
                  let activityList = [];
                  this.activityTypeOptions.map((activityInfo) => {
                    if (item.eduActivity && item.eduActivity.length > 0) {
                      let index = item.eduActivity.findIndex((info) => {
                        return info.activityClassify == activityInfo.value;
                      });
                      let listIndex = activityList.findIndex((info) => {
                        return info.value == activityInfo.value;
                      });
                      if (index != -1 && listIndex == -1) {
                        activityList.push(activityInfo);
                      }
                    }
                  });
                  if (item.eduActivity && item.eduActivity.length > 0) {
                    item.activityListShow = true;
                  } else {
                    item.activityListShow = false;
                  }
                  if (activityList.length > 0) {
                    item.activityTypeRadio = activityList[0].value;
                    item.activityTypeList = activityList;
                    this.getCourseContentCatalogActivityList(item.id, activityList[0].value, null, null, item, index, () => {

                    });
                  }
                }
                if (item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0) {
                  item.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                    let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.'), fileInfo.fileUrl.length);
                    if (type.toLowerCase().indexOf('pdf') != -1) {
                      fileInfo.iconType = 'pdf';
                    }
                    if (type.toLowerCase().indexOf('doc') != -1) {
                      fileInfo.iconType = 'doc';
                    }
                    if (type.toLowerCase().indexOf('xls') != -1) {
                      fileInfo.iconType = 'xls';
                    }
                    if (type.toLowerCase().indexOf('ppt') != -1) {
                      fileInfo.iconType = 'ppt';
                    }
                  });
                }
                if (item.eduCourseThemeCatalogueContentTrees) {
                  item.eduCourseThemeCatalogueContentTrees.map((info, i) => {
                    info.showFlag = true;
                    if (info.contentType == '2') {
                      // info.activityTypeRadio = this.activityTypeOptions[0].value;
                      let activityList = [];
                      this.activityTypeOptions.map((activityInfo) => {
                        if (info.eduActivity && info.eduActivity.length > 0) {
                          let index = info.eduActivity.findIndex((result) => {
                            return result.activityClassify == activityInfo.value;
                          });
                          let listIndex = activityList.findIndex((result) => {
                            return result.value == activityInfo.value;
                          });
                          if (index != -1 && listIndex == -1) {
                            activityList.push(activityInfo);
                          }
                        }
                      });
                      if (info.eduActivity && info.eduActivity.length > 0) {
                        info.activityListShow = true;
                      } else {
                        info.activityListShow = false;
                      }
                      if (activityList.length > 0) {
                        info.activityTypeRadio = activityList[0].value;
                        info.activityTypeList = activityList;
                        this.getCourseContentCatalogActivityList(info.id, activityList[0].value, info, i, item, index, () => {

                        });
                      }
                    }
                    if (info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0) {
                      info.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.'), fileInfo.fileUrl.length);
                        if (type.toLowerCase().indexOf('pdf') != -1) {
                          fileInfo.iconType = 'pdf';
                        }
                        if (type.toLowerCase().indexOf('doc') != -1) {
                          fileInfo.iconType = 'doc';
                        }
                        if (type.toLowerCase().indexOf('xls') != -1) {
                          fileInfo.iconType = 'xls';
                        }
                        if (type.toLowerCase().indexOf('ppt') != -1) {
                          fileInfo.iconType = 'ppt';
                        }
                      });
                    }
                  });
                }
              });
              this.courseContentList = res.data.data;
              if (this.isOpen === '1') {
                this.isParentShow = true;
              } else {
                this.isParentShow = false;
              }
              setTimeout(() => {
                this.resizeHeight();
              }, 400);
            } else {
              this.courseContentList = [];
              this.isParentShow = false;
              setTimeout(() => {
                this.resizeHeight();
              }, 400);
            }
          }, 300);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    // 获取课程内容目录的活动内容list
    getCourseContentCatalogActivityList (id, type, secondItem, secondIndex, firstItem, firstIndex, callback) {
      this.$api.contentCatalogActivityHandle('/api/course/activity/list', { courseThemeCatalogueContentId: id, activityClassify: type, }, 'list').then((res) => {
        if (res.data.code === 0) {
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.activityList = res.data.data;
            this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.activityList = res.data.data;
            this.$set(this.courseContentList, firstIndex, info);
          }
          setTimeout(() => {
            this.resizeHeight();
            callback && callback();
          }, 300);
        } else {
          this.$message.error(res.data.msg);
          callback && callback();
        }
      });
    },
    // 获取目标清单信息list
    getCourseContentCatalogTargetList () {
      this.$api.getCourseThemeTargetList({ courseThemeCatalogueId: this.courseContentCatalogId }).then((res) => {
        if (res.data.code === 0) {
          this.courseContentTargetInfo = res.data.data;
          setTimeout(() => {
            this.resizeHeight();
          }, 400);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 处理下拉turn事件
    handleTreeTurn (themeItem, themeIndex, firstItem, firstIndex, secondItem, secondIndex) {
      let info = null;
      if (secondItem) {
        info = JSON.parse(JSON.stringify(secondItem));
        info.isShowTree = !info.isShowTree;
        this.$set(this.courseThemeList[themeIndex].catalogData[firstIndex].children, secondIndex, info);
      } else {
        info = JSON.parse(JSON.stringify(firstItem));
        info.isShowTree = !info.isShowTree;
        if (info.isShowTree) {
          if (info.children && info.children.length > 0) {
            info.children.map((item) => {
              item.isShowTree = true
            })
          }
        } else {
          if (info.children && info.children.length > 0) {
            info.children.map((item) => {
              item.isShowTree = false
            })
          }
        }
        this.$set(this.courseThemeList[themeIndex].catalogData, firstIndex, info);
      }
      // this.showMenuFlag = false
      // this.$nextTick(() => {
      //   console.log(this.courseThemeList)
      //   this.showMenuFlag = true
      // })
      this.$forceUpdate();
      this.$nextTick(() => {
        this.resizeHeight();
      });
    },
    // 切换目录
    async handleTreeClick (info, themeIndex, firstItem, firstIndex, secondItem, secondIndex) {
      let contentFlag = await this.isHaveCourseContentCatalogContent(info.id)
      console.log(contentFlag)
      console.log(info)
      if (contentFlag === true || (contentFlag === false && info.isLeaf)) {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass: "loadingStyle"
        });
        this.courseContentCatalogId = info.id;
        this.contentTitle = info.catalogueName;
        this.isOpen = info.isOpen;
        this.isParentShow = info.isOpen === '1';
        this.pushText = ''
        this.getIsPush()
        this.getCourseContentCatalogContentInfo();
        this.getCourseContentCatalogTargetList();
      } else if (contentFlag === false && !info.isLeaf) {
        this.handleTreeTurn(null, themeIndex, firstItem, firstIndex, secondItem, secondIndex)
      }
    },
    // 内容操作
    handleContentItem (type, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      switch (type) {
        case 'turnItem':
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseContentList, firstIndex, info);
          }
          this.$nextTick(() => {
            this.resizeHeight();
          });
          break;
      }
    },
    // 预览富文本图片
    handleImgClick (e) {
      console.log(e);
      if (e.target.nodeName == 'IMG') {
        this.viewUrl = e.target.currentSrc;
        this.viewUrlList = [e.target.currentSrc];
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true;
        });
      }
    },
    // 预览文件
    fileView (fileInfo, type) {
      if (type == 'view') {
        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
        if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
          this.viewUrl = fileInfo.fileUrl;
          this.viewUrlList = [fileInfo.fileUrl];
          this.$nextTick(() => {
            this.$refs.viewImg.showViewer = true;
          });
        } else {
          // window.location.href = fileInfo.fileUrl
          const { originalName, fileVersionId } = fileInfo
          console.log(fileInfo);
          this.getFileViewUrl('', { fileVersionId, name: originalName }, (data) => {
            if (data) {
              sessionStorage.setItem('viewUrl', data);
              let routeUrl = this.$router.resolve({ path: '/fileView.html' });
              window.open(routeUrl.href, '_blank');
            }
          });
        }
      } else {
        if (fileInfo.isDownload === '1') {
          let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
          if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
            this.$message.warning('该附件为图片，只可预览查看');
          } else {
            // window.location.href = fileInfo.fileUrl;
            this.exportFile(fileInfo.fileUrl, {}, fileInfo.originalName)
          }
        } else {
          this.$message.warning('该附件不支持下载！');
        }
      }
    },
    // 视频预览
    videoView (videoInfo) {
      this.videoViewDialogVisible = true;
      this.$nextTick(() => {
        let type = videoInfo.videoUrl.substr(videoInfo.videoUrl.lastIndexOf('.') + 1, videoInfo.videoUrl.length);
        console.log(type);
        this.videoViewType = type == 'mp4' || type == 'avi' ? 'video' : type == 'mp3' ? 'audio' : '';
        this.videoViewUrl = videoInfo.videoUrl;
      });
    },
    // 视频预览关闭
    videoViewClose () {
      this.videoViewDialogVisible = false;
      this.$nextTick(() => {
        this.videoViewType = '';
        this.videoViewUrl = '';
      });
    },
    // 目标清单操作
    handleTaggetItem (type, row) {
      if (type == 'suggestView') {
        this.suggestsViewDialogVisible = true;
        this.$nextTick(() => {
          this.courseContentCatalogTargetId = row.targetCatalogueId;
          // this.courseContentCatalogTargetInfoId = row.targetInfoId;
        });
      } else if (type == 'practiceView') {
        this.practicesViewDialogVisible = true;
        this.$nextTick(() => {
          this.courseContentCatalogTargetId = row.targetCatalogueId;
          this.courseContentCatalogTargetFlag = row.flag;
          this.courseContentCatalogTargetTypeValue = row.typeValue;
          this.courseContentCatalogTargetInfoId = row.targetInfoId;
        });
      }
    },
    // 教学建议查看关闭
    suggestsDialogClose () {
      this.suggestsViewDialogVisible = false;
      this.$nextTick(() => {
        this.courseContentCatalogTargetId = null;
        // this.courseContentCatalogTargetInfoId = null;
      });
    },
    // 教学实践查看关闭
    practicesDialogClose () {
      this.practicesViewDialogVisible = false;
      this.$nextTick(() => {
        this.courseContentCatalogTargetId = null;
        this.courseContentCatalogTargetInfoId = null;
        this.courseContentCatalogTargetFlag = '';
        this.courseContentCatalogTargetTypeValue = '';
      });
    },
    // 活动切换操作
    handleRadioChange (val, secondItem, secondIndex, firstItem, firstIndex) {
      let info = null;
      if (secondItem) {
        info = { ...secondItem };
        info.activityTypeRadio = val;
        this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
        this.$nextTick(() => {
          this.getCourseContentCatalogActivityList(secondItem.id, val, info, secondIndex, firstItem, firstIndex);
        });
      } else {
        info = { ...firstItem };
        // info.showFlag = !info.showFlag;
        info.activityTypeRadio = val;
        this.$set(this.courseContentList, firstIndex, info);
        this.$nextTick(() => {
          this.getCourseContentCatalogActivityList(firstItem.id, val, secondItem, secondIndex, info, firstIndex);
        });
      }
    },
  },
  beforeDestroy () {
    window.onresize = null;
  },
};
</script>
<style lang="scss" scoped>
html,
body {
  height: 100%;
}
.course-resource-outside {
  width: 100%;
  height: 100%;
}
.course-resource {
  width: 100%;
  height: calc(100% - 61px);
  padding-top: 61px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  .course-resource-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(112, 112, 112, 0.15);
    .text {
      width: 100%;
      height: 100%;
      margin-left: 24px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      display: flex;
      align-items: center;
      .line {
        width: 24px;
        height: 3px;
        background-color: #597ef7;
        position: absolute;
        bottom: 0;
        left: 40px;
        transform: translateX(-50%);
      }
    }
  }
}
.box-card {
  margin-bottom: 0;
  /deep/.el-card__body {
    padding: 0;
  }
  &.course {
    /deep/.el-card__body {
      padding: 0 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &.selection {
    overflow: unset;
    position: relative;
  }
  &.shadow {
    box-shadow: 0px 2px 12px rgba(89, 126, 247, 0.3);
  }
}
.course-choose-arraw {
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -15px;
    right: 31px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #ffffff;
    z-index: 100;
  }
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -16px;
    right: 30px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid rgba(89, 126, 247, 0.3);
  }
}
.course-list {
  width: calc(100% - 115px);
  height: 70px;
  padding: 18px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .list-left {
    width: 10px;
    height: 14px;
    margin-right: 18px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .list-info {
    width: calc(100% - 56px);
    height: 100%;
    overflow: hidden;
    .list-inside-info {
      height: 100%;
      .info-item {
        padding: 0 18px;
        width: 252px;
        height: 70px;
        margin-right: 12px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        .item-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          color: rgba(0, 0, 0, 0.85);
        }
        .item-title {
          width: calc(100% - 70px);
          font-size: 19px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item-ltitle {
          text-align: right;
          font-size: 14px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 50px;
          // margin: 1.09375vw 0 1.25vw 0;
        }
        // .item-button {
        //   width: 5.625vw;
        //   height: 1.822917vw;
        //   border: 1px solid #ffffff;
        //   border-radius: 23px;
        //   font-size: 0.833333vw;
        //   color: #ffffff;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        // }
        // .item-del {
        //   position: absolute;
        //   top: 0.520833vw;
        //   right: 0.677083vw;
        //   width: 0.833333vw;
        //   height: 0.833333vw;
        // }
        &:last-child {
          margin-right: 0;
        }
        &.add {
          .item-content {
            flex-direction: row;
            align-items: center;
          }
          .add-button {
            width: 8.229167vw;
            height: 2.1875vw;
            border: 2px solid #ffffff;
            border-radius: 23px;
            text-align: center;
            line-height: 2.1875vw;
            font-size: 0.989583vw;
            color: #ffffff;
          }
        }
        &.active {
          .item-content {
            color: #ffffff;
          }
        }
      }
    }
  }
  .list-right {
    width: 10px;
    height: 14px;
    margin-left: 18px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.add-course {
  width: 70px;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px rgba(89, 126, 247, 0.25);
  border-radius: 43px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .search-select-icon {
    width: 14px;
    height: 13px;
    &.turn {
      transform: rotate(180deg);
    }
  }
  .add-course-text {
    font-size: 11px;
    font-weight: 400;
    color: #333333;
    margin-top: 2px;
    width: 100%;
    text-align: center;
  }
}
.course-choose-content {
  width: calc(100% - 36px);
  padding: 14px 18px 9px 18px;
  .content-item {
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px dashed #e9e9e9;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      width: 60px;
      height: 28px;
      line-height: 28px;
      font-size: 17px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .item-list {
      width: calc(100% - 110px);
      .item {
        min-width: 96px;
        padding: 2px 14px;
        height: 24px;
        margin-right: 12px;
        font-size: 17px;
        font-weight: 400;
        text-align: center;
        line-height: 23px;
        cursor: pointer;
        float: left;
        border: 1px solid #e8e8e8;
        border-radius: 24px;
        color: rgba(0, 0, 0, 0.65);
        &:last-child {
          margin-right: 0;
        }
        &.active {
          color: #ffffff;
          background-color: #1890ff;
        }
      }
    }
    .turn-icon {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      height: 24px;
      line-height: 23px;
      i {
        margin-left: 5px;
      }
    }
  }
}
.course-show {
  padding: 18px;
  border-radius: 5px;
  background-color: #ffffff;
  width: calc(100% - 36px);
  height: 96px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.15);
  &.fixedShow {
    position: fixed;
    top: 115px;
    z-index: 10;
  }
  .course-show-outside {
    min-width: 100%;
    height: 100%;
    .course-show-item {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .course-show-img {
        border-radius: 10px;
        width: 144px;
        height: 96px;
      }
      .course-show-info {
        // margin: 3px 0 17px 0;
        width: calc(100% - 159px);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .info-title {
          width: 100%;
          font-size: 19px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .info-description {
          width: 100%;
          font-size: 17px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 30px;
          .label {
            color: #000000;
          }
        }
      }
      .course-show-buttons {
        position: absolute;
        top: 25px;
        right: 0;
        display: flex;
        align-items: center;
        .course-intro-button {
          width: 99px;
          height: 29px;
          line-height: 28px;
          text-align: center;
          border: 1px solid #597ef7;
          border-radius: 24px;
          font-size: 17px;
          font-weight: 400;
          color: #597ef7;
          background-color: #ffffff;
          margin-right: 12px;
          cursor: pointer;
          &:hover {
            color: #ffffff;
            background-color: #597ef7;
          }
        }
        .course-submit-button {
          width: 101px;
          height: 31px;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          font-weight: 400;
          cursor: pointer;
          .collect-icon {
            width: 17px;
            height: 14px;
            margin-right: 4px;
          }
          &.cancel {
            color: rgba(0, 0, 0, 0.45);
            background-color: #f2f2f2;
          }
          &.like {
            color: #ffffff;
            background-color: #597ef7;
          }
        }
      }
    }
    .course-show-empty {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 125px;
        height: 84px;
      }
      .course-show-empty-text {
        font-size: 17px;
        font-weight: 400;
        color: #aca8a8;
        margin-top: 6px;
      }
    }
  }
  &.search {
    width: 100%;
    height: 156px;
    padding: 0;
    overflow-x: auto;
    .course-show-outside {
      height: 132px;
      display: flex;
      .course-show-item {
        padding: 18px 0;
        height: 96px;
        margin: 0 18px;
        height: calc(100% - 36px);
        width: 1659px;
      }
    }
    .course-show-tips {
      width: auto;
      height: 12px;
      margin: auto;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      .course-show-tip {
        width: 12px;
        height: 12px;
        background: #c7c5c5;
        opacity: 0.25;
        border-radius: 12px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        &.active {
          width: 31px;
          height: 12px;
          background: #597ef7;
          opacity: 0.8;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }
}
.ZWDiv {
  width: 100%;
  height: 132px;
}
.footer-button {
  margin: 35px 0;
  text-align: center;
  .add-course-button {
    width: 220px;
    height: 44px;
    box-sizing: border-box;
    &.el-button--primary {
      background-color: #ffffff;
      &:hover {
        background-color: #597ef7;
        color: #ffffff;
      }
      &:focus {
        background-color: #597ef7;
        color: #ffffff;
      }
    }
  }
}
.content-body {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  min-height: 500px;
  .body-left {
    width: 288px;
    min-height: 500px;
    background-color: #ffffff;
    box-shadow: 0px 3px 17px rgba(0, 0, 0, 0.05);
    .el-menu-item:focus,
    .el-menu-item:hover {
      background-color: transparent !important;
    }
    .el-submenu .el-menu-item {
      height: auto;
      line-height: normal;
      padding: 0 !important;
      min-width: 0;
    }
    .tree-list {
      width: 100%;
      .tree-item {
        width: 100%;
      }
      .tree-title {
        width: calc(100% - 58px);
        padding: 20px 18px 20px 40px;
        font-size: 0.833333vw;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.75);
        cursor: pointer;
        position: relative;
        white-space: normal;
        word-break: break-all;
        line-height: 1.4;
        &.active {
          background-color: rgba(89, 126, 247, 0.05);
          border-left: 4px solid #597ef7;
          width: calc(100% - 62px);
          border-radius: 5px;
          &.parentShow {
            width: calc(100% - 106px);
          }
        }
        &.parentShow {
          width: calc(100% - 102px);
          padding: 20px 62px 20px 40px;
        }
        .parent-show {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          width: 52px;
          height: 28px;
          line-height: 28px;
          color: #ffffff;
          font-weight: 400;
          font-size: 0.729167vw;
          background-color: #597ef7;
          border-radius: 23px;
          text-align: center;
        }
        i {
          position: absolute;
          font-size: 1.041667vw;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          // &.hideTurn{
          //   transform: rotate(-180deg) translateY(-50%);
          // }
        }
      }
      .sub-tree-list {
        width: 100%;
        .tree-title {
          width: calc(100% - 78px);
          padding: 20px 18px 20px 60px;
          &.active {
            width: calc(100% - 82px);
            &.parentShow {
              width: calc(100% - 126px);
            }
          }
          &.parentShow {
            width: calc(100% - 122px);
            padding: 20px 62px 20px 60px;
          }
        }
      }
    }
  }
  .body-right {
    width: calc(100% - 288px);
    .content-title {
      width: calc(100% - 24px);
      margin-left: 24px;
      padding: 28px 0 17px 0;
      font-size: 26px;
      font-weight: bold;
      color: #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 65px; //54px
        height: 31px; //26px
        margin: 0 28px;
        cursor: pointer;
      }
      .push-button {
        padding: 0 10px;
        height: 27px;
        border: 2px solid #dcdfe6;
        color: rgba(0, 0, 0, 0.75);
        background-color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 26px;
        border-radius: 20px;
        cursor: pointer;
        margin: 0 28px;
      }
    }
  }
}
//tab样式
/deep/.el-tabs__nav-wrap {
  padding: 0 20px;
  height: 60px;
}

/deep/.el-tabs__nav {
  font-size: 1.041667vw;
  height: 60px;
  .el-tabs__item {
    height: 60px;
    line-height: 60px;
    font-size: 1.041667vw;
  }
}

/deep/.el-submenu.is-active .el-submenu__title {
  font-size: 19px;
  font-weight: 600;
  color: #597ef7;
  background: rgba(0, 0, 0, 0.1);
}
/deep/.el-submenu .el-submenu__title {
  font-size: 19px;
  font-weight: 400;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 30px;
  .submenu-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-submenu__icon-arrow {
    right: 15px;
  }
}
/deep/.course-intro-dialog {
  overflow: hidden;
  .el-dialog__body {
    height: calc(100% - 115px);
    overflow: hidden;
    overflow-y: auto;
  }
}
.course-intro-dialog-title {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  .course-intro-dialog-close-button {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 18px;
    color: #597ef7;
    font-weight: 400;
    cursor: pointer;
  }
}
/deep/.top-back {
  &.el-backtop {
    right: 10px !important;
    bottom: 120px !important;
  }
}
</style>
