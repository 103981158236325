<template>
  <div class="course-edit">
    <!--v-loading="pageLoading"-->
    <el-card class="box-card publishDetail"
             shadow="never">
      <div class="course-header publist-detail-header"
           :style="{ 'background-image': 'url('+defaultHeaderBg+')', 'background-repeat': 'no-repeat' , 'background-size': '100% 6.25vw' }">
        <div class="header-left">
          <div class="img-div">
            <img :src="courseInfo.coverImg ? courseInfo.coverImg : defaultHeaderImg"
                 class="course-img" />
          </div>
          <div class="course-info">
            <div class="title"
                 style="margin-top: 1.30208vw;">{{ courseInfo.courseName }}</div>
            <div class="course-info-bottom">
              <div class="bottom-item">
                <div class="label">类型：</div>
                <div class="text">{{ courseInfo.courseClassifyName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label">领域：</div>
                <div class="text">{{ courseInfo.courseFieldName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label">系列：</div>
                <div class="text">{{ courseInfo.courseThemeName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label">版本：</div>
                <div class="text">{{ courseInfo.courseVersionName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item"
                   v-if="courseInfo.courseLevel !== ''">
                <div class="label">等级：</div>
                <div class="text">{{ courseInfo.courseLevelName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item"
                   v-if="courseInfo.courseSuitable !== ''">
                <div class="label">阶段：</div>
                <div class="text">{{ courseInfo.courseSuitableName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label">老师：</div>
                <div class="text">{{ courseInfo.userName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label long">创建时间：</div>
                <div class="text long">{{ courseInfo.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="course-intro-title">
        <div class="text active">
          <span>课程介绍</span>
          <div class="line"></div>
        </div>
      </div>
    </el-card>
    <div class="tab-info">
      <div class="panel-data"
           v-if="courseIntroInfo.length > 0">
        <div class="first-panel courseShow"
             v-for="(item, index) in courseIntroInfo"
             :key="item.id">
          <div class="panel-header">
            <div class="title">
              <div class="title-icon"></div>{{ item.catalogueName }}
            </div>
            <div class="button">
              <img :src="firstPanelTurnIcon"
                   alt=""
                   @click="handleContentItem('turnItem', null, null, item, index)"
                   style="margin-left: 0.41667vw"
                   :class="{ 'turn-icon-class': !item.showFlag }" />
            </div>
          </div>
          <div class="panel-content"
               v-show="item.showFlag"
               v-if="item.eduCourseIntroContentTree.length > 0">
            <div class="second-panel"
                 v-for="(info, i) in item.eduCourseIntroContentTree"
                 :key="info.id">
              <div class="panel-header">
                <div class="title">{{ info.catalogueName }}</div>
                <div class="button">
                  <img :src="secondPanelTurnIcon"
                       alt=""
                       @click="handleContentItem('turnItem', info, i, item, index)"
                       style="margin-left: 0.41667vw"
                       :class="{ 'turn-icon-class': !info.showFlag }" />
                </div>
              </div>
              <div class="panel-content"
                   v-show="info.showFlag"
                   v-if="info.catalogueType == '0'">
                <div class="content-info"
                     v-if="info.eduCourseIntroContent.length > 0">
                  <!-- <div class="ql-container ql-snow">
                    <div class="ql-editor" v-html="info.eduCourseIntroContent[0].content"></div>
                  </div> -->
                  <div class="w-e-text-container"
                       @click="handleImgClick">
                    <div class="w-e-text"
                         v-html="info.eduCourseIntroContent[0].content"></div>
                  </div>
                </div>
                <div class="file-info"
                     v-if="info.eduCourseIntroFile && info.eduCourseIntroFile.length > 0">
                  <div class="file-item"
                       v-for="(fileInfo,fileIndex) in info.eduCourseIntroFile"
                       :key="fileInfo.id"
                       :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.52083vw', 'width': '22.08333vw' }">
                    <div class="content">
                      <img class="icon"
                           :src="fileIconPDF"
                           alt=""
                           v-if="fileInfo.iconType == 'pdf'" />
                      <img class="icon"
                           :src="fileIconWord"
                           alt=""
                           v-if="fileInfo.iconType == 'doc'" />
                      <img class="icon"
                           :src="fileIconExcel"
                           alt=""
                           v-if="fileInfo.iconType == 'xls'" />
                      <img class="icon"
                           :src="fileIconPPT"
                           alt=""
                           v-if="fileInfo.iconType == 'ppt'" />
                      <div class="message">
                        <div class="title">{{ fileInfo.originalName }}</div>
                        <!-- <div class="ltitle">{{ fileInfo.remark }}</div> -->
                      </div>
                    </div>
                    <div class="bottom">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件下载"
                                  placement="bottom"
                                  v-if="fileInfo.isDownload == '1'">
                        <img :src="fileDownloadIcon"
                             alt=""
                             @click="fileView(fileInfo,'download')"
                             style="margin-right: 0.41667vw"
                             v-if="fileInfo.isDownload == '1'" />
                      </el-tooltip>
                      <el-divider direction="vertical"
                                  v-if="fileInfo.isDownload == '1'"></el-divider>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件预览"
                                  placement="bottom">
                        <img :src="fileViewIcon"
                             alt=""
                             @click="fileView(fileInfo,'view')"
                             style="margin: 0 0.26042vw 0 0.41667vw" />
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-content"
                   v-show="info.showFlag"
                   v-if="info.catalogueType == '1'">
                <el-row style="padding: 1.25vw 0.9375vw;">
                  <!--type="flex"-->
                  <!-- <el-col :span="videoSpan" v-for="(video, k) in info.eduCourseIntroVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '4vw' : '0', 'margin-bottom': '1.04167vw', }"> -->
                  <el-card shadow="hover"
                           class="video-card"
                           v-for="(video, k) in info.eduCourseIntroVideo"
                           :key="video.id"
                           :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '0.67708vw' : '0', 'margin-bottom': '0.67708vw', 'width': '22.08333vw', display: 'inline-block' }"
                           :body-style="{ padding: 'undefined' }"
                           @click.native="videoView(video)">
                    <div class="video-body"
                         :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                      <img :src="videoPlayIcon"
                           alt="" />
                    </div>
                    <div class="info-body video-item">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="video.videoName"
                                  placement="top">
                        <div class="title">{{ video.videoName }}</div>
                      </el-tooltip>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  :content="video.intro"
                                  placement="top"
                                  v-if="video.intro">
                        <div class="ltitle"
                             v-if="video.intro">{{ video.intro }}</div>
                      </el-tooltip>
                      <div class="bottom">
                        <div class="video-time">{{ video.createTime }}</div>
                        <div class="video-button"></div>
                      </div>
                    </div>
                  </el-card>
                  <!-- </el-col> -->
                </el-row>
              </div>
            </div>
          </div>
          <div class="panel-content"
               v-show="item.showFlag"
               v-if="item.eduCourseIntroContentTree.length == 0">
            <div class="content-panel"
                 v-if="item.catalogueType == '0'">
              <div class="content-info"
                   v-if="item.eduCourseIntroContent.length > 0">
                <!-- <div class="ql-container ql-snow">
                  <div class="ql-editor" v-html="item.eduCourseIntroContent[0].content"></div>
                </div> -->
                <div class="w-e-text-container"
                     @click="handleImgClick">
                  <div class="w-e-text"
                       v-html="item.eduCourseIntroContent[0].content"></div>
                </div>
              </div>
              <div class="file-info"
                   v-if="item.eduCourseIntroFile && item.eduCourseIntroFile.length > 0">
                <div class="file-item"
                     v-for="(fileInfo,fileIndex) in item.eduCourseIntroFile"
                     :key="fileInfo.id"
                     :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '0.52083vw', 'width': '22.08333vw' }">
                  <div class="content">
                    <img class="icon"
                         :src="fileIconPDF"
                         alt=""
                         v-if="fileInfo.iconType == 'pdf'" />
                    <img class="icon"
                         :src="fileIconWord"
                         alt=""
                         v-if="fileInfo.iconType == 'doc'" />
                    <img class="icon"
                         :src="fileIconExcel"
                         alt=""
                         v-if="fileInfo.iconType == 'xls'" />
                    <img class="icon"
                         :src="fileIconPPT"
                         alt=""
                         v-if="fileInfo.iconType == 'ppt'" />
                    <div class="message">
                      <div class="title">{{ fileInfo.originalName }}</div>
                      <!-- <div class="ltitle">{{ fileInfo.remark }}</div> -->
                    </div>
                  </div>
                  <div class="bottom">
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件下载"
                                placement="bottom"
                                v-if="fileInfo.isDownload == '1'">
                      <img :src="fileDownloadIcon"
                           alt=""
                           @click="fileView(fileInfo,'download')"
                           style="margin-right: 0.41667vw"
                           v-if="fileInfo.isDownload == '1'" />
                    </el-tooltip>
                    <el-divider direction="vertical"
                                v-if="fileInfo.isDownload == '1'"></el-divider>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件预览"
                                placement="bottom">
                      <img :src="fileViewIcon"
                           alt=""
                           @click="fileView(fileInfo,'view')"
                           style="margin: 0 0.26042vw 0 0.41667vw" />
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-panel"
                 v-if="item.catalogueType == '1'">
              <el-row style="padding: 1.25vw 0.9375vw;">
                <!--type="flex"-->
                <!-- <el-col :span="videoSpan" v-for="(video, k) in item.eduCourseIntroVideo" :key="video.id" :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '4vw' : '0', 'margin-bottom': '1.04167vw', }"> -->
                <el-card shadow="hover"
                         class="video-card"
                         v-for="(video, k) in item.eduCourseIntroVideo"
                         :key="video.id"
                         :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '0.67708vw' : '0', 'margin-bottom': '0.67708vw', 'width': '22.08333vw', display: 'inline-block' }"
                         :body-style="{ padding: 'undefined' }"
                         @click.native="videoView(video)">
                  <div class="video-body"
                       :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                    <img :src="videoPlayIcon"
                         alt="" />
                  </div>
                  <div class="info-body video-item">
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                :content="video.videoName"
                                placement="top">
                      <div class="title">{{ video.videoName }}</div>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                :content="video.intro"
                                placement="top"
                                v-if="video.intro">
                      <div class="ltitle"
                           v-if="video.intro">{{ video.intro }}</div>
                    </el-tooltip>
                    <div class="bottom">
                      <div class="video-time">{{ video.createTime }}</div>
                      <div class="video-button"></div>
                    </div>
                  </div>
                </el-card>
                <!-- </el-col> -->
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-panel"
           v-if="courseIntroInfo.length == 0">
        <img class="empty-img"
             :src="emptyImg"
             alt="" />
        <div class="empty-title">暂无内容</div>
        <!-- <div class="empty-ltitle">需要先进行内容设置后才能进行查看</div> -->
      </div>
    </div>
    <el-image style="width: 0; height: 0;"
              ref="viewImg"
              :src="viewUrl"
              :preview-src-list="viewUrlList"></el-image>
    <video-view-dialog ref="videoView"
                       :videoViewDialogVisible.sync="videoViewDialogVisible"
                       :type="videoViewType"
                       :videoUrl="videoViewUrl"
                       @closeDialog="videoViewClose"></video-view-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VideoViewDialog from "@/components/videoView/index.vue";
import imgOfflineIcon from "@/assets/img/img-offline-icon.png";
import imgOnlineIcon from "@/assets/img/img-online-icon.png";
import emptyImg from "@/assets/img/empty-img.png";
import firstPanelTurnIcon from "@/assets/img/first-panel-turn-icon.png";
import fileViewIcon from "@/assets/img/file-view-icon.png";
import fileDownloadIcon from '@/assets/img/file-download-icon.png';
import secondPanelTurnIcon from "@/assets/img/second-panel-turn-icon.png";
import fileIconPDF from "@/assets/img/file-icon-PDF.png";
import fileIconWord from "@/assets/img/file-icon-word.png";
import fileIconExcel from "@/assets/img/file-icon-excel.png";
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
import videoPlayIcon from "@/assets/img/play-icon.png";
import defaultImg from "@/assets/img/default-img.png";
import defaultHeaderImg from "@/assets/img/default-header-img.png";
import defaultHeaderBg from "@/assets/img/detail-header-bg.png";
import { getToken } from "@/utils/auth";
import { getAgeScopeName, getCourseLevelName, getCourseTypeName, getCourseFieldName, getCourseVersionName, getCourseThemeName } from "@/utils/getDicName";
import { getFileViewUrl, exportFile } from "@/utils/fileUtils.js";
export default {
  name: "courseIntro",
  props: {
    courseIntroId: {
      type: String,
      default: "",
    },
  },
  components: {
    VideoViewDialog,
  },
  inject: ["tagClose"],
  data () {
    return {
      courseInfo: {
        coverImg: "",
        courseName: "",
        courseField: "",
        courseClassify: "",
        courseTheme: "",
        courseVersion: "",
        courseLevel: "",
        courseSuitable: "",
        userName: "",
        createTime: "",
      }, // 课程基本信息

      courseId: null, // 编辑课程id

      pageLoading: null, // 页面loading
      courseIntroInfo: [], // 课程介绍详情
      secondInfo: {}, // 二级对象
      scondIndex: null, // 二级对象下标
      firstInfo: {}, // 一级对象
      firstIndex: null, // 一级对象下标

      imgOfflineIcon, // 图片下架icon
      imgOnlineIcon, // 图片上架icon
      emptyImg, // 空数据图片
      firstPanelTurnIcon, // 一级折叠icon
      fileViewIcon, // 文件预览icon
      fileDownloadIcon,//文件下载icon
      secondPanelTurnIcon, // 二级折叠icon
      fileIconPDF, // pdfIcon
      fileIconWord, // wordIcon
      fileIconExcel, // excelIcon
      fileIconPPT,//PPTIcon
      videoPlayIcon, // 视频icon
      defaultImg, // 默认图片
      defaultHeaderImg, // 头部信息默认图片
      defaultHeaderBg, // 头部背景图

      videoViewDialogVisible: false, // 视频预览标识
      videoViewType: "", // 视频类型
      videoViewUrl: "", // 视频预览url

      videoSpan: 6, // video宽度
      videoNum: 4, // video个数
      fileNum: 4, //附件个数

      courseContentSpan: 5, // 课程内容宽度
      courseContentNum: 4, // 课程内容个数
      fileTypeList: ["png", "jpg", "jpeg"], //文件类型list

      viewUrl: '', //附件图片预览
      viewUrlList: [],//附件图片预览list
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      themeOptions: (state) => state.common.courseThemeList,
      versionOptions: (state) => state.common.courseVersionList,
    }),
  },
  watch: {
    courseIntroId (val) {
      if (val) {
        this.courseId = val;
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          this.getData();
          this.getCourseIntroInfo();
        }, 300);
        this.resizeVideoInfo();
      }
    }
  },
  created () {
    this.courseId = this.$route.query.id ? Number(this.$route.query.id) : this.courseIntroId;
    let that = this;
    window.onresize = function () {
      that.resizeVideoInfo();
    };
    if (this.$route.query.id) {
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        this.getData();
        this.getCourseIntroInfo();
      }, 300);
      that.resizeVideoInfo();
    }
  },
  methods: {
    getToken,
    getAgeScopeName,
    getCourseLevelName,
    getCourseTypeName,
    getCourseFieldName,
    getFileViewUrl,
    getCourseVersionName,
    getCourseThemeName,
    exportFile,
    // 调整video个数和宽度
    resizeVideoInfo () {
      console.log(window.outerWidth);
      if (window.outerWidth <= 1800 && window.outerWidth > 1300) {
        this.videoSpan = 7;
        this.videoNum = 3;
      } else if (window.outerWidth <= 1300) {
        this.videoSpan = 10;
        this.videoNum = 2;
      } else if (window.outerWidth > 1800) {
        this.videoSpan = 5;
        this.videoNum = 4;
      }

      if (window.outerWidth <= 1800 && window.outerWidth > 1400) {
        this.fileNum = 4
      } else if (window.outerWidth <= 1400) {
        this.fileNum = 4
      } else if (window.outerWidth > 1800) {
        this.fileNum = 4
      }

      if (window.outerWidth <= 1800 && window.outerWidth > 1600) {
        this.courseContentSpan = 5;
        this.courseContentNum = 4;
      } else if (window.outerWidth <= 1600 && window.outerWidth > 1300) {
        this.courseContentSpan = 7;
        this.courseContentNum = 3;
      } else if (window.outerWidth <= 1300) {
        this.courseContentSpan = 10;
        this.courseContentNum = 2;
      } else if (window.outerWidth > 1800) {
        this.courseContentSpan = 5;
        this.courseContentNum = 4;
      }
    },
    // 获取课程详情
    getData () {
      this.$api.getCourseIntroDetailInfo({ id: this.courseId }).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          let info = res.data.data;

          let courseClassifyList = info.courseClassify.split(",");
          let courseClassifyName = [];
          courseClassifyList.map((item) => {
            let name = this.getCourseTypeName(item);
            if (name) {
              courseClassifyName.push(name);
            }
          });

          let courseFieldList = info.courseField.split(",");
          let courseFieldName = [];
          courseFieldList.map((item) => {
            let name = this.getCourseFieldName(item);
            if (name) {
              courseFieldName.push(name);
            }
          });

          let courseSuitableList = info.courseSuitable.split(",")
          let courseSuitableName = []
          courseSuitableList.map((item) => {
            let name = this.getAgeScopeName(item)
            if (name) {
              courseSuitableName.push(name)
            }
          })

          let courseLevelList = info.courseLevel.split(",")
          let courseLevelName = []
          courseLevelList.map((item) => {
            let name = this.getCourseLevelName(item)
            if (name) {
              courseLevelName.push(name)
            }
          })

          let courseThemeName = this.getCourseThemeName(info.courseTheme)

          let courseVersionName = this.getCourseVersionName(info.courseVersion)

          this.courseInfo = { ...res.data.data };
          this.courseInfo.courseClassifyName = courseClassifyName.join(",");
          this.courseInfo.courseFieldName = courseFieldName.join(",");
          this.courseInfo.courseSuitableName = courseSuitableName.join(",")
          this.courseInfo.courseLevelName = courseLevelName.join(",")
          this.courseInfo.courseThemeName = courseThemeName
          this.courseInfo.courseVersionName = courseVersionName
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取课程介绍详情
    getCourseIntroInfo () {
      this.$api.getCourseIntroInfo({ id: this.courseId }).then((res) => {
        this.pageLoading.close();
        if (res.data.code === 0) {
          console.log(res.data.data);
          res.data.data.map((item) => {
            item.showFlag = true;
            if (item.eduCourseIntroFile && item.eduCourseIntroFile.length > 0) {
              item.eduCourseIntroFile.map((fileInfo) => {
                let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length);
                if (type.toLowerCase().indexOf("pdf") != -1) {
                  fileInfo.iconType = "pdf";
                }
                if (type.toLowerCase().indexOf("doc") != -1) {
                  fileInfo.iconType = "doc";
                }
                if (type.toLowerCase().indexOf("xls") != -1) {
                  fileInfo.iconType = "xls";
                }
                if (type.toLowerCase().indexOf("ppt") != -1) {
                  fileInfo.iconType = "ppt";
                }
              });
            }
            if (item.eduCourseIntroContentTree) {
              item.eduCourseIntroContentTree.map((info) => {
                info.showFlag = true;
                if (info.eduCourseIntroFile && info.eduCourseIntroFile.length > 0) {
                  let fileList = info.eduCourseIntroFile
                  fileList.map((fileInfo) => {
                    let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length);
                    if (type.toLowerCase().indexOf("pdf") != -1) {
                      fileInfo.iconType = "pdf";
                    }
                    if (type.toLowerCase().indexOf("doc") != -1) {
                      fileInfo.iconType = "doc";
                    }
                    if (type.toLowerCase().indexOf("xls") != -1) {
                      fileInfo.iconType = "xls";
                    }
                    if (type.toLowerCase().indexOf("ppt") != -1) {
                      fileInfo.iconType = "ppt";
                    }
                  });
                  info.eduCourseIntroFile = fileList
                }
              });
            }
          });
          console.log(res.data.data);
          this.courseIntroInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close();
      });
    },
    // 内容操作
    handleContentItem (type, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      switch (type) {
        case "turnItem":
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseIntroInfo[firstIndex].eduCourseIntroContentTree, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseIntroInfo, firstIndex, info);
          }
          break;
      }
    },
    //预览富文本图片
    handleImgClick (e) {
      console.log(e)
      if (e.target.nodeName == 'IMG') {
        this.viewUrl = e.target.currentSrc
        this.viewUrlList = [e.target.currentSrc]
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true
        })
      }
    },
    // 预览文件
    fileView (fileInfo, type) {
      if (type == 'view') {
        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
        if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
          this.viewUrl = fileInfo.fileUrl;
          this.viewUrlList = [fileInfo.fileUrl];
          this.$nextTick(() => {
            this.$refs.viewImg.showViewer = true;
          });
        } else {
          const { originalName, fileVersionId } = fileInfo
          console.log(originalName, fileVersionId);
          this.getFileViewUrl('', { fileVersionId, name: originalName }, (data) => {
            if (data) {
              sessionStorage.setItem('viewUrl', data);
              let routeUrl = this.$router.resolve({ path: '/fileView.html' });
              window.open(routeUrl.href, '_blank');
            }
          });
        }
      } else {
        if (fileInfo.isDownload === '1') {
          let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
          if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
            this.$message.warning('该附件为图片，只可预览查看');
          } else {
            // window.location.href = fileInfo.fileUrl;
            this.exportFile(fileInfo.fileUrl, {}, fileInfo.originalName)
          }
        } else {
          this.$message.warning('该附件不支持下载！');
        }
      }
    },
    // 视频预览
    videoView (videoInfo) {
      this.videoViewDialogVisible = true;
      this.$nextTick(() => {
        let type = videoInfo.videoUrl.substr(videoInfo.videoUrl.lastIndexOf(".") + 1, videoInfo.videoUrl.length);
        console.log(type);
        this.videoViewType = type == "mp4" || type == "avi" ? "video" : type == "mp3" ? "audio" : "";
        this.videoViewUrl = videoInfo.videoUrl;
      });
    },
    // 视频预览关闭
    videoViewClose () {
      this.videoViewDialogVisible = false;
      this.$nextTick(() => {
        this.videoViewType = "";
        this.videoViewUrl = "";
      });
    },
  },
  beforeDestroy () {
    window.onresize = null;
  },
};
</script>
<style lang="scss" scoped>
//tab样式
/deep/.el-tabs__nav-wrap {
  padding: 0 20px;
  height: 60px;
}

/deep/.el-tabs__nav {
  font-size: 20px;
  height: 60px;
  .el-tabs__item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}
.course-content-tab {
  padding: 30px 25px 20px 25px;
  background-color: #ffffff;
}
/deep/.el-input__count {
  &::before {
    content: '已输入 ';
  }
}
</style>
